import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  Avatar,
  Paper,
  Select,
  MenuItem,
  IconButton,
  styled,
  Popover
} from "@material-ui/core";
import { createTheme,ThemeProvider,withStyles } from "@material-ui/core/styles";
import {googleImage,lightThemeBg,darkThemeBg, prev, next, RightIcon} from "./assets";
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import Loader from "../../../components/src/Loader.web";
import AppleLogin from "../../applelogin/src/AppleLogin.web"

const StyledIcon = styled(IconButton)({
  padding:0,
  marginLeft:-4,
  "&.MuiIconButton-root:hover":{
   background:'unset'
  }
})

// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  }

});

const commonLightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    }
   }
  });

const commonDarkTheme=createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3858E3",
      },
      secondary:{
      main:"rgba(255,255,255,0.75)"
      }
     }
    });

// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
  configJSON,
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  CustomMenu = withStyles((theme) => ({
    root: {  
      list: {
        paddingTop: '0px', 
        paddingBottom: '0px', 
      },
      '&:hover': {
        backgroundColor: this.props.checked?'#d3d3d3':"black",
      },
      '&.Mui-selected': {
        background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
      },      
       "&.MuiPaper-rounded":{
        borderRadius: "8px !important",
       },
      '&.MuiList-padding': {
        padding:"0px !important"
      },         
      alignItems: "center",
      gap: "8px",
      backgroundColor: this.props.checked?"var(--Neutrals-Cool-gray-50, #E9E9E9)":"var(--Neutrals-Cool-gray-500, #222)",
      display: "flex",
      width: "100%",
      height: "44px",
      justifyContent: 'space-between',
      fontWeight:600
    },
  }))(MenuItem);

  conditionShort =(condition:boolean,trueStatement:string,falseStatement:string)=>{
    return condition ?trueStatement:falseStatement
  }



  CalendarContainer=styled("div")(theme=>({
    "& .calendar_container .react-calendar__tile":{
      fontFamily:"Manrope !important",
      fontSize:12,
      fontWeight:500,
      height:40,
      padding:10,
      width:40,
      color: this.conditionShort(this.props.checked,"#222222","#fff"),
      borderRadius:'10px',
      margin:"2px",
      flex:'0 0 42px !important'
    },

    "& .calendar_container .react-calendar__century-view .react-calendar__tile":{
      fontWeight:500,
      color: this.conditionShort(this.props.checked,"#222222","#fff"),
      borderRadius:'10px',
      flex:'0 0 100px !important',
      height:40,
      padding:10,
      width:40,
      fontFamily:"Manrope !important",
      fontSize:12,
    },

    "& .calendar_container .react-calendar__month-view__days":{
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between'
    },


    "& .react-calendar__tile":{
     maxWidth:'28% !important'
    },

    '& .react-calendar__year-view__months':{
       display:'flex',
       flexDirection:'row',
       justifyContent:'center',
       gap:'8px'
    },

      "& .calendar_container .react-calendar":{
        padding:10,
        borderRadius:"10px",
        backgroundColor:this.conditionShort(this.props.checked,"#fff","#0B0B0B"),
        border:'none !important'
      },

      "& .calendar_container .react-calendar__month-view__weekdays__weekday abbr":{
        fontFamily:"Manrope !important",
        fontSize:12,
        fontWeight:500,
        textDecoration:'none !important',
        textTransform:'none',
        background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        color: 'transparent',
      },

      "& .calendar_container .react-calendar__tile:enabled:hover":{
        width:"40px",
        height:"40px",
        borderRadius:'20px',  
        color:this.conditionShort(this.props.checked,"#222222","#FFFFFF"),  
        background:this.conditionShort(this.props.checked,"#BABABA","#1F1F1F"),
        
      },


    "& .calendar_container .react-calendar__decade-view .react-calendar__tile":{
      fontFamily:"Manrope !important",
      fontSize:12,
      fontWeight:500,
      height:40,
      padding:10,
      width:40,
      color: this.conditionShort(this.props.checked,"#222222","#fff"),
      borderRadius:'10px',
      flex:'0 0 100px !important'
     
    },
      

      '& .react-calendar__navigation button:enabled:hover':{
        backgroundColor:'transparent !important'
      },
      '& .react-calendar__navigation button:enabled':{
        backgroundColor:'transparent !important'
      },

      "& .calendar_container .react-calendar__navigation__label__labelText":{
        background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        color: 'transparent',
        fontWeight:700,
        fontFamily:"Manrope",
        fontSize:'12px',
      }, 
       
      "& .calendar_container .react-calendar__tile--active":{
        background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
        width:"40px",
        height:"40px",
        borderRadius:'60px',
        color:"#fff !important",
      },

       "& .calendar_container .react-calendar__tile--active:enabled:hover":{
        width:"40px",
        height:"40px",
        borderRadius:'60px',
        background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
        color:this.conditionShort(this.props.checked,"#222222","#ffff") 
       },
      "& .calendar_container .react-calendar__tile--now":{
        background:'none',
        width:"40px",
        height:"40px",
        borderRadius:'60px',
       },
       "& .calendar_container .react-calendar__tile--now:hover":{
        backgroundColor:this.conditionShort(this.props.checked,"#BABABA !important","#1F1F1F !important"),
       },
       "& .calendar_container .react-calendar__tile--now:enabled:hover":{
        width:40,
        height:40,
        borderRadius:'20px',
        color:'#fff',
        background:'none', 
       },

       "& .calendar_container .react-calendar__month-view__days__day--neighboringMonth":{
         color: "#6B6B6B",
       },
       "& .calendar_container .react-calendar__year-view__months__month":{
        border:'1px solid #222222',
        margin:'2px',
        width:'10px !important',
        borderRadius:'4px !important'
      },

      "& .calendar_container .react-calendar__year-view__months__month:hover":{
         borderRadius:'4px !important',
         border:'none !important'
      },
      
      '& .react-calendar__decade-view__years':{
        display:'flex',
       flexDirection:'row',
       justifyContent:'flex-start',
       gap:'10px',
       marginLeft:'10px',
      },

      '& .calendar_container .react-calendar__decade-view__years__year':{
        border:'1px solid #222222 !important',
        borderRadius:'4px !important',
        maxWidth:'30% !important'
      },

      '& .calendar_container .react-calendar__decade-view__years__year:hover':{
        border:'none !important',
        borderRadius:'4px !important',
        maxWidth:'30% !important'
      },

      '& .calendar_container .react-calendar__decade-view__years__year--active':{
        border:'none !important',
        borderRadius:'4px !important',
        maxWidth:'30% !important'
      },

      
      '& .react-calendar__navigation button:disabled':{
         backgroundColor:'transparent !important'
      },

      '& .react-calendar__century-view__decades':{
        display:'flex',
       flexDirection:'row',
       justifyContent:'flex-start',
       marginLeft:'10px',
       gap:'8px'
      },

      '& .calendar_container .react-calendar__century-view__decades__decade':{
        border:'1px solid #222222 !important',
        borderRadius:'4px !important',
        maxWidth:'30% !important'
      },

      '& .calendar_container .react-calendar__century-view__decades__decade:hover':{
        border:'none !important',
        borderRadius:'4px !important',
        maxWidth:'30% !important'
      },

      "& .react-calendar__tile--hasActive":{
        color:'#E9E9E9 !important',
        background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
        border:'none !important',
        borderWidth:'0px !important'
    },
    
    "& .react-calendar__tile--hasActive:enabled":{
      color:'#E9E9E9 !important',
      background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
      border:'none !important',
      borderWidth:'0px !important'
  },

  "& .calendar_container .react-calendar__year-view .react-calendar__tile":{
    flex:'0 0 100px !important',
    borderRadius:'10px',
    color: this.conditionShort(this.props.checked,"#222222","#fff"),
    width:40,
    padding:10,
    height:40,
    fontWeight:500,
    fontSize:12,
    fontFamily:"Manrope !important", 
  },

  }))

  
  renderCalendar=()=>{
    const popper = Boolean(this.state.anchorEl)
    const id = popper ? 'simple-popper' : undefined;
    const{classes}=this.props
    return(
       <Popover
        id={id} 
        open={popper} 
        data-testid="popover"
        anchorEl={this.state.anchorEl} 
        onClose={this.handleCloseCalendar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popup}
        > 
        <this.CalendarContainer data-testid="calendarContainer">
          <div className="calendar_container"> 
            <Calendar 
            prev2Label={null}
            data-testid="calendar"
            prevLabel={<IconButton><img src ={prev}/></IconButton>}
            nextLabel={<IconButton><img src={next}/></IconButton>}
            next2Label={null}
            onChange={this.handleDobChange}
            value={this.state.calendarDate} 
            />
          </div>
        </this.CalendarContainer>  
        </Popover>  
     )       
  }

  // Customizable Area End

  render() {
      // Customizable Area Start
      const {classes}=this.props
 
      // Customizable Area End
    return (
      // Customizable Area Start
      
      <ThemeProvider theme={this.props.checked ? commonLightTheme : commonDarkTheme}>
        <Paper className={this.props.checked ? classes.lightColor : classes.darkColor}>
          <Box className={!this.props.checked ? classes.background : classes.lightBackground}>
          <Loader loading={this.state.loginPageLoading}/>
            <Grid container className={classes.gridContainer}>
              <Grid item className={classes.imageGridItem} md={5} sm={6} lg={6}>
              </Grid>
              <Grid item xs={10} md={4} sm={6} lg={4} className={classes.formContainer}>
                <Box className={classes.formBox}>
                  <Typography className={classes.arrowStartText} data-test-id='welcome'>
                    {configJSON.welcome} <Box component='span' className={classes.arrowEndText}>
                      {configJSON.arrows}
                    </Box>
                  </Typography>
                  <Typography data-test-id='signupText' className={classes.signupText}>{configJSON.signup}</Typography>
                  {this.state.apiError && <Box className={this.props.checked ? classes.errorBoxLightEmail : classes.errorBoxEmail}>
                    <Typography className={classes.errorText} data-test-id='apiError'>{this.state.apiError}</Typography>
                  </Box>}

                  {this.state.formError && <Box className={this.props.checked ? classes.errorBoxLightEmail : classes.errorBoxEmail}>
                    <Typography className={classes.errorText} data-test-id='errors'>{this.state.formError}</Typography>
                  </Box>}

                  <Box onSubmit={this.getOtp} component='form' data-test-id='submitForm'>
                    <Box className={classes.inputContainer}>
                      <Typography className={classes.nameLabel}>{configJSON.name}</Typography>
                      <TextField 
                      error={this.state.errors.nameError !== ''} 
                      data-test-id='name' id="outlined-basic" 
                      variant="outlined" 
                      className={
                        this.conditionShort(
                        this.state.errors.nameError !== '',
                        classes.textFieldError,
                        this.conditionShort(this.state.name.length>0,classes.textFieldColor,classes.textField)
                       )} 
                      value={this.state.name}
                      placeholder={configJSON.enterName} autoComplete="off" onChange={this.handleNameChange} />
                    </Box>
                    {this.state.showSuggestion ? <Box className={classes.inputContainer}>
                      <Typography className={classes.label}>{configJSON.phone}</Typography>
                      <Box style={{display:"flex",gap:"8px"}}>
                      <Select                       
                      inputProps={{
                        classes: {
                          nativeInput: this.conditionShort(this.props.checked,classes.nativeInputLight,classes.nativeInput)
                        }
                      }}
                       IconComponent={(iconProps) => (
                        <KeyboardArrowDownIcon  {...iconProps}  style={{color: this.conditionShort(this.props.checked,"balck","white"),right:"0px",position:'absolute',left:'35px'}} />
                       )}
                       style={{borderRadius:"10px"}}
                       value={`+${this.state.countryCode}`}
                        onChange={this.handleSelectCountryCode}
                        name="contryCode" 
                        MenuProps={{
                          classes: { paper: classes.menuPaper, list: classes.menuList},
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          onEntering: (element) => {
                            const firstItem = element.querySelector('li');
                            if (firstItem) {
                              firstItem.blur();
                            }
                          },
                        }}
                        className={classes.textFieldSelect}   
                        disableUnderline                                          
                        variant="outlined"
                        id="contry_code"                        
                        >
                        {this.state.codesFilter.map((val:any)=>
                           <this.CustomMenu key={val.isoCode2} autoFocus={false}  value={val.countryCodes}>
                           <div className={classes.contryStyle}> 
                    <Typography className={classes.contryFont} > +{val.countryCodes} </Typography >
                   <Typography  className={classes.contryFont}> {val?.country} </Typography ></div>
                            <img
                               width={36}
                               height={18}
                               src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${val.isoCode2}.svg`}
                             />
                            </this.CustomMenu>)} 
                       </Select>
                      <TextField error={(this.state.errors.phoneNumberError === configJSON.enterValidNumber)||this.state.apiError!==''} data-test-id='phoneNumber' id="outlined-basic" variant="outlined" 
                      className={this.conditionShort(((this.state.errors.phoneNumberError === configJSON.enterValidNumber)||this.state.apiError!==""),classes.textFieldError, this.conditionShort(this.state.phoneNumber.length>0,classes.textFieldColor,classes.textField))} value={this.state.phoneNumber}
                        placeholder={configJSON.enterPhoneNumber} autoComplete="off" onChange={this.handlePhoneNumberChange} />                        
                      </Box>                      
                      <Box className={classes.errorSuggestionContainer}>
                        <Typography data-test-id='suggestionEmail' className={classes.suggestionText} onClick={this.handleChangeSuggestion}>{configJSON.useEmail}</Typography>
                      </Box>
                    </Box>
                      : <Box className={classes.inputContainerEmail}>
                        <Typography className={classes.label}>{configJSON.email}</Typography>
                        <TextField error={(this.state.errors.emailError === configJSON.enterValidEmail)||this.state.apiError!==''}  data-test-id='email' id="outlined-basic" variant="outlined" 
                          className={this.conditionShort(((this.state.errors.emailError === configJSON.enterValidEmail)||this.state.apiError!==''),classes.textFieldError,this.conditionShort(this.state.userEmail.length>0,classes.textFieldColor,classes.textField))} 
                          value={this.state.userEmail}
                          placeholder={configJSON.enterEmail} autoComplete="off" onChange={this.handleEmailChange} />
                        <Box className={classes.errorSuggestionContainer}>
                          <Typography data-test-id='suggestionPhoneNumber' className={classes.suggestionText} onClick={this.handleChangeSuggestion}>{configJSON.phoneNumberSuggestion}</Typography>
                        </Box>
                      </Box>}
                    <Box className={classes.inputContainer}>
                      <Typography className={classes.label}>{configJSON.dateOfBirth}</Typography>
                      <TextField
                        error={this.state.errors.dobError!==''}
                        data-test-id='dateOfBirth'
                        id="date"
                        autoComplete="off"
                        variant="outlined"
                        placeholder="DD/MM/YYYY"
                        type='text'
                        onChange={this.handleDob}
                        className={ this.conditionShort(
                          this.state.errors.dobError!=='',classes.textFieldError,
                         this.conditionShort(this.state.dateOfBirth.length>0,classes.textFieldColor,classes.textField))}
                      />

                      {this.renderCalendar()}
                    </Box>
                    <Button className={classes.button} fullWidth type='submit'
                      data-test-id='continue'
                      disabled={this.state.disable}
                      endIcon={<StyledIcon><img src = {RightIcon}/></StyledIcon>}>
                      {configJSON.continue}
                    </Button>
                  </Box>
                  <Box className={classes.avatarContainer}>
                   
                    <GoogleOAuthProvider clientId={configJSON.clientId}>
                    <Avatar className={classes.avatar}>
                    <img src={googleImage} />
                    </Avatar>
                    <div className={classes.google}>
                        <GoogleLogin
                          data-test-id='google'
                          size="small"
                          type="icon"
                          shape="square"
                          width="60px"
                 
                         onSuccess={this.handleLoginSuccess}
                         onError={()=>this.handleLoginError}
                        />
                        </div>
                       
                     </GoogleOAuthProvider>
                     <AppleLogin checked={this.props.checked}
                  navigation={this.props.navigation}
                  id={"apple-login-splashscreen"}
                  testId={"apple"}
                  callback={this.handleAppleLoginSuccess}
                  onPress={function (): void {
                    throw new Error("Function not implemented.");
                  } }
                  hideButton={true}
                  buttonTitle="test"
                  />
                  </Box>
                  
                   
                  <Typography className={classes.account}>{configJSON.account} <Box data-test-id='signIntext' component='span' onClick={this.navigateToLogin} className={classes.login}>{configJSON.login}</Box></Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </ThemeProvider>
     
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = {
  popup:{
    '& .MuiPaper-rounded':{
      borderRadius:'10px !important',
      border:'1px solid #35383F'
    } 
  },
  contryStyle:{
    display:"flex",
    gap:"8px"
  },
  contryFont:{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 500,
  }, 
  arrowStartText: {
    fontFamily: "Manrope",
    fontSize: "31px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    textAlign: 'left' as "left",
  },
  google:{
    opacity: 0,
    cursor: 'pointer',
    zIndex: 200,
    left: '37%',
    position: "absolute" as 'absolute',
    width: '40px',
    overflow: 'hidden',
  },
  menuPaper: {
    marginTop: '8px', 
    borderRadius: '10px !important',
    background: "none !important",
    
  },
  menuList: {
    paddingTop: '0px', 
    paddingBottom: '0px', 
    maxHeight:"364px"
  },
  nativeInputLight: {
    opacity: '1 !important', 
    position: "absolute" as "absolute",    
    "@media (max-width: 1280px)": {
      bottom:"15 !important",
    },
    color: 'black', 
    bottom:"14 !important",
    backgroundColor: 'transparent !important', 
    border:"none !important" as "none",
    width:"44px",
    fontFamily:'Manrope',
    textAlign:"center" as "center" , 
    left:'-3px !important'
  },
  nativeInput: {
    opacity: '1 !important',     
    textAlign:"center" as "center",     
    "@media (max-width: 1280px)": {
      bottom:"15px !important",
    },
    position: "absolute" as "absolute", 
    color: 'white', 
    fontFamily:'Manrope',
    bottom:"14 !important",
    backgroundColor: 'transparent !important', 
    border:"none !important" as "none",
    width:"44px",
    left:'-3px'
  },
  arrowEndText: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    fontFamily: "Manrope",
    fontSize: "31px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    letterSpacing:'-0.02em'
  },
  signupText: {
    fontFamily: "Manrope",
    fontSize: "31px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    textAlign: 'left' as 'left',
    marginTop: '3px',
    letterSpacing:'-0.02em'
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#8833FF"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      border: '1px solid #6B6B6B',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      border: '1px solid #6B6B6B',
      padding: "10px 16px",
    },

  },
  textFieldColor: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '& .MuiOutlinedInput-notchedOutline':{
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#8833FF",
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#8833FF"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 16px",
      border: '1px solid #8833FF',
    },

  },
  textFieldError: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#f44336"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      border: '1px solid #6B6B6B',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 16px",
    },

  },
  textFieldSelect: {  
    width:"76px",  
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
      },
    },
    "& .MuiOutlinedInput-notchedOutline":{
      borderWidth:"1px",
      borderColor:"#8833FF"
     },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '20px',
      border: '1px solid #6B6B6B',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 14px",
    },

  },
  button: {
    borderRadius: "10px",
    background: "linear-gradient(134.78deg, #BD02F7 19.96%, #3858E3 109.45%)",
    textTransform: "capitalize" as "capitalize",
    color: "#E9E9E9",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: '8px',
    height: '45px',
    "&.MuiButton-root.Mui-disabled": {
      color: '#E9E9E9',
      opacity:0.64
    }
  },
  gridContainer: {
    justifyContent: 'space-between' as 'space-between',
    alignItems: 'center',
    height: '90vh',
    paddingTop: "3rem",
    [theme.breakpoints.down('xs')]:
      { justifyContent: 'center' as 'center' }
  },
  label: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    textAlign: 'left' as 'left',
    marginBottom: '6px'
  },
  suggestionText: {
    color: "#8833FF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    textDecoration: "underline",
    textDecorationColor:"rgb(136, 51, 255, 0.3)",
    cursor: 'pointer',
    marginTop: '6px',
    marginLeft: 'auto',
  },
  arrowImage: {
    height: '350px',
    width: '350px',
  },
  description: {
    textAlign: 'center' as 'center',
    fontFamily: "Manrope",
    fontSize: "18px",
    fontStyle: "normal" as 'normal',
    fontWeight: 600,
    marginTop: '-20px'
  },
  avatar: {
    borderRadius: "10px",
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    cursor: 'pointer'
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
    gap: 15,
    width: '100%',
    position: "relative" as "relative"
  },
  imageGridItem: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: { display: 'none', paddingLeft: '0px' },
    [theme.breakpoints.down('sm')]: { paddingLeft: '0px' },
    height: '80vh',
    paddingLeft: '80px',
  },
  account: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    marginTop: '20px',
  },
  login: {
    color: "#8833FF",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    textDecoration: 'underline',
    textDecorationColor:"rgb(136, 51, 255, 0.3)",
    cursor: 'pointer',
  },
  inputContainer: {
    marginBottom: '10px',
    marginTop: '10px'
  },
  errorText: {
    color: '#DC2626',
    fontFamily: "Manrope",
    fontSize: "11px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
  },
  errorSuggestionContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  inputContainerEmail: {
    height: 'auto'
  },
  switchGridContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-end'
  },
  textFieldDate: {
    width: '100%',
    boxSizing: 'border-box' as 'border-box',
    padding: "5px 5px",
    border: "1px solid #6B6B6B",
    height: '44px',
    borderRadius: "10px",
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
      color:'inherit !important',
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
      color:'inherit !important',
    },
    "& .MuiTypography-root": {
      fontFamily: "Manrope",
      fontSize: "12px",
      color:'inherit !important',
      fontStyle: "normal" as 'normal',
      fontWeight: 700,
    },
    "& .MuiInputBase-input::placeholder": {
      color:'inherit !important',
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      color:'inherit !important',
      filter: "invert(45%) sepia(2%) saturate(16%) hue-rotate(314deg) brightness(92%) contrast(98%)"
    }
  },
  lightColor: {
    backgroundColor: '#fff'
  },
  darkColor: {
    backgroundColor: '#070707'
  },
  background: {
    backgroundImage: `url(${darkThemeBg})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]:
      { backgroundImage: 'none' }
  },
  lightBackground: {
    backgroundImage: `url(${lightThemeBg})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]:
      { backgroundImage: 'none' }
  },
  textFieldDateError: {
    width: '100%',
    boxSizing: 'border-box' as 'border-box',
    height: '44px',
    padding: "5px 5px",
    border: "1px solid #DC2626",
    color: '#6B6B6B',
    borderRadius: "10px",
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiIconButton-root": {
      color: '#6B6B6B'
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      color: '#6B6B6B'
    }
  },
  formBox: {
    width: '80%',
    marginTop:'20px'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  errorBoxEmail: {
    display: 'flex',
    padding: '0.7rem 0.7rem',
    alignItems: 'center',
    borderRadius: "0.625rem",
    borderLeft: '1.3px solid var(--Status-Red-600, #DC2626)',
    background: 'linear-gradient(0deg, rgba(33, 33, 33, 0.29), rgba(33, 33, 33, 0.29)),linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)',
    backdropFilter: 'blur(24.5px)',
    color: '#DC2626',
    fontFamily: 'Manrope',
    fontSize: "12px",
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: "18px",
    marginTop: '22px',
  },
  errorBoxLightEmail: {
    display: 'flex',
    padding: '0.7rem 0.7rem',
    alignItems: 'center',
    borderRadius: "0.625rem",
    borderLeft: '1.3px solid var(--Status-Red-600, #DC2626)',
    background: 'linear-gradient(0deg, rgba(33, 33, 33, 0.07), rgba(33, 33, 33, 0.07)),linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)',
    backdropFilter: 'blur(24.5px)',
    color: '#DC2626',
    fontFamily: 'Manrope',
    fontSize: "12px",
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: "18px",
    marginTop: '22px',
  },
  nameLabel: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    textAlign: 'left' as 'left',
    marginBottom: '6px',
    marginTop: '20px',
  }
};
export const AccountCreation=withStyles(styles)(EmailAccountRegistration)
// Customizable Area End