// Customizable Area Start
import React, { forwardRef } from 'react'
import {
	Button,
	Box,
	Grid,
	IconButton,
	Popover,
	Slider,
	Tooltip,
	Typography
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import FullScreen from '@material-ui/icons/Fullscreen'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import VolumeDown from '@material-ui/icons/VolumeDown'
import VolumeMute from '@material-ui/icons/VolumeOff'
import VolumeUp from '@material-ui/icons/VolumeUp'
import MoreVert from '@material-ui/icons/MoreVert'

const useStyles = makeStyles((theme) => ({
	controlsWrapper: {
		visibility: 'hidden',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		height: '100%',
		background: 'rgba(0,0,0,0.6)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		borderRadius: "16px",
	},
	button: {
		margin: theme.spacing(1),
	},
	controlIcons: {
		color: '#777',
		transform: 'scale(0.9)',
		'&:hover': {
			color: '#fff',
			transform: 'scale(1)',
		},
	},
	bottomIcons: {
		color: '#999',
		'&:hover': {
			color: '#fff',
		},
	},
	moreActionContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
		paddingRight: '12px'
	},
	moreActionWrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: '256px',
		padding: '12px 0',
		gap: '12px',
	},
	moreActionText: {
		marginLeft: '16px',
		fontFamily: 'Manrope',
		fontSize: '14px',
		fontWeight: 500,
		cursor: 'pointer',
		textDecoration: 'none',
		color: 'inherit',
	}
}))

const VolumnSlider = withStyles({
	root: {},
	thumb: {
		background: 'linear-gradient(-45deg, #3858E3, #BA05F7)',
	},
	active: {},
	valueLabel: {},
	track: {
		background: 'linear-gradient(-45deg, #3858E3, #BA05F7)',
	},
	rail: {
		backgroundColor: '#CCC'
	},
})(Slider)

interface DurationSliderProps {
	isShowMini: boolean
	isFullScreen: boolean
}

const DurationSlider = withStyles({
	root: {
		height: 8,
	},
	thumb: {
		height: (props: DurationSliderProps) => (props.isShowMini && !props.isFullScreen ? 12 : 24),
		width: (props: DurationSliderProps) => (props.isShowMini && !props.isFullScreen ? 12 : 24),
		background: 'linear-gradient(-45deg, #3858E3, #BA05F7)',
		marginTop: (props: DurationSliderProps) => (props.isShowMini && !props.isFullScreen ? -4 : -8),
		marginLeft: (props: DurationSliderProps) => (props.isShowMini && !props.isFullScreen ? -6 : -12),
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 4px)',
	},
	track: {
		height: (props: DurationSliderProps) => (props.isShowMini && !props.isFullScreen ? 4 : 8),
		borderRadius: (props: DurationSliderProps) => (props.isShowMini && !props.isFullScreen ? 2 : 4),
		background: 'linear-gradient(-45deg, #3858E3, #BA05F7)',
	},
	rail: {
		height: (props: DurationSliderProps) => (props.isShowMini && !props.isFullScreen ? 4 : 8),
		borderRadius: (props: DurationSliderProps) => (props.isShowMini && !props.isFullScreen ? 2 : 4),
		backgroundColor: '#CCC'
	},
})(Slider)

interface ValueLabelComponent {
	children: any
	open: boolean
	value: string
}

function ValueLabelComponent(props: ValueLabelComponent) {
	const { children, open, value } = props

	return (
		<Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
			{children}
		</Tooltip>
	)
}

interface ControlsProps {
	onSeek: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
	onSeekMouseDown: () => void;
	onSeekMouseUp: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
	onPlayPause: () => void;
	playing: boolean;
	played: number;
	elapsedTime: string;
	totalDuration: string;
	onMute: () => void;
	muted: boolean;
	onVolumeSeekDown: (event: React.ChangeEvent<{}>, newValue: number | number[]) => void;
	playbackRate: number;
	selectedResolution: number | null;
	resolutionList: number[];
	onPlaybackRateChange: (rate: number) => void;
	onResolutionChange: (quality: number) => void;
	onToggleFullScreen: () => void;
	volume: number;
	onVolumeChange: (event: any, value: number | number[]) => void;
	isShowMini: boolean
	isFullScreen: boolean
	videoUrl: string
}

const Controls = forwardRef<HTMLDivElement, ControlsProps>(
	(
		{
			onSeek,
			onSeekMouseDown,
			onSeekMouseUp,
			onPlayPause,
			playing,
			played,
			elapsedTime,
			totalDuration,
			onMute,
			muted,
			onVolumeSeekDown,
			playbackRate,
			onPlaybackRateChange,
			onToggleFullScreen,
			volume,
			onVolumeChange,
			resolutionList,
			onResolutionChange,
			selectedResolution,
			isShowMini,
			isFullScreen,
			videoUrl,
		},
		ref
	) => {
		const classes = useStyles()
		const [playbackAnchorEl, setPlaybackAnchorEl] = React.useState<null | HTMLElement>(null)
		const [resolutionAnchorEl, setResolutionAnchorEl] = React.useState<null | HTMLElement>(null)
		const [moreActionAnchorEl, setMoreActionAnchorEl] = React.useState<null | HTMLElement>(null)

		const handleClickPlayback = (event: React.MouseEvent<HTMLElement>) => {
			setPlaybackAnchorEl(event.currentTarget)
		}

		const handleClickResolution = (event: React.MouseEvent<HTMLElement>) => {
			setResolutionAnchorEl(event.currentTarget)
		}

		const handleClickMoreAction = (event: React.MouseEvent<HTMLElement>) => {
			setMoreActionAnchorEl(event.currentTarget)
		}

		const handleClosePlayback = () => {
			setPlaybackAnchorEl(null)
		}

		const handleCloseResolution = () => {
			setResolutionAnchorEl(null)
		}

		const handleCloseMoreAction = () => {
			setMoreActionAnchorEl(null)
		}

		const playbackOpen = Boolean(playbackAnchorEl)
		const playBackId = playbackOpen ? 'playback-popover' : undefined

		const resolutionOpen = Boolean(resolutionAnchorEl)
		const resolutionId = resolutionOpen ? 'resolution-popover' : undefined

		const moreActionOpen = Boolean(moreActionAnchorEl)
		const moreActionId = moreActionOpen ? 'more-action-popover' : undefined

		const isShowSmallControl = isShowMini && !isFullScreen

		return (
			<div ref={ref} className={classes.controlsWrapper}>
				<Grid
					container
					direction="column"
					style={{ flexGrow: 1, justifyContent: 'space-between' }}
				>
					<Grid container>
						<Box className={classes.moreActionContainer}>
							<IconButton
								onClick={handleClickMoreAction}
								aria-describedby={moreActionId}
								className={classes.bottomIcons}
								style={{ fontSize: isShowSmallControl ? 26 : 50 }}
							>
								<MoreVert fontSize={isShowSmallControl ? 'small' : 'large'} />
							</IconButton>
							<Popover
								container={ref && 'current' in ref ? ref.current : null}
								open={moreActionOpen}
								id={moreActionId}
								onClose={handleCloseMoreAction}
								anchorEl={moreActionAnchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								disablePortal={false}
								style={{ zIndex: 2000 }}
							>
								<Box className={classes.moreActionWrapper}>
									<a 
										href={videoUrl}
										className={classes.moreActionText}
										target="_blank"
										rel="noopener noreferrer"
									>
										Download Video
									</a>
									<Typography className={classes.moreActionText}>Share Video</Typography>
								</Box>
							</Popover>
						</Box>
					</Grid>
					<Grid
						container
						direction="row"
						alignItems="center"
						style={{ padding: '0 16px', justifyContent: 'space-between' }}
					>
						<Grid item xs={12}>
							<DurationSlider
								isShowMini={isShowMini}
								isFullScreen={isFullScreen}
								min={0}
								max={100}
								ValueLabelComponent={(props) => (
									<ValueLabelComponent {...props} value={elapsedTime} />
								)}
								aria-label="custom thumb label"
								value={played * 100}
								onChange={onSeek}
								onMouseDown={onSeekMouseDown}
								onChangeCommitted={onSeekMouseUp}
							/>
						</Grid>

						<Grid item>
							<Grid container alignItems="center">
								<IconButton
									onClick={onPlayPause}
									className={classes.bottomIcons}
									style={{ fontSize: isShowSmallControl ? 26 : 50 }}
								>
									{playing ? (
										<PauseIcon fontSize={isShowSmallControl ? 'small' : 'large'} />
									) : (
										<PlayArrowIcon fontSize={isShowSmallControl ? 'small' : 'large'} />
									)}
								</IconButton>

								<IconButton
									onClick={onMute}
									className={`${classes.bottomIcons}`}
									style={{ fontSize: isShowSmallControl ? 26 : 50 }}
								>
									{muted ? (
										<VolumeMute fontSize={isShowSmallControl ? 'small' : 'large'} />
									) : volume > 0.5 ? (
										<VolumeUp fontSize={isShowSmallControl ? 'small' : 'large'} />
									) : (
										<VolumeDown fontSize={isShowSmallControl ? 'small' : 'large'} />
									)}
								</IconButton>

								<VolumnSlider
									min={0}
									max={100}
									value={muted ? 0 : volume * 100}
									onChange={onVolumeChange}
									aria-labelledby="input-slider"
									onMouseDown={onSeekMouseDown}
									onChangeCommitted={onVolumeSeekDown}
									style={{ width: isShowSmallControl ? 60 : 100 }}
								/>
								{!isShowSmallControl && (
									<Typography
										variant="body1"
										style={{ color: '#fff', marginLeft: 16 }}
									>
										{elapsedTime}/{totalDuration}
									</Typography>
								)}
							</Grid>
						</Grid>

						<Grid item>
							{Boolean(selectedResolution && resolutionList.length > 1) && (
								<Button
									onClick={handleClickResolution}
									aria-describedby={resolutionId}
									className={classes.bottomIcons}
									style={{ fontSize: isShowSmallControl ? 26 : 50 }}
									variant="text"
								>
									<Typography>{selectedResolution}</Typography>
								</Button>
							)}
							<Popover
								container={ref && 'current' in ref ? ref.current : null}
								open={resolutionOpen}
								id={resolutionId}
								onClose={handleCloseResolution}
								anchorEl={resolutionAnchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
							>
								<Grid container direction="column-reverse">
									{resolutionList.map((resolution) => (
										<Button
											key={resolution}
											onClick={() => {
												onResolutionChange(resolution)
												handleCloseResolution()
											}}
											variant="text"
											disabled={resolution === selectedResolution}
										>
											<Typography
												color={resolution === selectedResolution ? 'secondary' : 'inherit'}
											>
												{resolution}
											</Typography>
										</Button>
									))}
								</Grid>
							</Popover>

							{!isShowSmallControl && (
								<Button
									onClick={handleClickPlayback}
									aria-describedby={playBackId}
									className={classes.bottomIcons}
									style={{ fontSize: 50 }}
									variant="text"
								>
									<Typography>{playbackRate}X</Typography>
								</Button>
							)}

							<Popover
								container={ref && 'current' in ref ? ref.current : null}
								open={playbackOpen}
								id={playBackId}
								onClose={handleClosePlayback}
								anchorEl={playbackAnchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								disablePortal={false}
								style={{ zIndex: 2000 }}
							>
								<Grid container direction="column-reverse">
									{[0.5, 1, 1.5, 2].map((rate) => (
										<Button
											key={rate}
											onClick={() => {
												onPlaybackRateChange(rate)
												handleClosePlayback()
											}}
											variant="text"
										>
											<Typography
												color={rate === playbackRate ? 'secondary' : 'inherit'}
											>
												{rate}X
											</Typography>
										</Button>
									))}
								</Grid>
							</Popover>
							<IconButton
								onClick={onToggleFullScreen}
								className={classes.bottomIcons}
								style={{ fontSize: isShowSmallControl ? 26 : 50 }}
							>
								<FullScreen fontSize={isShowSmallControl ? 'small' : 'large'} />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
)

export default Controls

// Customizable Area End
