import React from 'react'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as Yup from "yup";

import FormikInputField from "../../../components/src/FormikInputField.web";
interface StripeProps {
    getStripeToken:(data:any) => void;
}
const StripeCard = (props:StripeProps) => {
    const elementOptions = {
        style: {
            base: {
                '::placeholder': {
                    color: '#888',
                },
                width: '330px'
            },
            invalid: {
                color: '#e5424d',
            },
        },
    };
    const validationSchema = Yup.object().shape({
        holderName: Yup.string().required('required'),
    });
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (value: any) => {
        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardNumberElement) as any;
        const { error, token } = await stripe.createToken(cardElement, { name: value.holderName});

        if (error) {
            console.error("Error creating token:", error);
        } else {
            props.getStripeToken(token)
            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);
      
            if (cardNumberElement) cardNumberElement.clear();
            if (cardExpiryElement) cardExpiryElement.clear();
            if (cardCvcElement) cardCvcElement.clear();
        }
    };

    return (
        <>
                <Formik
                    initialValues={{ holderName: "", }}
                    onSubmit={handleSubmit}
                    data-test-id="validate-fromik-form"
                    validationSchema={validationSchema}
                    enableReinitialize={true}

                >
                    {(formikProps) => {
                        const { values, } = formikProps;
                        return (
                            <Form noValidate autoComplete="off" id="addFormId">
                                <div className="fieldContainer">
                                    <label htmlFor="title"> Card Number</label>
                                    <CardNumberElement options={elementOptions} className="cardNumber" />

                                </div>


                                <div className="fieldContainer">
                                    <label htmlFor="title"> Cardholder Name</label>
                                    <Field
                                        component={FormikInputField}
                                        id="titleInput"
                                        name="holderName"
                                        value={values.holderName}
                                        placeholder="Cardholder Name"
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        InputLabelProps={{
                                            className: "13",
                                        }}
                                        className="passField"

                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                                    <div className="fieldContainer">
                                        <label htmlFor="title"> Expiray Date</label>
                                        <CardExpiryElement options={elementOptions} />

                                    </div>
                                    <div className="fieldContainer">
                                        <label htmlFor="title">CVV</label>
                                        <CardCvcElement options={elementOptions}  />


                                    </div>
                                </div>
                                <div className='fieldContainer'>
                                    <Button id="save-btn" className="submitButton" type="submit">
                                        <p className="label">Add Card</p>
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
        </>
    )
}

export default StripeCard