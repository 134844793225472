import React from "react";

import {
  // Customizable Area Start
  Box,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles"
import { backArrowWhite, backArrowDark, arrowRight24White } from "./assets";
import { Formik, Form, Field } from "formik";
import FormikInputField from "../../../components/src/FormikInputField.web";
// Customizable Area End

import UserUpdateFormController, {
  Props
} from "./UserUpdateFormController.web";

export default class UserUpdateForm extends UserUpdateFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderUserNameChange = () => {     
    return (
        <div className="userEditFormContainer">
            <div className="oldValueContainer">
                <p>Current</p>
                <p className="oldV">{this.props.fieldValue ? this.props.fieldValue: "---"}</p>
            </div>                
            {this.props.updateError !== "" && <div className="pasErrorContainer " > 
                {this.props.updateError}
                </div> }
            <Formik
                initialValues={{             
                    filedValue: ""
                }}
                onSubmit={this.submitEditForm}
                data-test-id="validate-user-edit-form"               
            >
                {(formikProps) => {
                    const { values} = formikProps;
                    return (
                        <Form noValidate autoComplete="off">
                            <div className="fieldContainer p16">
                                <label htmlFor="userNameInput"> New {this.state.fieldName}</label>
                                <Field
                                    component={FormikInputField}
                                    id="userNameInput"
                                    name="filedValue"
                                    value={values.filedValue}                                   
                                    placeholder={`Enter ${this.state.fieldName}`}
                                    variant="outlined"
                                    fullWidth
                                    type="text"
                                    InputLabelProps={{
                                        className: "13",
                                    }}
                                    className = "passField"
                                />
                            </div>
                            <Button className="submitButton" type="submit">
                                <p className="label">Verify</p> 
                                <span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
                            </Button>
                        </Form>
                    );
                }}
                    
            </Formik>       
        </div>
    )
  }

  renderOtpScreen = () => {
    return(
        <div className="userVerificationContainer">
        <div className="newValueContainer">
            <p className="heading">We sent you a code</p>
            <p className="newV">Enter it below to verify {this.state.newFieldValue}</p>
        </div>                
        
        <Formik
            initialValues={{             
                otp: ""
            }}
            onSubmit={this.submitOtp}
            data-test-id="validate-user-edit-otp-form"
        >
            {(formikProps) => {
                const { values} = formikProps;
                return (
                    <Form noValidate autoComplete="off">
                        <div className="fieldContainer p16">
                            <label htmlFor="otpInput"> Verification code</label>
                            <Field
                                component={FormikInputField}
                                id="otpInput"
                                name="otp"
                                value={values.otp}                                   
                                placeholder="Enter Verification code"
                                variant="outlined"
                                fullWidth
                                type="text"
                                InputLabelProps={{
                                    className: "13",
                                }}
                                className = "passField"
                            />
                        </div>
                        <p className="arrowFontFamily font14 font500 txtGradiant didntRecieveTxt">Didn’t received code ?</p>
                        <Button className="submitButton" type="submit">
                            <p className="label">Verify</p> 
                            <span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
                        </Button>
                    </Form>
                );
            }}
                
        </Formik>       
    </div> 
    )
  }
 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start 
      <React.Fragment>
        <StyleContainer>
            {!this.state.isLoading &&  <React.Fragment> 
                <div className="headingContainer">
                <Button onClick={this.props.onClickBackButton} className="backButton buttonCapitalize" variant="text">
                <span className="icon"><img src={this.props.checked ? backArrowDark : backArrowWhite} /></span>
            </Button>
              <div className="textStyled">Change {this.state.fieldName}</div>
            </div>
            {this.state.showOtpScreen ? this.renderOtpScreen() :this.renderUserNameChange()}
            </React.Fragment>}
        </StyleContainer>  
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyleContainer = styled(Box)({
    "& .headingContainer":{
      display:'flex',
      alignItems:'center'
    },
    "& .textStyled":{
      fontFamily:'Manrope',
      fontWeight:'500',
      fontSize:24,
      lineHeight:"28px"
    },

    "& .p16":{
        paddingBottom:24,
        paddingTop:8
    },

    "& .userEditFormContainer":{
        gap: "20px",
        display: "flex",
        flexDirection: "column",
        marginTop: "20px"
    },
    "& .userVerificationContainer .fieldContainer":{
        paddingTop:"8px",
        paddingBottom: "24px"
    },
    "& .userEditFormContainer .fieldContainer label":{
        textTransform: "capitalize"
    },
    "& .oldValueContainer":{
        display:'flex',
        flexDirection:'column',
        rowGap: "5px"
    },
    "& .oldValueContainer p": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px", 
        margin: "0px"        
    },
    "& .oldV":{
        color: "#999999"
    },

    "& .buttonCapitalize":{
        minWidth:"0px !important"
    },

    "& .buttonCapitalize span": {
        textTransform: "capitalize",
        marginRight:"0px !important",
        minWidth:"0px !important"
    },
    "& .newValueContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        marginBottom: "30px",
        marginTop: "30px"

    },
    "& .newValueContainer p": {
       margin: "0px"
    },
    "& .newValueContainer .heading": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: "800",
        lineHeight: "27.12px",
        backgroundImage: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: "text",         
        color: "transparent"
    },
    "& .newValueContainer .newV": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "19.2px"
    },
    "& .didntRecieveTxt":{
        margin: "0px",
        marginBottom: "30px"
    }
});
const webStyle = {
    gridItem:{
        position: "fixed" as 'fixed',
        top: 0,
        right: 0,
        zIndex: 99
      },
};
// Customizable Area End
