import { Box, Button, createStyles, TextField, Typography, withStyles } from '@material-ui/core';
import { imageFileIcon, sendIcon } from '../../blocks/chat/src/assets';
import React from 'react';
import ChatMessageReplyItem from './ChatMessageReplyItem.web';
import { ISetMessageReply } from '../../blocks/chat/src/ViewChatController';
import ViewChatPreviewAttachment from './ViewChatPreviewAttachment.web';
import clsx from 'clsx';

interface MessagePermissionNoticeProps {
    classes: any;
    checked: boolean;
}

export interface FileUploadListProps {
    files: File[];
    userInfo: CometChat.User | null;
    onRemove: (fileName: string) => void;
    onCloseSelectedFile: () => void;
    onSelectFiles: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSendMessage: () => Promise<void>;
}

interface MessageInputProps {
    classes: any;
    message: string;
    checked: boolean;
    listFileUpload: File[];
    refFileInput: React.RefObject<HTMLInputElement>;
    onMessageChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => Promise<void>;
    onMessageBlur: () => void;
    onFileSelect: () => void;
    onSelectFiles: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onCloseSelectedFile: () => void;
    onSendMessage: () => Promise<void>
    onRemoveReply: () => void;
    messageReplyItem: ISetMessageReply | null;
}

interface FooterWrapperProps extends MessageInputProps {
    isCheckCanSendMessage: boolean;
    userInfo: CometChat.User | null;
    onRemoveFile: (fileName: string) => void;
}

const MessagePermissionNotice: React.FC<MessagePermissionNoticeProps> = ({ checked, classes }) => (
    <Box className={`messages ${checked && "lightTheme"}`}>
        <Typography component="p">
            Only Group <Typography component="span">admins</Typography> can send messages.
        </Typography>
    </Box>
);

const FileUploadList: React.FC<FileUploadListProps> = ({
    files,
    userInfo,
    onRemove,
    onCloseSelectedFile,
    onSelectFiles,
    onSendMessage,
}) => {
    const senderName = userInfo?.getName() || "";
    return (
        <Box className="filesSelectedContainer">
            <ViewChatPreviewAttachment
                files={files}
                senderName={senderName}
                onRemove={onRemove}
                onCloseSelectedFile={onCloseSelectedFile}
                onSelectFiles={onSelectFiles}
                onSendMessage={onSendMessage}
            />
        </Box>
    )
};

const MessageInput: React.FC<MessageInputProps> = ({
    classes,
    message,
    checked,
    listFileUpload,
    refFileInput,
    onMessageChange,
    onMessageBlur,
    onFileSelect,
    onSelectFiles,
    onSendMessage,
    onRemoveReply,
    messageReplyItem
}) => {
    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSendMessage();
        }
    };
    return (
        <Box className={`sendMessageContainer ${checked && "lightTheme"}`}>
            <TextField
                data-testid="messageInput"
                variant="outlined"
                placeholder="Message..."
                className={classes.messageField}
                fullWidth
                value={message}
                onChange={onMessageChange}
                onKeyDown={handleKeyPress}
                onBlur={onMessageBlur}
                multiline
                maxRows={2}
                InputProps={{
                    style: {
                        borderRadius: "10px",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: checked ? "#222222" : "#E9E9E9",
                        height: "56px",
                        fontFamily: "Manrope",
                    },
                    endAdornment: (
                        <Box className="pointer" onClick={onFileSelect}>
                            <img src={imageFileIcon} width={24} height={24} alt="i" />
                            <input
                                ref={refFileInput}
                                id="fileUpload"
                                multiple
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => onSelectFiles(e)}
                                accept="image/*"
                            />
                        </Box>
                    )
                }}
            />
            <Button
                className={classes.sendMessageBtn}
                onClick={onSendMessage}
                disabled={!message.trim().length && !listFileUpload.length}
            >
                <img src={sendIcon} alt="" />
            </Button>

            <ChatMessageReplyItem
                checked={checked}
                messageReplyItem={messageReplyItem}
                onRemoveReply={onRemoveReply}
            />
        </Box>
    )
};

const ViewChatFooterWrapper: React.FC<FooterWrapperProps> = ({
    classes,
    isCheckCanSendMessage,
    checked,
    message,
    listFileUpload,
    refFileInput,
    messageReplyItem,
    userInfo,
    onMessageChange,
    onMessageBlur,
    onFileSelect,
    onSelectFiles,
    onCloseSelectedFile,
    onSendMessage,
    onRemoveFile,
    onRemoveReply
}) => (
    <Box className={`${classes.footerWrapper} ${clsx(!isCheckCanSendMessage && classes.adminChatWrapper)}`} data-test-id="footerWrapper">
        {isCheckCanSendMessage ? (
            <MessageInput
                classes={classes}
                message={message}
                checked={checked}
                listFileUpload={listFileUpload}
                refFileInput={refFileInput}
                onMessageChange={onMessageChange}
                onMessageBlur={onMessageBlur}
                onFileSelect={onFileSelect}
                onSelectFiles={onSelectFiles}
                onCloseSelectedFile={onCloseSelectedFile}
                onSendMessage={onSendMessage}
                onRemoveReply={onRemoveReply}
                messageReplyItem={messageReplyItem}
            />
        ) : (
            <MessagePermissionNotice checked={checked} classes={classes} />
        )}
        {!!listFileUpload.length && <FileUploadList
            files={listFileUpload}
            userInfo={userInfo}
            onRemove={onRemoveFile}
            onCloseSelectedFile={onCloseSelectedFile}
            onSelectFiles={onSelectFiles}
            onSendMessage={onSendMessage}
        />}
    </Box>
);


// Customizable Area Start
const styles = createStyles({
    footerWrapper: {
        display: "flex",
        gap: "10px",
        position: "relative",
        marginBottom: "20px",
        flexDirection: "column",
        "& .sendMessageContainer": {
            display: "flex",
            gap: 10,
            margin: "0 20px"
        },
        "& .messages.lightTheme": {
            background: "#FFFFFF",
            boxShadow: "0px -2px 4px 0px #00000014",
            borderImageSource: "linear-gradient(264.77deg, rgba(255, 255, 255, 0.18) -7.36%, rgba(255, 255, 255, 0) 95.13%)",
            "& p": {
                color: "#222222"
            }
        },
        "& .messages": {
            background: "#96969633",
            backdropFilter: "blur(39px)",
            border: "1px solid",
            borderImageSource: "linear-gradient(264.77deg, rgba(255, 255, 255, 0.18) -7.36%, rgba(255, 255, 255, 0) 95.13%)",
            width: "100%",
            marginTop: "20px",
            height: "calc(100% - 20px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& p": {
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "12px",
                color: "#FFFFFF",
                "& span": {
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "12px",
                    background: "linear-gradient(90deg, #3858E3, #BA05F7)",
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "transparent"
                }
            }
        },
        "& .filesSelectedContainer": {
            margin: "0 20px",
            display: "flex",
            alignItems: "center",
            gap: 8,
            maxWidth: "calc(100% - 40px)",
            height: 38,
            overflowX: "scroll",
        },
    },

    adminChatWrapper: {
        marginBottom: 0,
        height: 94
    },
    sendMessageBtn: {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        height: "56px",
        minWidth: "56px",
        borderRadius: "10px",
        padding: '10px',
        "&:hover": {
            background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        },
        "&.Mui-disabled": {
            background: "linear-gradient(-45deg, #8f86ed, #d35bf8)"
        },
    },

    messageField: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border: "1px solid #7A2BF5",
            },
            "& .MuiInputBase-input::placeholder": {
                color: "#6B6B6B",
                opacity: 1
            }
        },
        width: "100%"
    },
})

const ViewChatFooter = withStyles(styles)(ViewChatFooterWrapper)

export default ViewChatFooter;