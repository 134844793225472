import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {getStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

 
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  checked?: boolean
  classes?: { [key: string]: string }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  subscriptionDetails:Subscription[];
  orderSummary:OrderSummary;
  activeId:number;
  apiError:string;
  userToken:string;
  submittedDetails:{id:number,type:string,attributes:{id:number,name:string,price:string,description:string,subscribed:boolean,valid_up_to:string,features:string[]}}[],
  isLoading:boolean
  updateSubscription:boolean
  userSubscriptionId:number|null
  activeState:string
  isSubscribe: boolean
  promoCode:string
  promoCodeDetail:{
    isValid: boolean,
    msg: string,
    promoCodeId: string;
    data:any
  },
  isSubscriptionDone: boolean;
  isAutoRenewal: boolean;
  cardList:CardDetail[];
  selectedCard:any
}
export interface OrderSummary {
  plan_renew_date: string;
  plan_amount: number;
  discount: number;
  total_amount: number;
  plan_type: string;

}
export interface CardDetail {
  last4: string;
  exp_month: string;
  exp_year: string;
  name: string;
  id: string;
  isSelected:boolean
}
interface SubscriptionAttributes {
  id: number;
  account_id: number;
  title: string;
  details: string;
  period: string;
  price: number;
  is_subscribed: boolean
}

export interface Subscription {
  id: number;
  type: string;
  attributes: SubscriptionAttributes;
}
export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}

  // Customizable Area End


interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  getSubsId:string='';
  submitSubscriptionsId:string=''
  apiGetDataCallId:string=""
  submitSubscriptionStatusApi:string=''
  subScriptionsListId:string =''
  promoCodeId:string=''
  subscribeUserId:string=''
  orderSummaryId:string=''
  autoRenewalId:string=''
  cardListId:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      subscriptionDetails:[],
      activeId:0,
      apiError:'',
      submittedDetails:[],
      userToken:'',
      isLoading:true,
      updateSubscription:false,
      userSubscriptionId:null,
      activeState:'',
      isSubscribe: false,
      promoCode:'',
      promoCodeDetail:{
        isValid: false,
        msg:'',
        promoCodeId:'',
        data:{}
      },
      isSubscriptionDone:false,
      orderSummary:{} as OrderSummary,
      isAutoRenewal: false,
      cardList:[]as any,
      selectedCard:{}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Recived", message);
  
    this.getSubscriptionsApi(message)

    this.submitSubscriptionsApi(message)

    this.getUserDataResponse(message)

    this.showUserStatusSubscription(message)
    this.handleSubscriptionListResponse(message)
    this.handlePromoCodeResponse(message)
    this.handleSubscribeResponse(message)
    this.handleOrderSummaryResp(message)
    this.handleCardListResp(message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // this.getSubData()
    this.getUserData()
    const userId = this.getParamData()
    this.getSubscriptionsList(userId)
    // Customizable Area End
  }


  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
    // Customizable Area End
  }

  // Customizable Area Start
  getParamData = () =>{
    const userId = this.props.navigation.getParam('id')
    return userId;
  }
  getSubData=async()=>{
    const token = await getStorageData("authToken");
    this.setState({userToken:token})
    const header = {
      token:token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getSubscriptionsApi=(message:Message)=>{

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.setState({isLoading:false})

        if (apiRequestCallId === this.getSubsId) {
          if (apiRequestCallId && !responseJson.errors) {
          if(responseJson){
            const alreadySubs  =  responseJson.data.some((item:{
              id:string,
              type:string,
              attributes:{
                  subscribed:boolean,
                  status:string,
                  name:string,
                  id:number
              }
             })=>item.attributes.subscribed===true)

            this.setState({
              subscriptionDetails: responseJson.data,
              updateSubscription:alreadySubs

            },this.getActiveId);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }else{
          this.setState({apiError:responseJson.errors[0]})
        }
      }

    }

  }


  getUserDataResponse=(message:Message)=>{

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
        if (apiRequestCallId === this.apiGetDataCallId) {
          if (apiRequestCallId && !responseJson?.errors) {
          if(responseJson){
            this.setState({
             userSubscriptionId:responseJson.data.attributes.user_subscription_id
            });
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }

    }

  }

  getUserData = async () => {
    let token = await getStorageData("authToken");
    
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetDataCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getUserInfo);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  submitSubscriptionsApi=(message:Message)=>{
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

        if (apiRequestCallId === this.submitSubscriptionsId) {
          if (!responseJson.errors) {
          if(responseJson){
            this.setState({
              submittedDetails: responseJson.data
            });
            this.navigateToBecomeOwner()

          }
          this.parseApiCatchErrorResponse(errorReponse);
         }
         else{
          this.setState({apiError:responseJson.errors[0]})
         }
      }
    }
  }

  handleSelectSubscription=(activeId:number)=>{
    const result = this.state.subscriptionDetails.map((each) => {
      if (each.id === activeId) {
        return { ...each, attributes: { ...each.attributes, is_subscribed: !each.attributes.is_subscribed } }
      }
      return { ...each, attributes: { ...each.attributes, is_subscribed: false } }
    })
    this.setState({ subscriptionDetails: result }, () => {
      this.getActiveId()
    })
  }
  
  getActiveSub = () =>{
    const sub = this.state.subscriptionDetails.find((item) => item.id==this.state.activeId)
    return sub;
  }
  getActiveId=()=>{
    const activeIdArray=this.state.subscriptionDetails.filter((each)=>each.attributes.is_subscribed===true)
    this.setState({activeId:activeIdArray.length>0?activeIdArray[0]?.id:0})
    return activeIdArray[0]?.id
  }
 
  submitSubscription=()=>{
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      token:this.state.userToken
    };

    const sendData={
        "subscription_id":this.state.activeId
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitSubscriptionsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitSubscriptionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(sendData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.submitSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  handleSubsctiption=()=>{
    if(this.state.updateSubscription){
      this.updateSubscription()
    }else{
      this.setState({isSubscribe: true})
      this.getOrderSummary(this.state.activeId)
      this.getCardList()

    }
  }
  backToSubscriptionList =() =>{
    this.setState({isSubscribe: false})
  }
  getSubscriptionsList = (accountId:number) => {
    this.subScriptionsListId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getSubscriptionAPiMethod,
      endPoint: "bx_block_custom_user_subs/subscriptions?account_id="+accountId ,
    });
  }
  getOrderSummary = (subScriptionId: number,promoCodeId?:number) =>{
    this.orderSummaryId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getSubscriptionAPiMethod,
      endPoint: `bx_block_custom_user_subs/order_summary?subscription_id=${subScriptionId}&type=profile_subscription ${promoCodeId ? '&promo_code_id='+promoCodeId : ''}`
    })  
  }
  backToSubscribePage = () =>{
    this.setState({isSubscribe: false})
  }

  updateSubscription=()=>{
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      token:this.state.userToken
    };

    const sendData={
      "subscription_id":this.state.activeId
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitSubscriptionsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateSubscriptionApiEndPoint}/${this.state.userSubscriptionId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(sendData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchTypeApi
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  navigateToBecomeOwner=()=>{
    this.submitSubscriptionStatus()
  }

  submitSubscriptionStatus=async()=>{
    const formData = new FormData();
    formData.append("signup_state",'become_an_owner')
    const token = await getStorageData("authToken");

    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitSubscriptionStatusApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadPictureApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadPictureApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  showUserStatusSubscription=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.submitSubscriptionStatusApi) {
        console.log("success")

      } else {
        if (responseJson?.errors) {
          this.setState({activeState:'invalid token'})
        }
      }
    }
  }
  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body } = data;
    const token =  localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleSubscriptionListResponse = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.subScriptionsListId === apiRequestCallId && responseJson) {
      if (responseJson.data) {
        const data = responseJson.data
        this.setState({
          ...this.state,
          subscriptionDetails: data,
          isLoading: false
        })
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse)

      }
    }
  }
  handleOrderSummaryResp = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.orderSummaryId === apiRequestCallId && responseJson) {
      if (responseJson.data) {
        this.setState({
          ...this.state,
          orderSummary: responseJson.meta,
          isLoading: false
        })
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse)

      }
    }
  }
  handlePromoCodeResponse = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.promoCodeId === apiRequestCallId && responseJson) {     
      if (responseJson.detail) {
        this.setState({
          ...this.state,
          isLoading: false,
          promoCodeDetail:{
            isValid:true,
            msg: responseJson.message,
            promoCodeId: responseJson.promo_code_id,
            data:responseJson
          }
        })
        this.getOrderSummary(this.state.activeId,responseJson.promo_code_id)

      }
      else {
        this.setState({
          ...this.state,
          promoCodeDetail:{
            isValid: false,
            msg: responseJson.message,
            promoCodeId:'',
            data:{}
          }
        })
      }
    }
  }
  handleSubscribeResponse = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.subscribeUserId === apiRequestCallId && responseJson) {
      if (responseJson.subscription) {
        this.setState({
          ...this.state,
          isLoading: false,
          isSubscriptionDone: true
        })
      }else {
        this.setState({
          ...this.state,
          isLoading: false,
          apiError:responseJson.errors[0]
        })
      }
    }
  }

  handleCardListResp= (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.cardListId === apiRequestCallId && responseJson) {
      if (responseJson.data) {
        this.setState({
          ...this.state,
          isLoading: false,
          cardList: responseJson.data
        })
      }
    }
  }

  onPromoCodeChange = (value: string)=>{
    this.setState({
      promoCode: value
    })
  }
  applyPromoCode = () =>{
    this.promoCodeId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.submitSubscriptionAPiMethod,
      endPoint: "bx_block_custom_user_subs/apply_promo_code" ,
      body:{
        name:this.state.promoCode,
        subscription_id:this.state.activeId,
        applied: true
      }
    });
  }
  subscribeUserAPI =() =>{
    let body: any = {
      subscription_id: this.state.activeId,
      payment_method_id: this.state.selectedCard.id,
      price: this.state.orderSummary.total_amount
    }
    if (this.state.promoCodeDetail.promoCodeId) {
      body = {
        ...body,
        promo_code_id: this.state.promoCodeDetail.promoCodeId,

      }
    }
    this.subscribeUserId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.submitSubscriptionAPiMethod,
      endPoint: "bx_block_custom_user_subs/user_subscriptions",
      body
    });
    
  }
  autoRenewal = (event:any) =>{
    this.setState({isAutoRenewal: event.target.checked})
    this.autoRenewalId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodPatchType,
      endPoint: "bx_block_subscriptions/update_auto_renewal" ,
      body:{
        user_subscription_id: this.state.activeId,
    plan_auto_renewal: event.target.checked
      }
    })
  }
   
  addCard = () =>{
    this.props.navigation.navigate("Card")
  }
  getCardList = () =>{
    this.cardListId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getSubscriptionAPiMethod,
      endPoint: "bx_block_stripe_integration/cards",
    });
  }
  handleSelectCard = (id:string) =>{
    let selectedCard={};
    const cardList = this.state.cardList.map((item) =>{
      if(item.id === id){
        selectedCard = item;
        return {
          ...item,
          isSelected: true
        }
      }
      return {...item,isSelected: false}
    })
    this.setState({
      ...this.state,
      selectedCard: selectedCard,
      cardList
    })
  }
  cancelPromoCode = () =>{
    this.setState({
      ...this.state,
      promoCodeDetail:{isValid: false,data:{}} as any
    })
    this.getOrderSummary(this.state.activeId)
  }
  // Customizable Area End
}
