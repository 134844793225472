import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { verifyIcon, verifyOrange, verifyYellow } from "../../blocks/activityfeed/src/assests";
import { EventEmitter } from 'fbemitter'
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
const baseURL = require("../../framework/src/config").baseURL;
import { toast } from 'react-toastify';

export type TAny = any;

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

interface RequestParams {
  method?: HttpMethods;
  headers?: object;
  body?: object | FormData | any;
}
export function sendAPIRequest(endpoint: string, params?: RequestParams) {

  const { method = "GET", headers, body } = params || {}
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  )
  const callId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  )
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  )
  if (body)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body?.append ? body : JSON.stringify(body)
    )

  if (headers)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
  runEngine.sendMessage(requestMessage.id, requestMessage)
  return callId
}

export const convertMentionHashtagText = (input: string): string => {
  return input.replace(/@\*(.*?)\*|\@\[(#.*?)\]\(\d+\)/g, (match: string, p1: string, p2: string) => {
    if (p1 !== undefined) {
      return `${p1}`; // Convert @*mention* to @mention
    } else if (p2 !== undefined) {
      return p2; // Convert @[#hash_tag](5) to #hash_tag
    }
    return match; // Return the original match if no condition is met
  });
}

export const downloadGifFromUrl = async (gifUrl: string, gifName: string) => {
  try {
    const response = await fetch(gifUrl);
    const blob = await response.blob();
    const file = new File([blob], gifName, { type: 'image/gif' });
    return file
  } catch (error) {
    console.error('Error downloading GIF', error);
  }
};

export const removeStorageData = async (key: string) => {
  try {
    localStorage.removeItem(key);
    console.log(`Removed item with key: ${key}`);
  } catch (error) {
    console.error(`Error removing item with key: ${key}`, error);
  }
};

export const highlightText = (body: any, highlights: any) => {
  const combinedHighlights = [...highlights].sort((a, b) => a.indices[0] - b.indices[0]);

  let highlightedBody = '';
  let currentIndex = 0;

  combinedHighlights.forEach(highlight => {
    const start = highlight.indices[0];
    const end = highlight.indices[1];

    highlightedBody += body.substring(currentIndex, start);

    highlightedBody += `<span style="color: ${"#8429Ef"};" >${body.substring(start, end)}</span>`;
    currentIndex = end;
  });

  highlightedBody += body.substring(currentIndex);

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const outputString = highlightedBody.replace(urlRegex, (url) => {
    return `<a href=${url} target="_blank" style="color: ${"#8429Ef"};">${url}</a>`;
  });

  return outputString;
};

export const getBorderRadiusClass = (index: number, length: number, classes: any) => {
  if (length === 1) return classes.borderRadius;
  const borderClasses = [];
  if (index === 0) borderClasses.push(classes.topLeftRadius);
  if (index === 1) {
    if (length === 2) {
      borderClasses.push(classes.topRightRadius, classes.bottomRightRadius);
    } else {
      borderClasses.push(classes.topRightRadius);
    }
  }
  if (index === length - 2) {
    if (length === 3) {
      borderClasses.push(classes.bottomRightRadius);
    } else {
      borderClasses.push(classes.bottomLeftRadius);
    }
  }
  if (index === length - 1) {
    if (length === 3) {
      borderClasses.push(classes.bottomLeftRadius);
    } else {
      borderClasses.push(classes.bottomRightRadius);
    }
  }
  return borderClasses.join(' ');
};

export const verifyImg = (userSubscription: string) => {
  switch (userSubscription) {
    case 'I am a business':
      return verifyYellow
    case 'I am a news organization':
      return verifyOrange
    default:
      return verifyIcon
  }
}

export function convertTimestampToTime(timestamp: number): string {
  const date = new Date(timestamp * 1000);
  const hours: string = date.getUTCHours().toString().padStart(2, '0');
  const minutes: string = date.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}

export const handleConditionFunction = (condition: TAny, trueCondition: TAny, falseCondtion: TAny) => {
  return condition ? trueCondition : falseCondtion
}

export const emitterEvents = new EventEmitter()

interface Location {
  latitude: number | null;
  longitude: number | null;
}

export const getLocation = (): Promise<Location | null> => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.warn(error.message);
          reject(error.message);
        }
      );
    } else {
      reject('Geolocation is not supported by this browser.');
    }
  });
};

export const scrollIntoElement = (refElement: any, elementDom: string) => {
  if (refElement) {
    if (!refElement?.querySelectorAll) return;
    const elements = refElement.querySelectorAll(elementDom);
    if (!elements.length) return;
    elements[0].scrollTop = (elements[0].scrollHeight)
  }
}

const createBulkUpLoading = async () => {
  const url = `${baseURL}/bx_block_bulk_uploading/attachments`
  
  const response = await fetch(url, {
    method: 'POST',
    headers: {
        token: localStorage.getItem('authToken') || ''
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const responseData = await response.json()

  return responseData?.data?.id
}

const uploadChunk = async (file: Blob, attachmentId: string, id: number) => {
  const formData = new FormData();
  formData.append('id', String(attachmentId));
  formData.append('chunk_number', id.toString());
  formData.append('chunk', file);

  const url = `${baseURL}/bx_block_bulk_uploading/attachments/${attachmentId}/upload_chunk`
  
  const response = await fetch(url, {
    method: 'POST',
    headers: {
        token: localStorage.getItem('authToken') || ''
    },
    body: formData
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
}

const finalizeUpload = async (attachmentId: string) => {
  const url = `${baseURL}/bx_block_bulk_uploading/attachments/${attachmentId}/finalize_upload`
  
  const response = await fetch(url, {
    method: 'POST',
    headers: {
        token: localStorage.getItem('authToken') || ''
    },
    body: JSON.stringify({
      id: attachmentId
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const responseData = await response.json()
  return responseData?.success
}

export const chunkVideoFFmpeg = async (videoFile: File) => {
  const ffmpeg = createFFmpeg({
    log: true,
    mainName: 'main',
    corePath: 'https://unpkg.com/@ffmpeg/core-st@0.11.1/dist/ffmpeg-core.js',
  });

  const chunkTime = '20' // 20s
  let toastId: string | number = ''
  const styles = {
    backgroundColor: '#222',
    color: '#FFF',
  }

  try {
    if (!ffmpeg.isLoaded()) {
      await ffmpeg.load();
    }

    if (!videoFile) return;
    
    const extension = videoFile.name.split('.').pop()?.toLowerCase()
    toastId = toast.loading('Processing video...', {
      style: styles,
    });
    
    await ffmpeg.FS('writeFile', `input.${extension}`, await fetchFile(videoFile));

    await ffmpeg.run(
      '-i',
      `input.${extension}`,
      '-c',
      'copy',
      '-map',
      '0',
      '-segment_time',
      chunkTime,
      '-f',
      'segment',
      `output%05d.${extension}`,
    );

    const chunkFiles: Blob[] = []

    let i = 0;
    while (true) {
      try {
        // Read chunk file from FFmpeg's virtual file system
        const chunkData = await ffmpeg.FS('readFile', `output${String(i).padStart(5, '0')}.${extension}`);
        const chunkBlob = new Blob([chunkData.buffer], { type: videoFile.type });
        chunkFiles.push(chunkBlob);
        await ffmpeg.FS('unlink', `output${String(i).padStart(5, '0')}.${extension}`)
        i++;
      } catch (error) {
        // Break the loop if there is no more chunk to read (file not found)
        break;
      }
    }

    await ffmpeg.FS('unlink', `input.${extension}`)

    const attachmentId: string = await createBulkUpLoading()

    for (let chunkIndex = 0; chunkIndex < chunkFiles.length; chunkIndex++) {
      const chunkFile = chunkFiles[chunkIndex];
      await uploadChunk(chunkFile, attachmentId, chunkIndex);
      toast.update(toastId, {
        render: `Uploaded ${chunkIndex + 1} files of ${chunkFiles.length} total`,
        type: "info",
        autoClose: false,
        isLoading: true,
        style: styles,
      });
    }

    toast.update(toastId, {
      render: `Finalize Upload.`,
      type: "info",
      autoClose: 3000,
      isLoading: false,
      style: styles,
    });

    const isSuccess =  await finalizeUpload(attachmentId)
    if (isSuccess) {
      return attachmentId
    } else {
      throw new Error('Upload failed')
    }
  } catch (err) {
    if (toastId) {
      toast.update(toastId, {
        render: `Upload failed`,
        type: "error",
        autoClose: 3000,
        isLoading: false,
        style: styles,
      });
    } else {
      toast.error(`Upload failed`, {
        autoClose: 3000,
        style: styles,
      });
    }
    console.log('Load FFmpeg Error', err)
  }
}
