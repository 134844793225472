import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import codes from "country-calling-code";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { centerCrop, makeAspectCrop,Crop } from 'react-image-crop'
import React,{ RefObject } from 'react'

const countries = codes.sort((itemA, itemB) =>  itemA.country.localeCompare(itemB.country))
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked:boolean;
  classes:{ [key: string]: string }
  // Customizable Area End
}

interface S {
  currentProfile?: File,
  profileImageUrl: string;
  
  email: string;
  phoneNumber: string;
  profilePicture: Blob | null | boolean;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  attributes: any;
  personalProfile: any
  userProfileLoading:boolean,
  apiError:string,
  validationError:string
  selectPictureError:string;
  isEmailVerfy:boolean
  ismobileVerfy:boolean;
  isModel:boolean;
  otpInput:string;
  otpError:string;
  isOtpButtonClickable:boolean;
  modelType:string;
  selectedCategories:any;
  selectedSubCategories:any,
  categories:any;
  userOwnerShip:any
  selectedOwnerShip:any;
  congratulationModel:boolean;
  subScriptions:any;
  selectedsubScriptions:any;
  isPasswordVisible:boolean
  codesFilter: Array<{
    country: string;
    isoCode2: string;
    countryCodes: Array<string>;
  }>
  countryCode:string,
  ownerShip:any,
  isImage:boolean,
  crop:Crop,
  userImage: string;
  croppedFile:Blob|null,
  croppedImage:string,
  isImgCropped:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  getSocialMedialID:string ="";
  getCategoriesID:string ="";
  getOwnerShipID:string ="";
  token: string = "";
  emailVeryfyCallId:string= "";
  otpApiCallId:string="";
  resetOtpCallId:string ="";  
  updateCategoriesID:string="";
  getSubscriptionsID:string ="";
  updateOwnerShipId:string ="";
  updateSubscriptionId:string =""
  imageRef: RefObject<HTMLImageElement> = React.createRef();

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isImgCropped:false,
      croppedImage:'',
      croppedFile:null,
      userImage: "",
      profileImageUrl: "",
      attributes: [],
      email: "",
      profilePicture:null,
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      instagram: "",
      facebook: "",
      phoneNumber: "",
      personalProfile:{},
      userProfileLoading:false,
      apiError:"",
      validationError:"",
      selectPictureError:"",
      ismobileVerfy:false,
      isEmailVerfy:false,
      isModel:false,
      otpInput:"",
      otpError:"",
      isOtpButtonClickable:false,
      modelType:"",
      selectedCategories:[],
      selectedSubCategories:[],
      categories:[],
      userOwnerShip:[],
      selectedOwnerShip:{},
      congratulationModel:false,
      subScriptions:[],
      selectedsubScriptions:{},
      isPasswordVisible:false,
      codesFilter:countries,
      countryCode:"91",
      ownerShip:{},
      isImage:false,
      crop:{  unit: '%',
        x: 25,
        y: 25,
        width: 50,
        height: 50
      }
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    await this.fetchProfileData()
    await this.categoriesHnadler()
    await this.ownershipHandler()
    await this.subscriptionHandler()

    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson?.errors&&responseJson?.errors[0] =="Invalid token"){
        this.props.navigation.navigate("EmailAccountLoginBlock")
      }else {
        this.checkProfileAccount(message);
        this.categoriesRes(message)
        this.ownershipRes(message)
        this.handleEmailRes(message)
        this.handleUpdateRes(message)
        this.handleUpadteCategoriesRes(message)
        this.handleSubscription(message)
        this.handleUpadteProfile(message)        
      }
    }
    // Customizable Area End
  }

   // Customizable Area Start
  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes){
        this.setState({apiError:responseJson?.errors[0],userProfileLoading:false})
        return
      } 
        this.setState({
          personalProfile:responseJson.data.attributes ,
          phoneNumber:responseJson.data.attributes?.phone_number,
          email:responseJson.data.attributes?.email,
          isEmailVerfy:responseJson.data.attributes?.email?true:false,
          ismobileVerfy:responseJson.data.attributes?.full_phone_number?true:false,
          countryCode:responseJson.data.attributes?.country_code||"91",
          userImage:responseJson.data.attributes?.profile_photo
        })
       }
   }  

   categoriesRes =(message: Message) =>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getCategoriesID) {
      if (responseJson.data) {       
        this.setState({categories:responseJson.data},this.setSelectedCategories)
        }
        else if(responseJson.errors){
          this.setState({apiError:responseJson?.errors[0],userProfileLoading:false})
        }
      }

   }
   formatPricesWithComma = (data: any) => {
    if (!Array.isArray(data)) {
      return []; // Handle or log error as needed
    }
    return data.map((item: { attributes: { price: number } }) => ({
      ...item,
      attributes: {
        ...item.attributes,
        price: `$${item.attributes.price.toLocaleString()}`
      }
    }));
  };
  

   handleSelectCountryCode = (e: any) => {    
    this.setState({ countryCode: e.target.value[0] });
  };

   ownershipRes =(message: Message) =>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getOwnerShipID) {
      if (responseJson.data){
        this.setState({userOwnerShip:responseJson.data},this.handleSelectedOwnership) 
        this.setState({userOwnerShip:this.formatPricesWithComma(responseJson.data)
        })        
      }else if(responseJson.errors){
        this.setState({apiError:responseJson?.errors[0],userProfileLoading:false})
      }
     }

   }

   handleSelectedOwnership =() =>{
      let ownerShip = [...this.state.userOwnerShip]
      let filterValue =ownerShip.find(item => item.attributes.owned === true);      
      this.setState({selectedOwnerShip:filterValue,ownerShip:filterValue})
   }
   

   handleEmailRes =(message: Message) =>{    
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.emailVeryfyCallId) {
      if (responseJson.data && responseJson.meta) {
         this.token =  responseJson.meta?.token
         this.setState({isModel:true ,userProfileLoading:false})
      }else{       
        this.setState({apiError:responseJson?.errors[0] =="Token has expired"?"Otp expaired":responseJson?.errors[0], userProfileLoading:false})
      } }
   }

   handleUpdateRes =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.otpApiCallId) {
      if (responseJson.data) {
         this.setState({
          isModel:false ,userProfileLoading:false,congratulationModel:true,otpInput:""
        })
        if (this.state.modelType=="mobile"){
          let mobile:any = responseJson?.data?.attributes?.full_phone_number
          this.setState({personalProfile:{...this.state.personalProfile,full_phone_number:mobile},ismobileVerfy:true})
        }else{
          let email:any = responseJson.data.attributes?.email
          this.setState({personalProfile:{...this.state.personalProfile,email:email},isEmailVerfy:true})
        }
      }else{
        this.setState({otpError:responseJson.errors[0], userProfileLoading:false})
      } }

   }

   handleUpadteCategoriesRes =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.updateCategoriesID) {
      if (responseJson.messages) { 
         this.setState({userProfileLoading:false})
      }else{
        this.setState({apiError:responseJson.errors[0] ,userProfileLoading:false})
      } }

   }

   handleSubscription =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getSubscriptionsID) {
      if (responseJson.data) { 
         this.setState({userProfileLoading:false,subScriptions:responseJson.data},this.handleSelectedSubscription)
      }else{
        this.setState({apiError:responseJson.errors[0] ,userProfileLoading:false})
      } }
   }

   handleSelectedSubscription =() =>{
    let subScriptions = [...this.state.subScriptions]
    let filterValue = subScriptions.find(item => item.attributes.is_subscribed === true);      
    this.setState({selectedsubScriptions:filterValue})
   }

   handleUpadteProfile =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.updateProfileID) {
    if (!responseJson.data || !responseJson.data.attributes){
      this.setState({apiError:responseJson?.errors[0],userProfileLoading:false})
      return
    } 
    this.setState({
      isEmailVerfy:responseJson.data.attributes?.email?true:false,
      phoneNumber:responseJson.data.attributes?.full_phone_number,
      personalProfile:responseJson.data.attributes ,
      ismobileVerfy:responseJson.data.attributes?.full_phone_number?true:false,
      email:responseJson.data.attributes?.email, 
      modelType:"profile",
      congratulationModel:true    
    })
   }
  }
   
   handleChangeCategories = (event: any) => {
    const selectedIds = event.target.value;
    const updatedCategories = this.state.categories.map((category: any) => ({
        ...category,
        attributes: {
            ...category.attributes,
            is_selected: selectedIds.includes(category.attributes.id)        }
    }));

    this.setState({ categories: updatedCategories }, this.setSelectedCategories);
   }

  setSelectedCategories = () => {
    const selectedCategories = this.state.categories.filter((category:any) => category.attributes.is_selected);
    this.setState({ selectedCategories },this.setSelectedSubCategories);
  };

  setSelectedSubCategories = () => {
    const selectedSubCategories = this.state.categories
      .filter((category:any) => category.attributes.is_selected) 
      .flatMap((category:any) => category.attributes.sub_categories)
      .filter((subCategory:any) => subCategory.is_selected);
    this.setState({ selectedSubCategories });
  };

  handleChangeSubCategories = (id:any) => {
    const updatedCategories = this.state.categories.map((category:any) => ({
      ...category,
      attributes: {
        ...category.attributes,
        sub_categories: category.attributes.sub_categories.map((subCategory:any) => {
          if (subCategory.id === id) {
            return {
              ...subCategory,
              is_selected: true,
            };
          }
          return subCategory;
        }),
      },
    }));

    this.setState({ categories: updatedCategories }, this.setSelectedCategories);
  };

   handleDeleteCategories = (id:any) => {    
    let cat = [...this.state.categories]
    const updatedCategories = cat.map((category:any) => {
      if (category.attributes.id == id) {
        return {
          ...category,
          attributes: {
            ...category.attributes,
            is_selected: false
          }
        };
      }
      return category;
    });
    this.setState({ categories: updatedCategories },this.setSelectedCategories)
  };

  handleDeleteSubCategories = (id:any) => {
    const updatedCategories = this.state.categories.map((category:any) => ({
      ...category,
      attributes: {
        ...category.attributes,
        sub_categories: category.attributes.sub_categories.map((subCategory:any) => {
          if (subCategory.id === id) {
            return {
              ...subCategory,
              is_selected: false,
            };
          }
          return subCategory;
        }),
      },
    }));
    this.setState({ categories: updatedCategories }, this.setSelectedCategories);
  };

  categoriesHnadler = async()=>{
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getCategoriesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/categories"
    
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
 
  }

  ownershipHandler =async() =>{
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getOwnerShipID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_user_subs/ownerships`    
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  subscriptionHandler =async() =>{
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getSubscriptionsID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_subscriptions/subscriptions`    
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  personalForm =async(value:any)=>{
    this.setState({userProfileLoading:true})
    const token = await getStorageData("authToken");
    const header = {
      token: token
    };

    let formData = new FormData()
    formData.append("user_name",value.username)
    formData.append("full_name",value.name)    
    this.state.profilePicture && formData.append("profile_photo", this.state.croppedFile!==null?this.state.croppedFile:this.state.profilePicture  as Blob);
    formData.append("bio",value.bio)
    formData.append("date_of_birth",value.date_of_birth)  
    if(value.password) {
      formData.append("password",value.password) 
      formData.append("confirm_password",value.password) 
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateProfileID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/update_profile");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiVerifyOtpMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  categoriesForm =async()=>{
    this.setState({userProfileLoading:false})
    const token = await getStorageData("authToken");
    const header = {
      token: token,
      "Content-Type": configJSON.searchApiContentType,
    };
    
  
    const selectedSUbcaregorisIds = this.state.selectedCategories
    .filter((category:any) => category.attributes.is_selected)
    .flatMap((category: any) => category.attributes.sub_categories)
    .filter((subCategory: any) => subCategory.is_selected) 
    .map((subCategory: any) => subCategory.id) 
     

    const selectedCategoryIds = this.state.selectedCategories
    .filter((category:any) => category.attributes.is_selected) 
    .map((category:any) => category.id)

  const httpBody ={
    selected_ids: {
      category_ids:selectedCategoryIds,
      sub_category_ids: selectedSUbcaregorisIds,
    },
  } 
   
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCategoriesID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_categories/user_categories");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
     
  }

  ownerShipForm = async()=>{
    this.setState({userProfileLoading:false})
    const token = await getStorageData("authToken");
    const header = {
      token: token,
      "Content-Type": configJSON.searchApiContentType,      
    };

    let filterValue =this.state.userOwnerShip.find((item:any) => item.attributes.owned === true);      
      
     if(filterValue === this.state.ownerShip){
      return
     } 
     const httpBody ={
      "ownership_id": this.state.ownerShip?.id 
    }   
    let method =  this.state.personalProfile?.user_ownership_id ?configJSON.apiVerifyOtpMethod:configJSON.httpPostMethod
    let endpont =  this.state.personalProfile?.user_ownership_id ?`bx_block_custom_user_subs/user_ownerships/${this.state.personalProfile?.user_ownership_id}`:"bx_block_custom_user_subs/user_ownerships"
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateOwnerShipId= requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endpont);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  subscriptionForm = async()=>{
    this.setState({userProfileLoading:false})
    const token = await getStorageData("authToken");
    const header = {
      token: token,
      "Content-Type": configJSON.searchApiContentType,
    };

    let filterValue =this.state.subScriptions.find((item:any) => item.attributes.subscribed === true);         
     if(filterValue === this.state.selectedsubScriptions){
      return
     } 
     const httpBody ={
      "subscription_id": this.state.selectedsubScriptions?.id  
    }   
    
    let method =  this.state.personalProfile?.user_subscription_id ?configJSON.apiVerifyOtpMethod:configJSON.httpPostMethod
    let endpont =  this.state.personalProfile?.user_subscription_id ?`bx_block_subscriptions/user_subscriptions/${this.state.personalProfile?.user_subscription_id}`:"bx_block_subscriptions/user_subscribe"
    
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateSubscriptionId= requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endpont);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleSubmit = async(value:any) =>{
    if(!this.state.personalProfile.email){
      this.setState({apiError:"",validationError:"Email is required"})
      return
    }
    this.setState({apiError:""})
    this.personalForm(value)
    this.categoriesForm()  
    this.ownerShipForm() 
    this.subscriptionForm()     
  }

  fetchProfileData = async () => {
    this.setState({userProfileLoading:true})
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProgileDataEndPoint
    
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangePicture=(event: React.ChangeEvent<HTMLInputElement>)=>{
    const file = event.target.files?.[0];
    if (file  && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      this.setState({isImgCropped:false,profilePicture:file,selectPictureError:''},()=>{
         this.setState({isImage:true ,userImage:URL.createObjectURL(file)})
      })
    }
    else{
      this.setState({selectPictureError:configJSON.picError})
    }
  }

  handleSharePriceChange =(event:any,setFieldValue:any) =>{    
    let selecShare = this.state.userOwnerShip.filter((val:any)=>val.id==event.target.value)
    this.setState({ownerShip:selecShare[0]})   
    setFieldValue('Share_price', selecShare[0].attributes.price);

  }

  handleSubscriptionChange =(event:any) =>{    
    let selecShare = this.state.subScriptions.filter((val:any)=>val.id==event.target.value)
    this.setState({selectedsubScriptions:selecShare[0]})  

  }

  isEmailReg = (value: string) => {
    const atSymbolRegex = /@/;
    return atSymbolRegex.test(value);
  };

  isPhoneNumberReg = (value: string) => {
    const numbersRegex = /^\d+$/;
    return numbersRegex.test(value);
  };

  handleEmailChnage = (event: { target: { value: string } }) =>{
    const {value} = event.target
    if(this.state.personalProfile.email){
      this.setState({
        email:value,
        isEmailVerfy :this.state.personalProfile.email === value,
        validationError:"",
        apiError:""
      })   
    }else{
      this.setState({
        email:value,
        validationError:"",
        apiError:""
      })
    }    
  }

  hanadleEmailVerfy = async(setFieldError?:any) =>{    
    if (this.isEmailReg(this.state.email)) {
      const emailRegex = configJSON.emailRegex;
    if (emailRegex.test(this.state.email)) {
      setFieldError&&setFieldError("email", undefined);
        this.setState({
          validationError: "",
          modelType:"email" ,
          userProfileLoading:true ,
          apiError:""        
    })  
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };
    const httpBody ={
      "data": {
        "attributes": {
          "email": this.state.email,
        }
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.emailVeryfyCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateEmailEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);

  } else {
        this.setState({
          validationError: "Incorrect Email id",
        })
        return
      }
    }
    else{
      this.setState({
        validationError: "Incorrect Email id",
      })
    }
  }

  hanadleMobileVerfy = async() =>{
      const countryCodeMap:any = this.state.codesFilter.filter((countryCode) => {
        return countryCode.countryCodes[0] == this.state.countryCode
      });        

      const parsedNumber = parsePhoneNumberFromString(`+${this.state.countryCode} ${this.state.phoneNumber}`,countryCodeMap[0].isoCode2);

      if (parsedNumber ? parsedNumber.isValid() : false) {
        this.setState({
          validationError: "",
          modelType:"mobile",
          userProfileLoading:true,
          apiError:""
          
        })
        const token = await getStorageData("authToken");
       const header = {
        "Content-Type": configJSON.searchApiContentType,
         token: token
      };

       const httpBody ={
        "data": {
          "attributes": {
            "full_phone_number": `${this.state.countryCode} ${this.state.phoneNumber}`,
          }
         }
       }
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.emailVeryfyCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateEmailEndpoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
      runEngine.sendMessage(requestMessage.id, requestMessage);
      } else {
        this.setState({
          validationError: "Incorrect Phone Number",
        })
        return
      }
  }
       
  handleMobileChnage = (event: { target: { value: string } }) =>{ 
    const {value} = event.target   
    if(this.state.personalProfile.full_phone_number){
      this.setState({
        phoneNumber:value,
        ismobileVerfy :this.state.personalProfile.full_phone_number === `${this.state.countryCode}${value}`,
        validationError:"",
        apiError:""
      })   
    }else{
      this.setState({
        phoneNumber:value,
        validationError:"",
        apiError:""
      })
    }   
  }

  handleChangeOtp = (event: { target: { value: string } }) => {
    const otpRegex = configJSON.otpRegex;
    this.setState({ otpInput: event.target.value },()=>{
      this.handleEnableDisableOtpButton()
    });
    if (event.target.value === "") {
      this.setState({ otpError: configJSON.otpFieldError });
    } else if (!otpRegex.test(event.target.value)) {
      this.setState({ otpError: "",apiError:"" });
    } 
  };

  handleEnableDisableOtpButton=()=>{
    if(this.state.otpInput!=='' && configJSON.otpRegex.test(this.state.otpInput)){
      this.setState({isOtpButtonClickable:false})
    }
    else{
      this.setState({isOtpButtonClickable:true})
    }
  }

  verifyOtphandler = async () => {
    const otpRegex = configJSON.otpRegex;
    if (this.state.otpInput === "") {
      this.setState({ otpError: configJSON.otpFieldError });
    } else if (!otpRegex.test(this.state.otpInput)) {
      this.setState({ otpError: configJSON.enterValidOtp });
    } else {
      this.setState({ otpError: "" });
    }
    if (this.state.otpInput !== "" && otpRegex.test(this.state.otpInput)) {
      this.setState({userProfileLoading:true})
      const token = await getStorageData("authToken");

      const header = {
        "Content-Type": configJSON.searchApiContentType,
        token:token
      };

      const sendData = {
        otp_code: this.state.otpInput,
        token: this.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.otpApiEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(sendData)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleNavigation =()=>{
    this.props.navigation.navigate("LandingPage")
  }

  handlePreviousNavigation =() =>{
    this.props.navigation.goBack()
  }

  handleLogout =async()=>{
    await removeStorageData("authToken")
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  toggleHnadle=()=>{
    this.setState((prevState) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  }
  

  handleResendOtp =async() =>{
    if(this.state.modelType=="mobile"){
      this.hanadleMobileVerfy()
    }
    else{
       this.hanadleEmailVerfy()
    }
  }  
  updateCroppedImage=(c:Crop)=>{
    this.setState({crop:c})
  }
  handleCropComplete=async()=>{
    const croppedImageBlob = await this.getCroppedImg();
    this.sendCroppedImage(croppedImageBlob)
  }
  getCroppedImg = () => {
    if(this.imageRef.current!==null){
      
    
    const canvas = document.createElement('canvas');
    const scaleX =this.imageRef.current.naturalWidth / this.imageRef.current.width;
    
    const scaleY = this.imageRef.current.naturalHeight / this.imageRef.current.height;

    canvas.width = this.state.crop.width;
    canvas.height = this.state.crop.height;

    const ctx = canvas.getContext('2d');
    if(ctx!==null){
      ctx.drawImage(
        this.imageRef.current,
        this.state.crop.x * scaleX,
        this.state.crop.y * scaleY,
        this.state.crop.width * scaleX,
        this.state.crop.height * scaleY,
        0,
        0,
        this.state.crop.width,
        this.state.crop.height
      );
    }
    

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  }
  };

  sendCroppedImage = async (blob:Blob|unknown) => {   
   const croppedImageUrl = URL.createObjectURL(blob as Blob);
   this.setState({croppedImage:croppedImageUrl})
   this.setState({croppedFile:blob as Blob})
  }
  onImageLoad=(e:{currentTarget:{width:number,height:number}})=>{
    const{width,height}=e.currentTarget
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 50,
        },
        1,
        width,
        height
      ),
      width,
      height
    )

    this.setState({crop:crop})
  }
  closeDragImage=()=>{
    this.setState({isImage:false,isImgCropped:true})
  } 
  // Customizable Area End
}
