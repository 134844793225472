// Customizable Area Start
import React from 'react'
import { Box, Button, Typography, createStyles, withStyles } from '@material-ui/core'
import { noInternetIcon, retryIcon } from '../src/assests'
import ImageRenderer from './ImageRenderer.web'

const NoInternetComponent = ({classes, checked} : {classes: any, checked: boolean}) => {
	const handleReload = () => {
		const windowInstance = window
		windowInstance.location.reload()
	}

	return (
		<Box className={classes.container}>
			<ImageRenderer src={noInternetIcon} style={{color: checked ? '#000' : "#FFF"}}/>
			<Typography className={classes.detailText}>
				Looks like you lost your connection. Please check it and try again.
			</Typography>
			<Button className={classes.retryBtn} onClick={handleReload}>
				<img src={retryIcon} style={{marginRight: '8px'}} />
				Retry
			</Button>
		</Box>
	)
}

const styles = createStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '20px',
		backgroundColor: 'rgba(117, 117, 117, 0.05)',
		borderRadius: '6px',
		width: '420px',
		padding: '12px',
		margin: '0 auto',
	},
	detailText: {
		fontFamily: "Manrope",
    fontSize: "12px",
		fontWeight: 500,
	},
	retryBtn: {
    height: '32px',
    borderRadius: '5px',
    background: 'linear-gradient(-45deg, #3858E3, #BA05F7)',
    color: '#fff',
		fontFamily: "Manrope",
    fontSize: "14px",
		fontWeight: 700,
		padding: '0 16px',
    alignItems: 'center',
    justifyContent: 'center',
    "&:hover": {
      background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    },
  },
})

export default withStyles(styles)(NoInternetComponent) 

// Customizable Area End
