import { Box, Button, Grid, IconButton, InputAdornment, Tab, Tabs, styled, MenuItem, Typography, Select } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AdditionalResourcesController, { Props } from "./AdditionalResourcesController";
import { arrowRight24White, backArrowDark, backArrowWhite, downArrowIconDark, downArrowIconLight, sucIcon, forwardArrowDark, forwardArrowLight, greenRightIcon, searchIconDark, searchIconWhite, upArrowIconLight, upArrowIconDark } from "./assets";
import { Formik, Form, Field } from "formik";
import FormikInputField from "../../../components/src/FormikInputField.web";
import Loader from "../../../components/src/Loader.web";
import React from "react";
export const configJSON = require("./config");
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


export default class AdditionalResources extends AdditionalResourcesController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  CustomMenu = withStyles((theme) => ({
    root: {  
      list: {
        paddingTop: '0px', 
        paddingBottom: '0px', 
      },
      '&:hover': {
        backgroundColor: this.props.checked?'#d3d3d3':"black",
      },
      '&.Mui-selected': {
        background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
      },      
       "&.MuiPaper-rounded":{
        borderRadius: "8px !important",
       },
      '&.MuiList-padding': {
        padding:"0px !important"
      },         
      alignItems: "center",
      gap: "8px",
      backgroundColor: this.props.checked?"var(--Neutrals-Cool-gray-50, #E9E9E9)":"var(--Neutrals-Cool-gray-500, #222)",
      display: "flex",
      width: "100%",
      height: "44px",
      justifyContent: 'space-between',
      fontWeight:600
    },
  }))(MenuItem);

  renderHeader = (name:string, goTo:string) => {
    return (
      <Box className="headerNav" onClick={() => { (goTo === 'Settings') ? this.props.navigation(goTo) : this.setState({renderAdditionalResources: goTo})}} data-test-id="back-btn">
        <Grid container style={{alignItems:'center'}}>
          <Grid item ><img src={this.props.checked? backArrowDark : backArrowWhite} /></Grid>
          <Grid item style={{marginLeft:'12px'}}><Typography className="h2" style={this.props.checked ? {color:'#222222'} : {color:'#fff'}}>{name}</Typography></Grid>
        </Grid>
      </Box>
    )
  }

  renderRightArrow = () => {
    const {checked} = this.props;
    return (<Grid item style={{marginLeft:'auto'}}><img src={checked ? forwardArrowLight : forwardArrowDark } /></Grid>)
  }

  renderDownArrow = () => {
    const {checked} = this.props;
    return (<Grid item style={{marginLeft:'auto'}}><img src={checked ? downArrowIconLight : downArrowIconDark } /></Grid>)
  }

  renderUpArrow = () => {
    const {checked} = this.props;
    return (<Grid item style={{marginLeft:'auto'}}><img src={checked ? upArrowIconLight : upArrowIconDark } /></Grid>)
  }

  renderExpandable = (name: string, index: number, answer: string) => {
    const {checked} = this.props;
      return <Box className={checked ? "grayBoxLight" : "grayBoxDark" }>
         <Grid container className="cursor">
           <Grid item><Typography className="h5">{name}</Typography></Grid>
           <Box onClick={() => this.expandQuestionAndAns(index)} style={{marginLeft:'auto'}} data-test-id="expand-qans">
             {this.state.selectedFAQItem === index ? this.renderUpArrow() : this.renderDownArrow()}
           </Box>
         </Grid>
         {this.state.selectedFAQItem === index && <Box className="horizontalLine"></Box>}
         {this.state.selectedFAQItem === index && <Typography style={{fontSize:'14px', lineHeight:'21px'}}>{answer}</Typography>}
     </Box>
  }
  
  renderSubCat = () => {
    const {checked} = this.props;
    return (
      <Box data-test-id="additional-resources" className="wrapper">
        {this.renderHeader("Additional resources", "Settings")}
        <Box className={checked ? "grayBoxLight" : "grayBoxDark" }>
          <Typography className="h6">Version 1.01.0-release.0</Typography>
        </Box>
        <Box className={checked ? "grayBoxLight" : "grayBoxDark" } style={{gap:'30px'}}>
          <Typography className="h4" style={{fontWeight: 800}}>Legal</Typography>
          <Grid container className="cursor" data-test-id="cookie-use-btn" onClick={() => {this.setState({renderAdditionalResources:'Cookie Use'});}}>
            <Grid item><Typography className="h5">Cookie Use</Typography></Grid>
            {this.renderRightArrow()}
          </Grid>
          <Grid container className="cursor" data-test-id="privacy-policy-btn" onClick={() => {this.setState({renderAdditionalResources:'Privacy Policy'});}}>
            <Grid item><Typography className="h5">Privacy Policy</Typography></Grid>
            {this.renderRightArrow()}
          </Grid>
          <Grid container className="cursor" data-test-id="terms-of-service-btn" onClick={() => {this.setState({renderAdditionalResources:'Terms of Service'});}}>
            <Grid item><Typography className="h5">Terms of service</Typography></Grid>
            {this.renderRightArrow()}
          </Grid>
          <Grid container className="cursor" data-test-id="legal-notices-btn" onClick={() => {this.setState({renderAdditionalResources:'Legal Notices'});}}>
            <Grid item><Typography className="h5">Legal notices</Typography></Grid>
            {this.renderRightArrow()}
          </Grid>
        </Box>
        <Box className={checked ? "grayBoxLight" : "grayBoxDark" }>
          <Grid container className="cursor" onClick={() => this.getDataHelpCenter()} data-test-id="help-center-btn">
            <Grid item><Typography className="h5">Help Center</Typography></Grid>
            {this.renderRightArrow()}
          </Grid>
        </Box>
      </Box>
    );
  }
  renderErrorMsgContainer = () => {
    return <React.Fragment>
       {this.state.errorMsg !== "" && <div className="pasErrorContainer " > 
                {this.state.errorMsg}
                </div>}
    </React.Fragment>
   }
  
  renderSelectIconComponent = (iconProps: any) => (
    <KeyboardArrowDownIcon  {...iconProps}  style={{color: this.props.checked?"balck":"white",right:"0px"}} />
  )

  renderSelectCountryCode = () => {

    return(this.state.codesFilter.length > 0 &&
      <Select                       
        IconComponent={this.renderSelectIconComponent}
        style={{borderRadius:"10px"}}
        value={`+${this.state.countryCode}`}
        onChange={this.onCountryCodeChange}
        renderValue={(selected: any) =>  selected}
        name="contryCode" 
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          onEntering: (element: any) => {
            const firstItem = element.querySelector('li');
            if (firstItem) {
              firstItem.blur();
            }
          }
        }}
        disableUnderline                                          
        variant="outlined"
        id="contry_code" 
        className="helpCenter-CountryCode"    
        data-test-id="country-code-select"
        >
        {this.state.codesFilter.map((val:any) => (
            <this.CustomMenu key={val.isoCode2} autoFocus={false}  value={val.countryCodes}>
              <div style={inputStyles.contryStyle}> 
              <Typography style={inputStyles.contryFont} > +{val.countryCodes} </Typography >
              <Typography  style={inputStyles.contryFont}> {val?.country} </Typography ></div>
              <img width={36} height={18} src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${val.isoCode2}.svg`} />
            </this.CustomMenu>
        )
        )} 
       </Select>)
  }

  renderHelpCenter = () => {
    const {checked} = this.props;
    return (
      <Box data-test-id="help-center" className="wrapper">
        {this.renderHeader("Help Center", "Additional Resources")}
        <Box>
          <Tabs value={this.state.selectedTab} className="tabs" indicatorColor="secondary" textColor="secondary" variant="fullWidth" data-test-id="tabBtn">
            <Tab className="tabStyle" label="FAQ" onClick={() => this.changeTab(0)} data-test-id='FAQ-tab'/>
            <Tab className="tabStyle" label="Contact us" onClick={() => this.changeTab(1)} data-test-id='contact-us-tab'/>
          </Tabs>
        </Box>

        {this.state.selectedTab === 1 && <Box className="flexWrapper" style={{gap:'10px'}}>
        {this.state.formError && <Box style={this.props.checked ? inputStyles.errorBoxLightEmail : inputStyles.errorBoxEmail}>
                    <Typography style={inputStyles.errorText} data-test-id='errors'>{this.state.formError}</Typography>
                  </Box>}
        <Formik validate={this.resetError} initialValues={{name: "", email:"", phone:"", message:"" }} onSubmit={() => {}}>
            {(formikProps) => {
              const {values} = formikProps;
              return <Form className="changePassForm" noValidate autoComplete="off">
               {this.renderErrorMsgContainer()}
              <div className="fieldContainer">
                <label htmlFor="name">Name</label>
                <Field component={FormikInputField} id="name" className = "passField" name="name" value={this.state.name} onChange={(e: { target: { value: any; }; }) => {this.setState({name:e.target.value})}} placeholder="Enter name" variant="outlined" fullWidth data-test-id="name-input"/>
              </div>

                <Grid container spacing={1} style={{alignItems:"end"}}>
                  <Grid item xs={2}>
                    <div className="fieldContainer">
                      <label htmlFor="phone">Phone</label>
                      {this.renderSelectCountryCode()}
                    </div>
                  </Grid>
                  <Grid item xs={10}>
                    <div className="fieldContainer">
                        <Field
                            component={FormikInputField}
                            id=""
                            className = "passField"
                            name="phone"
                            data-test-id="phone-input"
                            value={this.state.phoneNumber}
                            onChange={(e: { target: { value: any; }; }) => {this.changePhoneNumber(e.target.value);}}
                            placeholder="Enter phone number"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                              <InputAdornment position="start">
                                  <IconButton style={inputStyles.iconButton}>
                                    {this.state.validPhoneNumber && <img style={{marginRight:'8px'}} src={greenRightIcon}/>}
                                  </IconButton>
                              </InputAdornment>
                              ),
                            }}
                        />
                    </div>
                  </Grid>
                </Grid>
                    
                <div className="fieldContainer">
                  <label htmlFor="email">Email</label>
                    <Field component={FormikInputField} id="email" className = "passField" name="email" value={this.state.email} onChange={(e: { target: { value: any; }; }) => {this.changeEmail(e.target.value);}} placeholder="Enter email ID" variant="outlined" fullWidth data-test-id="email-input" InputProps={{
                              endAdornment: (
                              <InputAdornment position="start">
                                  <IconButton style={inputStyles.iconButton}>
                                    {this.state.validEmail && <img style={{marginRight:'8px'}} src={greenRightIcon}/>}
                                  </IconButton>
                              </InputAdornment>
                              ),
                            }}/>
                </div>

                <div className="fieldContainer">
                  <label htmlFor="message">Message</label>
                  <textarea className="messageField" id="message" name='message' rows={4} placeholder='Enter message here' value={this.state.message} onChange={(e) => {this.changeMessage(e.target.value)}} data-test-id="message-input" style={ checked ? {backgroundColor:'#fbf9ff'} : {backgroundColor:'#100c18', color:'#fff'}}></textarea>
                  <Typography className="messageLimitTxt" style={{textAlign:'end'}}>{this.state.messageWordsLength}/100</Typography>
                </div>

                <Box style={{display:"flex", justifyContent:'end'}}>
                  <Button data-test-id="submit-btn" className="submitButton" type="submit" onClick={() => {this.contactUsSubmit()}}>
                    <p className="label">Submit</p> 
                    <span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
                  </Button>
                </Box>
              </Form>
            }}
          </Formik>
          </Box>}

        {this.state.selectedTab === 0 &&  <Box>
          <Grid container spacing={3}>
            {this.state.FAQGroupList.map((item, index) => {
              return <Grid item key={index}>
                        <Button 
                          className={this.state.selectedFAQGroup === index ? "fillBtn" : checked ? "outlineBtnLight" : "outlineBtnDark"}
                          onClick={() => this.getFAQList(item['attributes']['name'], index)}
                          data-test-id={`FAQ-group-button-${index}`}>
                          <span className={this.state.selectedFAQGroup === index ? "label" : "coloredTxt"}>{item['attributes']['name']}</span>
                        </Button>
                      </Grid>
            })}
          </Grid>
        </Box>}

        {this.state.selectedTab === 0 && <Formik initialValues={{search:'' }} onSubmit={() => {}}>
            {(formikProps) => {
              return <Form className="changePassForm" noValidate autoComplete="off" style={{marginBottom:'0px'}}>
              <div className="fieldContainer" style={{paddingBottom:"0px"}}>
                <Field component={FormikInputField} 
                  id="search" 
                  className = "passField" 
                  name="search" 
                  value={this.state.searchedFaq} 
                  onChange={(e: { target: { value: any; }; })=> this.handleFAQSearch(e.target.value)} 
                  placeholder="Search" variant="outlined" 
                  fullWidth 
                  data-test-id="search-input"
                  InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <img src={checked ? searchIconDark: searchIconWhite } />
                    </InputAdornment>
                    ),
                  }}
                />
              </div>
              </Form>
            }}
          </Formik>}

        {this.state.selectedTab === 0 && <Box className="flexWrapper">
          { this.state.searchedFaq != "" ?
            this.state.filteredFAQList.map((item, index) => {
              return this.renderExpandable(item['attributes']['question'], index, item['attributes']['answer'])
            })
          : this.state.FAQList.map((item, index) => {
           return this.renderExpandable(item['attributes']['question'], index, item['attributes']['answer'])
          })}
        </Box>}
      </Box>
    )
  }

  renderCookieUse = () => {
    var list = ["Keep you logged in to Arrows.", "Deliver features and functionality of Arrows services.", "Save and honour your preferences.",
     "Personalise the content you see.", "Protect you against spam and abuse.", "Show you more relevant ads.", "Provide subscription features and distribute certain content.",
      "Understand how you interact with our services and where we can improve.", "Measure the effectiveness of our advertising and marketing.",
      "Rate how our services perform and identify bugs and other quality issues. ", "Gather data used to operate our business — from measuring our audience size to enforcing the Arrows."];

    return (
      <Box data-test-id="cookie-use-tab" className="wrapper" style={{gap:'20px'}}>
        {this.renderHeader("Cookie Use", "Additional Resources")}
        <Box className="flexWrapper" style={{gap:'20px'}}>
          <Typography className="h1">How cookies are used on Arrows</Typography>
          <Box className="horizontalLine" />
          <Box
            className={this.props.checked?"flexWrapperDark":"flexWrapper2"} style={{gap:'10px'}}
            dangerouslySetInnerHTML={{ __html: this.state.termsOfService?.description as string }}
          />
        </Box>
      </Box>
    )
  }

  renderPrivacyPolicy = () => {
    return (
      <Box data-test-id='privacy-policy-tab' className="wrapper" style={{gap:'20px'}}>
        {this.renderHeader("Privacy Policy", "Additional Resources")}
        <Box className="flexWrapper" style={{gap:'20px'}}>
        <Typography className="h1">Arrows Privacy Policy</Typography>
          <Box className="horizontalLine" />
          <Box
            className={this.props.checked?"flexWrapperDark":"flexWrapper2"} style={{gap:'10px'}}
            dangerouslySetInnerHTML={{ __html: this.state.privacyPolicy?.description as string }}
          />
        </Box>

      </Box>
    )
  }

  renderTermsofService = () => {
    return (
      <Box className="wrapper" data-test-id="terms-of-service-tab" style={{gap:'20px'}}>
        {this.renderHeader("Terms of Service", "Additional Resources")}
        <Box className="flexWrapper" style={{gap:'20px'}}>
          <Typography className="h1">Arrows Terms of service</Typography>
          <Box className="horizontalLine" />
          <Box
            className={this.props.checked?"flexWrapperDark":"flexWrapper2"} style={{gap:'10px'}}
            dangerouslySetInnerHTML={{ __html: this.state.termsOfService?.description as string }}
          />
        </Box>

      </Box>
    )
  }
  
  renderLegalNotices = () => {
    return (
      <Box className="wrapper" data-test-id="legal-notices-tab">
        {this.renderHeader("Legal Notices", "Additional Resources")}
        <Box className="flexWrapper" style={{gap:'20px'}}>
          <Typography className="h1">Notices on Arrows and what they mean</Typography>
          <Box className="horizontalLine" />
          <Box
            className={this.props.checked?"flexWrapperDark":"flexWrapper2"} style={{gap:'10px'}}
            dangerouslySetInnerHTML={{ __html: this.state.legalNotices?.description as string }}
          />
        </Box>

      </Box>
    )
  }

  renderMessage = () => {
    return(
        <div className="messageScreen">
            <div className="messageContainer">
                <img src={this.state.messageType === "suc" ? sucIcon : ""} />
                <span>{this.state.succMessage}</span>
            </div>
        </div>
    )
  }

  renderSection = () => {
    return (
      <Box>
        {this.state.renderAdditionalResources === "Additional Resources" && this.renderSubCat()}
        {this.state.renderAdditionalResources === "Help Center"  && this.renderHelpCenter()}
        {this.state.renderAdditionalResources === "Cookie Use" && this.renderCookieUse()}
        {this.state.renderAdditionalResources === "Privacy Policy" && this.renderPrivacyPolicy()}
        {this.state.renderAdditionalResources === "Terms of Service" && this.renderTermsofService()}
        {this.state.renderAdditionalResources === "Legal Notices" && this.renderLegalNotices()}
        {this.state.messageType !=="" &&  this.renderMessage()} 
      </Box>
    );
  }

  render(){
    return (
      <React.Fragment>
        <Loader loading={this.state.isLoading} />
        <StyleContainer>
        
          {this.renderSection()}
        </StyleContainer>
      </React.Fragment>
    )
  }
}

const StyleContainer = styled(Box)({
  "& .pasErrorContainer":{
    height: "36px",
    padding: "12px 16px 12px 16px",
    marginBottom: "8px",
    borderRadius: "10px",
    border: "0px 0px 0px 1.3px",
    background: "linear-gradient(0deg, rgba(33, 33, 33, 0.29), rgba(33, 33, 33, 0.29)), linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)",
    alignContent: "center",
    color: "rgba(239, 68, 68, 1)"
  },

  "& .fieldContainer": {
    position:'relative',
    display: "flex",
    flexDirection: "column",
    paddingBottom: "20px"
  },
  "& .fieldContainer .MuiOutlinedInput-root":{
      borderRadius: "10px"
  },
  "& .fieldContainer label": {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
      marginBottom: "8px"
  },
  "& .messageField": {
    height: '100px',
    borderRadius:'8px',
    border: '1px solid #6B6B6B',
    padding:'9px 16px',
    fontSize:'14px',
    fontFamily: "Manrope",
  },
  "& .messageField::placeholder":{
    fontFamily: "Manrope",
    fontSize:'14px',
    color:'#6B6B6B',
    fontWeight:500
  },
  "& .messageField:focus":{
    outline:'none',
    border:'2px solid #3858E3'
  },
  "&. messageLimitTxt":{
    position:'absolute',
    color:'#6B6B6B',
    fontSize:'10px',
    bottom:'0'
  },
  "& .wrapper":{
    fontFamily: "Manrope",
    display:'flex',
		flexDirection:'column' as 'column',
		gap: '30px',
		padding:'10px 0',
  },
  "& .flexWrapper":{
    display:"flex",
    flexDirection:'column' as 'column',
		gap: '15px',
  },
  
  "& .flexWrapperDark":{
    display:"flex",
    flexDirection:'column' as 'column',
		gap: '15px',

    "& p":{
      fontSize:'14px',
      fontFamily:"Manrope",
      fontWeight:500,
      color:"#6B6B6B",
      lineHeight:'21px'
    },

    "& p strong":{
      fontSize:'18px',
      fontWeight:800,
      color:"#222222",
      fontFamily:"Manrope",
      lineHeight:'21.6px'
    }
  },

  "& .flexWrapper2":{
    display:"flex",
    flexDirection:'column' as 'column',
		gap: '15px',

    "& p":{
      fontSize:'14px',
      fontFamily:"Manrope",
      fontWeight:500,
      color:"#BABABA",
      lineHeight:'21px'
    },

    "& p strong":{
      fontSize:'18px',
      fontWeight:800,
      color:"#fff",
      fontFamily:"Manrope",
      lineHeight:'21.6px'
    }
  },

  
  "& .headerNav":{
    fontSize:'24px',
		color:'#fff',
    cursor:'pointer'
  },
  "& .cursor":{
    cursor:'pointer'
  },
  "& .search-container": {
    border: '1px solid',
    borderRadius: '10px',
    height: "48px",
    padding: '0px 15px',
    alignContent: 'center',
    "&:focus":{
      color:'#007aff'
    }
  },
  "& .search-field::before": {
    display: "none"
  },
  "& .search-field::after": {
    display: "none"
  },
  "& .fillBtn":{
    borderRadius: "100px",
    padding: "8px 20px",
		background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
		color:"#fff",
		fontWeight:700,
		textTransform:'capitalize' as 'capitalize',
		display:'flex',
		justifyContent:'center',
		alignItems:'center',
		fontFamily: "Manrope",
		fontSize: "12px",
		cursor:'pointer',
  },
  "& .fillBtn .label":{
    fontWeight:700,
    fontSize:'14px',
    lineHeight:"16.9px"
  },
  "& .outlineBtnLight": {
		fontFamily: "Manrope",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		boxShadow:"none !important",
		background: "linear-gradient(330.07deg, #fcfaff, #fcfaff) padding-box,linear-gradient(330.07deg, #3858E3 -51.03% , #BA05F7 138.78%) border-box",
		backgroundClip: "padding-box, border-box",
		backgroundOrigin: "padding-box, border-box",
		border :"1px solid transparent",
		borderRadius: "100px",
		textTransform: "capitalize" as "capitalize",
    padding: "7px 20px",
    display:'flex',
		justifyContent:'center',
		alignItems:'center',
  },
  "& .outlineBtnDark":{
    background: "linear-gradient(330.07deg, #110b1b, #110b1b) padding-box,linear-gradient(330.07deg, #3858E3 -51.03% , #BA05F7 138.78%) border-box",
		backgroundClip: "padding-box, border-box",
		backgroundOrigin: "padding-box, border-box",
		border :"1px solid transparent",
		borderRadius: "100px",
		textTransform: "capitalize" as "capitalize",
		color: "#FFF",
		fontFamily: "Manrope",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		fontWeight: 700,
		boxShadow:"none !important",
    padding: "7px 20px",
    display:'flex',
		justifyContent:'center',
		alignItems:'center',
  },
  "& .coloredTxt": {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		fontWeight: 700,
		WebkitTextFillColor: 'transparent', 
		WebkitBackgroundClip: 'text',
		backgroundClip: 'text',  
		fontFamily: "Manrope",
		textTransform:"none" as "none",
    lineHeight:"16.9px"
  },
  "& .tabs .Mui-selected":{
    color: "rebeccapurple",
		fontSize: "16px",
		fontStyle: "normal" as 'normal',
		fontWeight: 600,
    lineHeight: '19.2px'
  },
  "& .tabs .MuiTabs-indicator":{
    background: "rebeccapurple !important",
    height:'4px',
    borderRadius:'100px',
  },
  "& .tabStyle":{
    fontFamily:"Manrope",
    fontWeight: 600,
    fontSize:"16px",
    textTransform:"capitalize" as "capitalize",
    borderBottom:"3px solid gray",
  },
  "& .submitButton": {
    width: "160px",
    height: "48px",
    padding: "15px 30px 15px 30px",
    gap: "5px",
    borderRadius: "10px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    marginLeft:'auto'
  },
  "& .submitButton .label":{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16.8px",
    textAlign: "center",
    textTransform: "unset",
    color: "white"
  },
  "& .horizontalLine":{
		width:'100%',
		height:'1px',
		backgroundColor:'#222222',
	},
  "& .grayBoxLight":{
    borderRadius:'10px',
		padding:'15px',
		display:'flex',
		gap:'15px',
		flexDirection:'column' as 'column',
    border: '1px solid transparent',
    background: "linear-gradient(to right, #E9E9E9, #E9E9E9) padding-box,linear-gradient(to right, #50505050, #5B5B5B) border-box",
  },
  "& .grayBoxDark":{
    borderRadius:'10px',
		padding:'15px',
		display:'flex',
		gap:'15px',
		flexDirection:'column' as 'column',
    border: '1px solid transparent',
    background: "linear-gradient(to right, #181818, #181818) padding-box,linear-gradient(to right, #50505050, #5B5B5B) border-box",
  },
  "& .messageScreen": {
		position: "fixed",
		width: "100vw",
		height: "100vh",
		top: "0px",
		left: "0px",
		zIndex: 100,
		background: "#000000c7",
		display: "flex",
		justifyContent: "center"
	},
	"& .messageScreen .messageContainer": {         
			background: "#222222",        
			padding: "10px 20px 10px 20px",
			gap: "8px",
			borderRadius: "6px",
			marginTop: "auto",
			marginBottom: "5%",
			display: "flex"
	},
	"& .messageContainer span": {
			margin: "auto",
			fontFamily: "Manrope",
			fontSize: "12px",
			fontWeight: "500",
			lineHeight: "14.4px"
	},
  "& .unorderedList":{
    paddingTop:'0px',
    paddingLeft:'20px',
    marginTop:'0px',
  },
  "& .unoLi":{
    paddingLeft:'5px'
  },
  "& .h1":{
    fontWeight: 'bold',
		fontSize: '32px',
		lineHeight: 1.25,
		letterSpacing: '0.27px',
    fontFamily: "Manrope",
  },
  "& .h2":{
    fontWeight: 'normal',
		fontSize: '24px',
		lineHeight: 1.33,
		letterSpacing: '0.2px',
    fontFamily: "Manrope",
  },
  "& .h3":{
    fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: 1.2,
		letterSpacing: '0.2px',
    fontFamily: "Manrope",
  },
  "& .h4":{
    fontWeight: 'normal',
		fontSize: '18px',
		lineHeight: 1.78,
		letterSpacing: 'normal',
    fontFamily: "Manrope",
  },
  "& .h4Dark":{
    fontWeight: 'normal',
		fontSize: '18px',
		lineHeight: 1.78,
		letterSpacing: 'normal',
    color:'#BABABA',
    fontFamily: "Manrope",
  },
  "& .h4Light":{
    fontWeight: 'normal',
		fontSize: '18px',
		lineHeight: 1.78,
		letterSpacing: 'normal',
    color:'#222222',
    fontFamily: "Manrope",
  },
  "& .h5":{
    fontWeight: 600,
		fontSize: '16px',
		lineHeight: 1.38,
		letterSpacing: '0.2px',
    fontFamily: "Manrope",
  },
  "& .h6":{
    fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '14.4px',
		color:'#6B6B6B',
    fontFamily: "Manrope",
  }
})

const inputStyles={ 
  menuPaper: {
    marginTop: '8px', 
    borderRadius: '10px !important',
    background: "none !important",    
  },
  menuList: {
    paddingTop: '0px', 
    paddingBottom: '0px', 
    maxHeight:"364px"
  },
  contryStyle:{
    display:"flex",
    gap:"8px"
  },
  contryFont:{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 500,
  }, 
  darkBackdrop:{
     backgroundColor:'#0E0E0E',
     opacity:0.8
  },
   fade:{
      border:'1px solid #5B5B5B',
      borderRadius:'10px',
      display: 'flex'
  },
  lightBackdrop:{
    backgroundColor:'#FFFFFF',
    opacity:0.9
  },
  logoutButtonContainer: {
      display: "flex",
      gap: "12px",
  },
  logoutCancelButton: {
      width: "160px",
      height: "48px",
      padding: "18px 16px 18px 16px",
      gap: "10px",
      borderRadius: "10px",
      border: "1px solid"
  },
  logoutYesButton:{
      width: "160px",
      height: "48px",
      padding: "18px 16px 18px 16px",
      gap: "10px",
      borderRadius: "10px",
      background: "rgba(255, 77, 103, 1)"
  },
  iconButton:{
      color: "#6B6B6B",
      margin:0,
      padding:0,
      position:'absolute' as 'absolute',
      right:'4px'
  },
  nativeInputLight:{
    opacity: '1 !important', 
    position: "absolute" as "absolute",    
    "@media (max-width: 1280px)": {
      bottom:"15 !important",
    },
    color: 'black', 
    bottom:"14 !important",
    backgroundColor: 'transparent !important', 
    border:"none !important" as "none",
    width:"44px",
    textAlign:"center" as "center" , 
  },
  nativeInput: {
    opacity: '1 !important',     
    textAlign:"center" as "center",     
    "@media (max-width: 1280px)": {
      bottom:"15px !important",
    },
    position: "absolute" as "absolute", 
    color: 'white', 
    bottom:"14 !important",
    backgroundColor: 'transparent !important', 
    border:"none !important" as "none",
    width:"44px",
  },
  errorSuggestionContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  suggestionText: {
    color: "#8833FF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    textDecoration: "underline",
    textDecorationColor:"rgb(136, 51, 255, 0.3)",
    cursor: 'pointer',
    marginTop: '6px',
    marginLeft: 'auto',
  },
  textFieldError: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#f44336"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      border: '1px solid #6B6B6B',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 16px",
    },

  },
  errorBoxEmail: {
    display: 'flex',
    padding: '0.7rem 0.7rem',
    alignItems: 'center',
    borderRadius: "0.625rem",
    borderLeft: '1.3px solid var(--Status-Red-600, #DC2626)',
    background: 'linear-gradient(0deg, rgba(33, 33, 33, 0.29), rgba(33, 33, 33, 0.29)),linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)',
    backdropFilter: 'blur(24.5px)',
    color: '#DC2626',
    fontFamily: 'Manrope',
    fontSize: "12px",
    fontStyle: 'normal',
    fontWeight: 500,
    // marginBottom: "18px",
    // marginTop: '22px',
  },
  errorBoxLightEmail: {
    display: 'flex',
    padding: '0.7rem 0.7rem',
    alignItems: 'center',
    borderRadius: "0.625rem",
    borderLeft: '1.3px solid var(--Status-Red-600, #DC2626)',
    background: 'linear-gradient(0deg, rgba(33, 33, 33, 0.07), rgba(33, 33, 33, 0.07)),linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)',
    backdropFilter: 'blur(24.5px)',
    color: '#DC2626',
    fontFamily: 'Manrope',
    fontSize: "12px",
    fontStyle: 'normal',
    fontWeight: 500,
    // marginBottom: "18px",
    // marginTop: '22px',
  },
  errorText: {
    color: '#DC2626',
    fontFamily: "Manrope",
    fontSize: "11px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
  },
}

export const AdditiondalResources=withStyles(inputStyles)(AdditionalResources)