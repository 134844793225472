// Customizable Area Start
import { Box, styled, Typography } from '@material-ui/core'
import React from 'react'
import { deleteIcon, editIcon } from './assets'
import { Subscription } from './Subscriptionbilling2Controller'
interface Props {
    data: Subscription
    onEdit:(data:Subscription) => void;
    checked:boolean;
    onDelete:(data:Subscription) => void;

}
const SubscriptoinCard = ({ data, onDelete,onEdit,checked }: Props) => {
    
    const checkCondition=(condition:boolean,trueStatement:string,falseStatement:string)=>{
       return condition ? trueStatement : falseStatement
    }

    const fontColorStyle = checkCondition(checked,"lightColor","darkColor")

    return (<StyleContainer>
        <div className={`card ${checkCondition(checked,"lightBackground","darkBackground")}`}>
            <div className="planTitle">
                <Typography className={`fontStyle ${fontColorStyle} `}>{data.attributes.title}</Typography>
                <div style={WebStyle.iconStyle}>
                    <img src={editIcon} onClick={() =>onEdit(data)} data-test-id="editBtn"/>
                    <img src={deleteIcon} onClick={()=>onDelete(data)} data-test-id="deleteBtn"/>

                </div>
            </div>
            <Typography className={`fontStyle price ${fontColorStyle}`}>${data.attributes.price}/ {data.attributes.period}</Typography>
            <Typography className={`fontStyle price ${fontColorStyle}`} style={WebStyle.fontSizeStyle}>{data.attributes.details} </Typography>
        </div>
    </StyleContainer>)
}
const StyleContainer = styled(Box)({
    "& .card": {
        width: '330px',
        border: '1px solid #5B5B5B',
        borderRadius: '10px',
        gap: '10px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '2rem'
    },

    "& .lightBackground":{
      backgroundColor:'#fff'
    },
    "& .darkBackground":{
        backgroundColor: '#222222',
    },
    "& .planTitle": {
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .fontStyle": {
        fontSize: "18px",
        fontWeight: "800",
        fontFamily: "Manrope",
    },

    "& .lightColor":{
      color: '#222222'
    },

    "& .darkColor":{
      color: '#fff'
    },

    "& .price": {
        fontSize: "14px",
        fontWeight: "500",
    }
})

const WebStyle={
    iconStyle :{ 
      gap: '9px', 
      display: 'flex'
   },
   fontSizeStyle:{
       fontSize: '10px' 
   }
  }


export default SubscriptoinCard;
// Customizable Area End
