import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Modal,
  Backdrop,
  Fade,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles"
import { createTheme, ThemeProvider, withStyles} from "@material-ui/core/styles";
import {mainCategoryList, lightBackground, darkBackground, modeDark, modeWhite, backArrowWhite, backArrowDark, searchWhite, searchDark, logoutIcon, darkRightArrow, rightArrow} from "./assets";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Loader from "../../../components/src/Loader.web";
import AccountSettings from "./AccountSettings.web";
import AccessibilityAndDisplay from './AccessibilityAndDisplay.web';
import { SettingsNotification } from "./SettingNotifications.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const lightTheme = createTheme({
  
  // palette: {
  //   type: "light",
  //   primary: {
  //     main: "#3858E3",
  //   },
  //   secondary:{
  //     main:'rgba(0,0,0,0.75)'
  //   }
  //  }
   
  });

const darkTheme=createTheme({
    // palette: {
    //   type: "dark",
    //   primary: {
    //     main: "#3858E3",
    //   },
    //   secondary:{
    //   main:"rgba(255,255,255,0.75)"
    //   }
    //  }
    });
    const IOSSwitch = withStyles(({
      switchBase: {
        transform: "translate(5px,4.8px)",
        padding: 0,
        "&$checked": {
          transform: "translate(23px,4.4px)",
          color: theme.palette.common.white,
          "& + $track": {
            background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            border: "none",
            opacity: 1,
          },
        }
      },
      root: {
        padding: 0,
        width: 48,
        height: 28,
      },
      thumb: {
        width: 24,
        margin:"-2px -1px",
        height: 24,
      },
      track: {
        opacity: 1,
        borderRadius: 26 / 2,
        backgroundColor: "#1F1F1F",
        transition:theme.transitions.create(["background-color", "border"]),
      },
      focusVisible:{},
      checked: {},
    }))(Switch);
  
  const inputStyles = { 
    darkBackdrop:{
      opacity: 0.8,
      backgroundColor: '#0E0E0E',
    },
    fade: {
      borderRadius: '10px',
      border: '1px solid #5B5B5B',
      display:'flex'
    },
    lightBackdrop: {
      opacity: 0.9,
      backgroundColor: '#FFFFFF',
    },
    LogoutHeading:{
      width:'90%',
      height:42,
      display:'flex',
      justifyContent:'center',
      borderBottom:'1px solid #4E4E4E'
    },
    logoutButtonContainer:{
      gap:"12px",
      display:"flex",
    },
    logoutCancelButton:{
      gap:"10px",
      borderRadius:"10px",
      height:"48px",
      width:"160px",
      padding:"18px 16px 18px 16px",
      border:"1px solid #6B6B6B"
    },
    logoutYesButton:{
      borderRadius:"10px",
      height:"48px",
      width:"160px",
      gap:"10px",
      padding:"18px 16px 18px 16px",
      background:"rgba(255, 77, 103, 1)"
    },
  }
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import AdditionalResources from "./AdditionalResources.web";
import SecurityAndAccountAccess from "./SecurityAndAccountAccess.web";
import PrivacyAndSafety from "../../privacysettings/src/PrivacyAndSafety.web";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMainCat = () => {
    const {checked} = this.state
    return(
      <div className="main-category">
        <div className="main-heading">
        <Button onClick={() => this.setNavigation("ActivityFeed")} className="back-button-settings" variant="text"><span><img src={checked ? backArrowDark : backArrowWhite} /></span></Button>
        <div className="main-heading-text">{configJSON.settings}</div>
        </div>
        <div className="search-container">
        <Input
          id="input-with-icon-adornment"
          className="search-field"
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <img src={checked ? searchDark: searchWhite } />
            </InputAdornment>
          }
        />
        </div>
        <List className={"list"}>
          {mainCategoryList.map((each, index: number) =>
          (<ListItem alignItems="center"
            className={"listItem"}
            button
            data-test-id={`main-cat-${index}`}
            key={`setingsCat${index}`} 
            onClick={()=>this.setNavigation(each.path)}
            >
            <ListItemIcon className={"drawerItemIcon"}>
              <img src={checked ? each.darkIcon : each.whiteIcon} className={"iconImage2"} />
            </ListItemIcon>
            <ListItemText className="mainCatLabel" primary={each.name} />
            <ListItemIcon className={"justify-content-right"}>
              <img src={checked ? darkRightArrow : rightArrow} className={"iconImage"} />
            </ListItemIcon>
          </ListItem>))}
          <ListItem alignItems="center"
            className={"listItem"}
            button>
            <ListItemIcon className={"drawerItemIcon"}>
              <img src={checked ? modeDark : modeWhite} className={"iconImage2"} />
            </ListItemIcon>
            <ListItemText primary='Dark Mode' className={"mainCatLabel"}/>
            <ListItemIcon className={"justify-content-right"}>
              <CustomSwitch onChange={this.onDarkModeChanged} data-test-id="dark-mode-switch" checked={!this.state.checked}/>
            </ListItemIcon>
          </ListItem>
          <ListItem 
            alignItems="center"
            className={"listItem"}
            button
            data-test-id="logout-button"
            onClick={this.showLogoutModal}
          >
            <ListItemIcon className={"drawerItemIcon"}><img src={logoutIcon} className={"iconImage2"} /></ListItemIcon>
            <ListItemText className="mainCatLabel red" primary='Logout' />
          </ListItem>
        </List>
      </div>       
    )
  }

  renderSubCat = () => {
    return( 
      <React.Fragment>
      {this.state.pathName === "/SettingsProfile" && <AccountSettings renderBlock={this.setNavigation} checked={this.state.checked} />}
      {this.state.pathName === "/privacySafety" && <PrivacyAndSafety navigation={undefined} id={""} checked={this.state.checked} />}     
      {this.state.pathName === "/SettingsSecurity" && <SecurityAndAccountAccess navigation={this.setNavigation} id={""} checked={this.state.checked} />}     
      {this.state.pathName === "/SettingsAccessibility" && <AccessibilityAndDisplay navigation={undefined} id={""} checked={this.state.checked} />}     
      {this.state.pathName === "/SettingsResources" && <AdditionalResources navigation={this.setNavigation} id={""} checked={this.state.checked} />}     
      {this.state.pathName === "/SettingsNotification" && <SettingsNotification navigation={this.setNavigation} id={""} checked={this.state.checked} />} 
      </React.Fragment>     
    )
  }

  renderLogoutModal = () => {
    return(
        <Modal           
            className={`logoutModal  dark-${!this.state.checked}`}
            open={this.state.logoutModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              style: this.props.checked ? inputStyles.lightBackdrop : inputStyles.darkBackdrop
            }}
          >
            <Fade 
                in={this.state.logoutModal} 
                style={inputStyles.fade}
            >
               <div className={`logoutContainer dark-${!this.state.checked}`}>
                   <div className="LogoutHeading" style={inputStyles.LogoutHeading}>
                    <h6 className="arrowFontFamily font18 m0">Logout</h6>
                    </div>

                    <p className="arrowFontFamily font16 m0">Are you sure you want to log out?</p>
                    <div style={inputStyles.logoutButtonContainer}>
                        <Button className="cancelButton" onClick={this.onCloseLogout} style={inputStyles.logoutCancelButton}>Cancel</Button>
                        <Button data-test-id="logout-confirm-modal" className="confirmButton" onClick={this.onLogout} style={inputStyles.logoutYesButton} variant="text">                                  
                        Yes, Logout
                        </Button>
                    </div>
               </div>
            </Fade>
          </Modal>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationMenu navigation={this.props.navigation} id={""} checked={this.state.checked}>
        <StyleContainer>
            <Loader loading={this.state.isLoading} />
            <Grid container>
              <Grid item className="settingsMainCat">    
                {!this.state.isLoading && this.renderMainCat()}
              </Grid>
              <Grid item className="settingsSubCat subCat">
                {!this.state.isLoading && this.renderSubCat()}    
              </Grid>  
              {this.renderLogoutModal()}               
            </Grid>
        </StyleContainer>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomSwitch =styled(Switch)({
  width:48,
  height:28,
  padding:0,
  borderRadius:40,
 "& .MuiSwitch-switchBase":{
    padding:0,
    top:3,
    left:2
 },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track":{
    background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)!important',
    opacity:1,
   },
 "& .MuiSwitch-thumb":{
  height:24,
  width:24,
  color:'#FFFFFF'
 }
})
const StyleContainer = styled(Box)({
  height:'100vh',
  overflow:'auto',
  "& .main-category": {
    marginTop: "20px",
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily:"Manrope",

  },

  "& .paperDarkTheme":{
    display:"flex",
    backgroundColor:'#070707',
    backgroundImage:`url(${darkBackground})`,
    backgroundRepeat:"no-repeat",
    backgroundSize:'100% 100%',
    height:'100%',
    width:"100vw"
  },
  "& .paperLightTheme":{
    display:"flex",
    backgroundColor:'#fff',
    backgroundRepeat:"no-repeat",
    backgroundImage:`url(${lightBackground})`,
    backgroundSize:'100% 100%',
    height:'100%',
    width:"100vw",
  },
   
  "& .settingsSubCat":{
   minWidth:'300px'
  },

  "& .search-container": {
    marginTop:16,
    border: '1px solid',
    borderRadius: '10px',
    height: "48px",
    padding: '0px 15px',
    alignContent: 'center',
  },
  "& .search-field::before": {
    display: "none"
  },
  "& .search-field::after": {
    display: "none"
  },
  "& .main-heading":{
    display:'flex',
    alignItems:'center'
  },

  "& .main-heading-text":{
    fontFamily:"Manrope",
    fontSize: "18px",
    fontWeight: "800",
  },

  "& .back-button-settings": {
    height: "48px",
    minWidth: "48px !important",
    fontFamily:"Manrope",
    fontSize: "18px",
    fontWeight: "800",
  },
  
  "& .iconImage2":{
     height:28,
     width:28
  },

  "& .red":{
    color:'#EF4444'
  },

  "& .back-button-settings .MuiButton-label": {
    justifyContent: "space-between",
    textTransform: "capitalize"
  },
  "& .justify-content-right": {
    justifyContent: "end"
  },
  "& .mainCatLabel .MuiTypography-body1": {
    fontSize: "16px", 
    fontWeight: "600",
    marginLeft:16,
    lineHeight:'19.2px',
    fontFamily:"Manrope",
  },
  
  "& .list":{
    display:'flex',
    flexDirection:'column',
    rowGap:'5px'
  },

  "& .list .listItem:hover": {
    backgroundColor: "unset"
  },
  "& .list .listItem": {
    cursor: "pointer",
    padding:"5px 0px",
    width:'100%',
  }

});
const webStyle = {
  bgDarkWrapper:{
    display:"flex",
    backgroundColor:'#070707',
    backgroundImage:`url(${darkBackground})`,
    backgroundRepeat:"no-repeat",
    backgroundSize:'100% 100%',
    height:'100vh',
    width:"100vw",
  },
  bgLightWrapper:{
    display:"flex",
    backgroundColor:'#fff',
    backgroundRepeat:"no-repeat",
    backgroundImage:`url(${lightBackground})`,
    backgroundSize:'100% 100%',
    height:'100vh',
    width:"100vw",
  },
  subtab: {
    display: 'flex',
    fontFamily: "Roboto-Medium",
    alignItems: "center",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
