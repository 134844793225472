// Customizable Area Start
import React, { useRef, useState, RefObject, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import ReactPlayer from 'react-player'

import screenfull from 'screenfull'
import Controls from './VideoControls'
import { OnProgressProps } from 'react-player/base'
import { isArray } from 'lodash'
import Hls from 'hls.js';

interface CustomVideoPlayerProps {
	videoUrl: string
	videoDownloadUrl: string
	classesName: any
	isShowMini?: boolean
}

const useStyles = makeStyles((theme) => ({
	playerWrapper: {
		width: '100%',
		height: '100%',
		position: 'relative',
	},
}))

const format = (seconds: number) => {
	if (isNaN(seconds)) {
		return `00:00`
	}
	const date = new Date(seconds * 1000)
	const hh = date.getUTCHours()
	const mm = date.getUTCMinutes()
	const ss = date.getUTCSeconds().toString().padStart(2, '0')
	if (hh) {
		return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`
	}
	return `${mm}:${ss}`
}

let count = 0

const CustomVideoPlayer: React.FC<CustomVideoPlayerProps> = ({
	videoUrl,
	classesName,
	isShowMini,
	videoDownloadUrl,
}) => {
	const classes = useStyles()
	const [videoState, setVideoState] = useState({
		pip: false,
		playing: false,
		controls: false,
		light: false,

		muted: false,
		played: 0,
		duration: 0,
		playbackRate: 1.0,
		volume: 1,
		loop: false,
		seeking: false,
	})
	const [currentQuality, setCurrentQuality] = useState<number | null>(null);
	const [availableQualities, setAvailableQualities] = useState<number[]>([]);
	const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false);
	const [isFullScreen, setIsFullScreen] = useState(false);

	const hlsRef = useRef<Hls | null>(null)
	const playerRef: RefObject<any> = useRef(null)
	const playerContainerRef: RefObject<HTMLDivElement> = useRef(null)
	const controlsRef: RefObject<any> = useRef(null)
	const { playing, light, muted, loop, playbackRate, pip, played, volume } =
		videoState

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (!entry.isIntersecting) {
					setVideoState({ ...videoState, playing: false });
				}
			},
			{
				threshold: 0.1,
			}
		);

		if (playerContainerRef.current) {
			observer.observe(playerContainerRef.current);
		}

		return () => {
			if (playerContainerRef.current) {
				observer.unobserve(playerContainerRef.current);
			}
		};
	}, [playerContainerRef]);

	useEffect(() => {
		const onFullscreenChange = () => {
			setIsFullScreen(screenfull.isFullscreen);
		};

		screenfull.on('change', onFullscreenChange);

		return () => {
			screenfull.off('change', onFullscreenChange);
		};
	}, []);

	useEffect(() => {
		const isHls = videoUrl.endsWith('.m3u8');

		if (isHls && isPlayerReady && Hls.isSupported()) {
			const hlsInstance = new Hls();
			hlsRef.current = hlsInstance;

			hlsInstance.loadSource(videoUrl);
			hlsInstance.attachMedia(playerRef.current?.getInternalPlayer());

			hlsInstance.on(Hls.Events.MANIFEST_PARSED, () => {
				const levels = hlsInstance.levels.map(level => level.height);
				setAvailableQualities(levels);
				setCurrentQuality(levels[0]);
			});

			hlsInstance.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
				const newQuality = hlsInstance.levels[data.level].height;
				setCurrentQuality(newQuality);
			});

			return () => {
				hlsInstance.destroy();
			};
		} else if (!isHls) {
			setAvailableQualities([]);
			setCurrentQuality(null);
		}
	}, [videoUrl, isPlayerReady]);

	const switchQuality = (quality: number) => {
		if (hlsRef.current) {
			const levelIndex = hlsRef.current.levels.findIndex(level => level.height === quality);
			if (levelIndex !== -1) {
				hlsRef.current.currentLevel = levelIndex;
				setCurrentQuality(quality);
			}
		}
	};

	const handlePlayPause = () => {
		setVideoState({ ...videoState, playing: !videoState.playing })
	}

	const handleProgress = (changeState: OnProgressProps) => {
		if (count > 3) {
			controlsRef.current.style.visibility = 'hidden'
			count = 0
		}
		if (controlsRef.current.style.visibility == 'visible') {
			count += 1
		}
		if (!videoState.seeking) {
			setVideoState({ ...videoState, ...changeState })
		}
	}

	const handleSeekChange = (e: React.ChangeEvent<{}>, newValue: number | number[]) => {
		if (!isArray(newValue)) {
			setVideoState({ ...videoState, played: parseFloat((newValue / 100).toString()) })
		}
	}

	const handleSeekMouseDown = () => {
		setVideoState({ ...videoState, seeking: true })
	}

	const handleSeekMouseUp = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
		if (!isArray(newValue)) {
			setVideoState({ ...videoState, seeking: false })
			playerRef.current.seekTo(newValue / 100, 'fraction')
		}
	}

	const handleVolumeSeekDown = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
		if (!isArray(newValue)) {
			setVideoState({
				...videoState,
				seeking: false,
				volume: parseFloat((newValue / 100).toString())
			})
		}
	}

	const handleVolumeChange = (event: any, newValue: number | number[]) => {
		if (!isArray(newValue)) {
			setVideoState({
				...videoState,
				volume: parseFloat((newValue / 100).toString()),
				muted: newValue === 0 ? true : false,
			})
		}
	}

	const toggleFullScreen = () => {
		if (playerContainerRef.current) {
			screenfull.toggle(playerContainerRef.current)
		}
	}

	const handleMouseMove = () => {
		controlsRef.current.style.visibility = 'visible'
		count = 0
	}

	const handleMouseLeave = () => {
		controlsRef.current.style.visibility = 'hidden'
		count = 0
	}

	const handlePlaybackRate = (rate: number) => {
		setVideoState({ ...videoState, playbackRate: rate })
	}

	const handleMute = () => {
		setVideoState({ ...videoState, muted: !videoState.muted })
		playerRef && playerRef.current.getInternalPlayer()
	}

	const currentTime =
		playerRef && playerRef.current
			? playerRef.current.getCurrentTime()
			: '00:00'

	const duration =
		playerRef && playerRef.current ? playerRef.current.getDuration() : '00:00'
	const elapsedTime = format(currentTime)

	const totalDuration = format(duration)

	const handleReady = () => {
		setIsPlayerReady(true)
	};

	return (
		<div
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
			ref={playerContainerRef}
			className={classes.playerWrapper}
			style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			<ReactPlayer
				ref={playerRef}
				className={classesName}
				url={videoUrl}
				pip={pip}
				playing={playing}
				controls={false}
				light={light}
				loop={loop}
				playbackRate={playbackRate}
				volume={volume}
				muted={muted}
				onProgress={handleProgress}
				onReady={handleReady}
				config={{
					file: {
						attributes: {
							crossorigin: 'anonymous',
						},
					},
				}}
			/>

			<Controls
				ref={controlsRef}
				onSeek={handleSeekChange}
				onSeekMouseDown={handleSeekMouseDown}
				onSeekMouseUp={handleSeekMouseUp}
				onPlayPause={handlePlayPause}
				playing={playing}
				played={played}
				elapsedTime={elapsedTime}
				totalDuration={totalDuration}
				onMute={handleMute}
				muted={muted}
				onVolumeChange={handleVolumeChange}
				onVolumeSeekDown={handleVolumeSeekDown}
				playbackRate={playbackRate}
				onPlaybackRateChange={handlePlaybackRate}
				onToggleFullScreen={toggleFullScreen}
				volume={volume}
				resolutionList={availableQualities}
				onResolutionChange={switchQuality}
				selectedResolution={currentQuality}
				isShowMini={!!isShowMini}
				isFullScreen={isFullScreen}
				videoUrl={videoDownloadUrl}
			/>
		</div>
	)
}

export default CustomVideoPlayer

// Customizable Area End
