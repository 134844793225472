// Customizable Area Start
import React, { Fragment } from "react";

import GroupsController, {
  Props,
  TTabGroupScreen,
} from "./GroupsController";
import { Box, createStyles, Typography, withStyles, Input, IconButton, Button } from "@material-ui/core";
import { backArrowDark, backArrowWhite, cameraIcon } from "./assets";
import GroupsPermission from "./GroupsPermission.web";
import clsx from "clsx";
import EmojiPicker from "../../../components/src/EmojiPicker.web";
import DisappearingMessagesModal from "../../../components/src/Chats/DisappearingMessagesModal.web";
import GroupsListMember from "../../../components/src/GroupsListMember.web";
import GroupsSettings from "../../../components/src/GroupsSettings.web";
import GroupsMemberListSection from "../../../components/src/GroupsMemberListSelection.web";

export class Groups extends GroupsController {
  constructor(props: Props) {
    super(props);
  }

  renderEmotionPicker = () => {
    const { emojiAnchorEl } = this.state;

    return (
      <EmojiPicker
        onClickEmoji={this.handleClickEmoji}
        onSelectEmoji={this.handleSelectEmoji}
        onCloseEmoji={this.handleCloseEmoji}
        emojiAnchorEl={emojiAnchorEl}
      />
    )
  }

  renderDisappearingMessages = () => {
    const { isOpenModalDisappearing } = this.state;
    const { checked } = this.props
    return (
      <DisappearingMessagesModal
        data-testid="messagesModal"
        isOpen={isOpenModalDisappearing}
        value={this.state.disappearing_messages.toString()} checked={!checked}
        onSubmit={this.handleSubmitDisappearingMessage}
        onClose={() => this.handleToggleModalDisappearing(isOpenModalDisappearing)} />
    )
  }

  renderCreateGroup = () => {
    const { classes, checked } = this.props
    const { listMemberSelected } = this.state;

    return <Fragment>
      <Box className={classes.searchContainer}>
        <img
          data-test-id="selectIconGroup"
          className={classes.selectIconGroup}
          src={this.state.createGroupIcon || cameraIcon}
          width={50}
          height={50}
          onClick={this.handleImageClick}
        />
        <input
          type="file"
          data-test-id="selectedImageIcon"
          ref={this.fileInputUploadImg}
          style={{ display: 'none' }}
          onChange={this.handleFileChange}
          accept="image/*"
        />
        <Box className={classes.searchWrapper} style={{ flex: 1 }}>
          <Input
            id="input-groupName"
            className={classes.searchField}
            placeholder="Group Name"
            onChange={this.handleChangeGroupName}
            value={this.state.createGroupName}
            endAdornment={this.renderEmotionPicker()}
          />
        </Box>
      </Box>

      <GroupsSettings
        checked={checked}
        isOpenModalDisappearing={this.state.isOpenModalDisappearing}
        isEditGroup={true}
        isCanEdit={true}
        isCreateGroup={true}
        scope="admin"
        disappearingMessages={this.state.disappearing_messages}
        groupDescriptionValue={this.state.createGroupDescription}
        onToggleModalDisappearing={this.handleToggleModalDisappearing}
        onChangeGroupScreen={this.handleChangeGroupScreen}
        onChangeGroupDescription={this.handleChangeGroupsDescription}
      />

      <GroupsMemberListSection
        checked={this.props.checked}
        listMemberSelected={listMemberSelected}
        listContact={this.state.listContact}
        checkedContacts={this.state.checkedContacts}
        onCheckboxChange={this.handleCheckboxChange}
        onRemoveMember={this.handleRemoveMember}
      />

      {this.renderDisappearingMessages()}
    </Fragment>
  }

  renderGroupsPermission = () => {
    return <GroupsPermission
      isCanSendMessage={this.state.isCanSendMessage}
      checked={this.props.checked}
      defaultSetting={this.state.groupsPermissionSetting}
      onChangeSetting={this.handleChangeGroupsPermissionSetting}
      onChangeGroupScreen={this.handleChangeGroupScreen}
    />
  }

  renderGroupsListMember = () => {
    const { listMemberSelected, listMemberCanNotSendMessage } = this.state;
    const { checked } = this.props;
    return <GroupsListMember
      checked={checked}
      listUserCanNotSendMessage={listMemberCanNotSendMessage}
      typeList="permission"
      listMember={listMemberSelected}
      onToggleUserSendMessage={this.handleToggleUserSendMessage}
    />
  }

  renderCurrentGroupScreen = () => {
    const { currentGroupScreen } = this.state
    switch (currentGroupScreen) {
      case "create":
        return this.renderCreateGroup();
      case "group_permission":
        return this.renderGroupsPermission();
      case "send_messages":
        return this.renderGroupsListMember();
    }
  }

  renderHeaderGroup = () => {
    const { classes, onClose, checked } = this.props;
    const { currentGroupScreen } = this.state;
    const backTo =
      this.sortIndexTab.findIndex((tab) => tab === currentGroupScreen) - 1;
    const getTitleHeader = () => {
      if (currentGroupScreen === "group_permission") return "Group permission";
      if (currentGroupScreen === "send_messages") return "Send messages";
    };

    if (currentGroupScreen === "create") {
      return (
        <Box className={classes.header}>
          <Typography className={classes.headerButton} onClick={onClose}>
            Cancel
          </Typography>
          <Typography
            className={`${classes.headerTitle} ${clsx(
              checked && classes.headerTitleLight
            )}`}
          >
            New Group
          </Typography>
          <Button
            className={classes.headerButton}
            data-test-id="nextStep1"
            onClick={this.nextCreateGroup}
            disabled={this.state.isDisableDoneCreateGroup}
          >
            Done
          </Button>
        </Box>
      );
    }

    return (
      <Box className={`${classes.header} ${classes.headerInside}`}>
        <IconButton
          className={`${classes.backArrowButton}`}
          data-test-id="backArrowBtn"
          onClick={() =>
            this.handleChangeGroupScreen(
              this.sortIndexTab[backTo] as TTabGroupScreen
            )
          }
        >
          <img
            width={24}
            height={24}
            alt="i"
            src={this.props.checked ? backArrowDark : backArrowWhite}
          />
        </IconButton>
        <Box>
          <Typography className={`${classes.labelContainer__header} ${clsx(checked && classes.lightThemeTextColor)}`}>
            {getTitleHeader()}
          </Typography>
        </Box>
      </Box>
    );
  };


  render() {
    const { classes } = this.props
    return (
      <Box className={classes.container}>
        <Box className={classes.headerContainer}>
          {this.renderHeaderGroup()}
        </Box>
        {this.renderCurrentGroupScreen()}
      </Box>
    );
  }
}

const styles = createStyles({
  lightThemeTextColor: {
    color: "#222222!important"
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: "24px",
    "& > *:not(:nth-child(3))": {
      margin: "0 20px"
    },
  },
  header: {
    paddingTop: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerInside: {
    justifyContent: "unset"
  },
  headerButton: {
    color: '#8833FF',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Manrope',
    cursor: 'pointer',
    textTransform: "capitalize"
  },
  headerTitle: {
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 800,
    fontFamily: 'Manrope',
  },

  headerTitleLight: {
    color: '#222222',
  },
  backArrowButton: {
    padding: "2px 4px",
    width: "48px",
    height: "48px",
    marginLeft: "-20px"
  },
  labelContainer__header: {
    fontFamily: "Manrope",
    fontWeight: 800,
    fontSize: "24px",
    color: "#FFFFFF"
  },
  selectIconGroup: {
    borderRadius: "40px",
    cursor: "pointwr"
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  searchWrapper: {
    height: "48px",
    padding: '0px 15px',
    border: '1px solid #6B6B6B',
    borderRadius: '10px',
    alignContent: 'center',
  },
  searchField: {
    '&::after': {
      display: "none",
    },
    '&::before': {
      display: "none",
    },
    width: "100%"
  },
})

export default withStyles(styles)(Groups);


// Customizable Area End
