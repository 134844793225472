import { profile } from "../../blocks/chat/src/assets";
import clsx from 'clsx';
import React, { useEffect, useState } from "react";
import { Box, Button, Popover, styled, Typography } from "@material-ui/core";
import { CometChatUIKitConstants } from "@cometchat/chat-uikit-react";
import CometChatBubbleViewListReaction from "./CometChatBubbleViewListReaction";
import {
    addReactionToMessage,
    deleteMessage,
    fetchListReactionByMessage,
    getReplyMessageItem,
    IReaction,
    ReactionItem,
    ReactionObject,
    removeReactionToMessage,
    sendMediaMessage,
    sendTextMessage
} from "./CometChat";
import { convertTimestampToTime, emitterEvents } from "./utils";
import { EventSubscription } from "fbemitter";
import MessageOptionItem from "./MessageOptionItem.web";
import DeleteMessageModal from "./Chats/DeleteMessageModal.web";
import CometChatBubbleViewReplyMessage from "./CometChatBubbleViewReplyMessage.web";
import { ISetMessageReply } from "../../blocks/chat/src/ViewChatController";
import { ForwardModal } from "./Chats/MediaDocsPopover.web";
import { CometChat } from '@cometchat/chat-sdk-javascript'

import CometChatBubbleViewMessageImageRenderer from "./CometChatBubbleViewMessageImageRenderer.web";
const Highlight = require("react-highlighter")

const menuOptionMessage = require("./assets/menuOptionMessage.svg");

interface IPropCometChatBubbleViewTemplate {
    message: CometChat.BaseMessage,
    typeTemplate: string
    userInfo: CometChat.User | null;
    categories: string;
    checked?: boolean;
    conversationId: string;
    searchKeyword?: string;
    onRefreshMessageList?: () => void;
    onOpenModalTotalReaction?: (listReaction: IReaction[]) => void;
    onSetParentMessageId: (messageReplyItem: ISetMessageReply) => void;
    onScrollToBottom: () => void;
}

export interface IPropMessageRenderer {
    message: CometChat.BaseMessage;
    searchKeyword?: string;
    categories?: string;
}

function CometChatBubbleViewTemplate({
    message,
    typeTemplate,
    userInfo,
    categories,
    checked,
    conversationId,
    searchKeyword,
    onRefreshMessageList,
    onOpenModalTotalReaction,
    onSetParentMessageId,
    onScrollToBottom
}: IPropCometChatBubbleViewTemplate) {
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [isOpenModalConfirmDeleteMessage, setIsOpenModalConfirmDeleteMessage] = useState(false);
    const [currentListReaction, setCurrentListReaction] = useState<ReactionObject[]>([]);
    const [currentListReactionRaw, setCurrentListReactionRaw] = useState<IReaction[]>([]);
    const [isScrollToBottom, setIsScrollToBottom] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isOpenPopOver, setIsOpenPopOver] = useState(false);
    const [replyMessageItem, setReplyMessageItem] = useState<CometChat.BaseMessage | null>(null);
    const [isForwardModalOpen, setIsForwardModalOpen] = useState<boolean>(false)
    const [isViewAttachment, setIsViewAttachment] = useState(false);

    let subscription: EventSubscription | null = null;
    const currentUserLoginUid = userInfo?.getUid() || "";
    const { uid: senderMessageUid = "", avatar = "", name: senderName = "" } = message?.getSender() as unknown as { uid: string, avatar: string, name: string };
    const messageId = message?.getId();
    const timestampSentAt = convertTimestampToTime(message.getSentAt());
    const isMessageSentByCurrentUser = checkIsMessageSentByCurrentUser(senderMessageUid, currentUserLoginUid);

    useEffect(() => {
        // Get all Reaction of this message
        const getReactionsByMessage = async () => {
            const listReaction = await fetchListReactionByMessage(messageId);
            const listReactionRaw = listReaction as unknown as IReaction[];
            setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
            setCurrentListReactionRaw(listReactionRaw)
            setIsScrollToBottom(true);
        }
        getReactionsByMessage();

        const getReplyMessageData = async () => {
            const replyMessageItemValue = await getReplyMessageItem(message, conversationId);
            setReplyMessageItem(replyMessageItemValue)
        }
        getReplyMessageData()

        subscription = emitterEvents.addListener('messageIdReaction', async (messageIdReaction: string) => {
            if (messageIdReaction) {
                if (messageId.toString() !== messageIdReaction) return;
                const listReaction = await fetchListReactionByMessage(Number(messageIdReaction));
                const listReactionRaw = listReaction as unknown as IReaction[];
                setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
                setCurrentListReactionRaw(listReactionRaw)
                setIsScrollToBottom(false);
            }
        })

        return () => {
            if (subscription) {
                subscription.remove()
            }
        }
    }, [conversationId, userInfo])

    const renderHeaderReplyMessage = () => {
        if (!replyMessageItem) return null;

        const senderName = message.getSender().getName()?.trim() || '';
        const replySenderName = replyMessageItem?.getSender()?.getName()?.trim() || '';
        const firstName = getFirstName(replySenderName);

        const messageText = isMessageSentByCurrentUser
            ? `You replied to ${firstName}'s message`
            : `${senderName} replied`;

        return (
            <Typography className="headerReplyMessage">
                {messageText}
            </Typography>
        );
    };


    const handleRenderMessageByType = (message: CometChat.BaseMessage, typeTemplate: string) => {
        let messageRenderer = null;
        switch (typeTemplate) {
            case CometChatUIKitConstants.MessageTypes.text:
                messageRenderer = <MessageTextRenderer message={message} searchKeyword={searchKeyword} />
                break;
            case CometChatUIKitConstants.MessageTypes.delete:
                messageRenderer = <MessageDeletedTextRenderer message={message} />
                break;
            case CometChatUIKitConstants.MessageTypes.image:
                messageRenderer = (
                    <CometChatBubbleViewMessageImageRenderer
                        message={message}
                        isViewAttachment={isViewAttachment}
                        onViewImage={() => setIsViewAttachment(true)}
                        onCloseViewAttachment={() => setIsViewAttachment(false)}
                    />
                );
                break;
            case CometChatUIKitConstants.MessageTypes.file:
                messageRenderer = <MessageFileRenderer message={message} />
                break;
            default:
                break;
        }
        return messageRenderer;
    }

    const handleAddReactionToMessage = async (messageId: number, emoji: string) => {
        try {
            await addReactionToMessage(messageId, emoji);
            const listReaction = await fetchListReactionByMessage(messageId);
            const listReactionRaw = listReaction as unknown as IReaction[];
            setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
            setCurrentListReactionRaw(listReactionRaw)
            setIsScrollToBottom(false);
        }
        catch (error) {
            console.error("Send reaction fail", error)
        }
    }

    const handleRemoveReactionToMessage = async (messageId: number, emoji: string) => {
        try {
            await removeReactionToMessage(messageId, emoji);
            const listReaction = await fetchListReactionByMessage(messageId);
            const listReactionRaw = listReaction as unknown as IReaction[];
            setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
            setCurrentListReactionRaw(listReactionRaw);
            setIsScrollToBottom(false);
        }
        catch (error) {
            console.error("Send reaction fail", error)
        }
    }

    const handleForwardMessage = async (selectedContacts: { id: string, type: 'GROUP' | 'USER' }[]) => {
        try {
            // Create an array of promises for sending messages to multiple contacts
            const sendMessages = selectedContacts.map(async (contact) => {
                // If it's a media message, use sendMediaMessage, otherwise send regular message
                const isMediaMessage = message instanceof CometChat.MediaMessage;
                let conversationType = contact.type == 'USER' ? new CometChat.User(contact.id) : new CometChat.Group(contact.id);
                if (isMediaMessage) {
                    const mediaMessage = message as CometChat.MediaMessage;
                    const mediaFile = mediaMessage.getAttachments(); // Get the file (image, video, etc.)                    
                    async function createFileInstance(data: any) {
                        const response = await fetch(data.url);
                        const blob = await response.blob();
                        const file = new File([blob], data.name, { type: data.mimeType });
                        return file;
                    }
                    let files: any = [];
                    files = await Promise.all(
                        mediaFile.map(async (ele) => {
                            const result = await createFileInstance(ele);
                            return result; // Return the result (or undefined/null if no result)
                        })
                    );

                    await sendMediaMessage(files, conversationType, [], 0);

                } else {
                    const messageText = message.getData().text
                    await sendTextMessage(messageText, conversationType, [], 0);
                }

                onRefreshMessageList && onRefreshMessageList();
            });

            // Wait for all messages to be sent concurrently
            await Promise.all(sendMessages);
            console.log("All messages forwarded successfully!");
        } catch (error) {
            console.error("Error forwarding message:", error);
        }
    }

    const handleMouseEnter = () => {
        setIsMouseOver(true);
    };

    const handleMouseLeave = () => {
        setIsMouseOver(false);
    };

    const handleOpenPopOver = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
        setIsOpenPopOver(true);
    }

    const handleClosePopOver = () => {
        setAnchorEl(null);
        setIsOpenPopOver(false);
    }

    const handleOptionClick = async (labelOption: string) => {
        switch (labelOption) {
            case "Reply": {
                const getName = message.getSender().getName()?.trim();
                const textMessage = (message.getRawMessage() as any)?.text;
                const attachments = (message.getRawMessage() as any)?.data?.attachments ?? [];
                const sentAt = convertTimestampToTime(message.getSentAt());
                const firstName = getFirstName(getName);
                onSetParentMessageId && onSetParentMessageId(
                    {
                        parentMessageId: message.getParentMessageId() || messageId,
                        messageType: message.getType(),
                        textMessageReply: textMessage,
                        sentAtReply: sentAt,
                        nameReply: firstName?.toLowerCase() === userInfo?.getName()?.toLowerCase() ? "You" : firstName,
                        attachment: attachments[0]
                    }
                )
                break;
            }
            case "Copy": {
                const { text = "" } = message as unknown as { text: string }
                await navigator.clipboard.writeText(text)
                break;
            }
            case "Delete": {
                setIsOpenModalConfirmDeleteMessage(true)
                break;
            }
            case 'Forward': {
                setIsForwardModalOpen(true);
                break
            }
        }
        setIsOpenPopOver(false)
    }

    const handleConfirmDeleteMessage = async () => {
        await deleteMessage(messageId.toString())
        onRefreshMessageList && onRefreshMessageList();
    }

    return (
        categories === CometChatUIKitConstants.MessageCategory.action
            ? <MessageActionMemberRenderer message={message} categories={categories} />
            : <CometChatBubbleViewTemplateStyled checked={checked} ismessagesentbycurrentuser={isMessageSentByCurrentUser.toString()}>
                {
                    message.getParentMessageId() &&
                    renderHeaderReplyMessage()
                }
                <Box className={`groupMessageContainer ${clsx(message.getParentMessageId() && "borderGroupMessage")} ${!isMessageSentByCurrentUser && "groupMessageContainer-other"}`}>
                    <CometChatBubbleViewReplyMessage
                        message={message}
                        replyMessageItem={replyMessageItem}
                        isMessageSentByCurrentUser={isMessageSentByCurrentUser}
                        onScrollToBottom={onScrollToBottom}
                    />
                    <Box className={`messageItemContainer  ${clsx({
                        'user-message': isMessageSentByCurrentUser,
                        'other-message': !isMessageSentByCurrentUser,
                    })}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        {
                            <Box className={`menuOption ${clsx((isMouseOver || isOpenPopOver) && "showBlock")}`}>
                                <img src={menuOptionMessage} height={16} width={16} onClick={handleOpenPopOver} />
                            </Box>
                        }
                        <Box
                            className={`messageContainer ${typeTemplate === CometChatUIKitConstants.MessageTypes.image &&
                                "messageContainer-image"
                                }`}
                        >
                            {!isMessageSentByCurrentUser && (
                                <img
                                    style={{ borderRadius: "30px" }}
                                    src={avatar || profile}
                                    width={30}
                                    height={30}
                                />
                            )}
                            <Box className={`contentMessage`}>
                                {handleRenderMessageByType(message, typeTemplate)}
                                <Box className={`messageSentAt`}>
                                    <span className={`${checked && "lightThemeTextColor"}`} style={{ color: "#FFFFFF" }}>
                                        {timestampSentAt}
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <PopOverOptionMessageStyled
                    open={isOpenPopOver}
                    anchorEl={anchorEl}
                    onClose={handleClosePopOver}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: isMessageSentByCurrentUser ? "left" : "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: isMessageSentByCurrentUser ? "right" : "left",
                    }}
                >
                    <MessageOptionItem
                        isMessageSentByCurrentUser={isMessageSentByCurrentUser}
                        onOptionClick={handleOptionClick}
                        messageId={messageId}
                        onAddReactionToMessage={handleAddReactionToMessage}
                        checked={!!checked}
                    />
                </PopOverOptionMessageStyled>
                <CometChatBubbleViewListReaction
                    checked={checked}
                    message={message}
                    listReaction={currentListReaction}
                    listReactionRaw={currentListReactionRaw}
                    onRemoveReactionToMessage={handleRemoveReactionToMessage}
                    isMessageSentByCurrentUser={isMessageSentByCurrentUser}
                    onOpenModalTotalReaction={onOpenModalTotalReaction}
                    onScrollToBottom={onScrollToBottom}
                    isScrollToBottom={isScrollToBottom}
                />
                {isForwardModalOpen && <ForwardModal
                    open={isForwardModalOpen}
                    onClose={() => { setIsForwardModalOpen(false) }}
                    checked={!checked}
                    onForward={handleForwardMessage}

                />}
                <DeleteMessageModal
                    data-testid="deleteMessageModal"
                    isOpen={isOpenModalConfirmDeleteMessage}
                    checked={!checked}
                    onSubmit={() => { handleConfirmDeleteMessage() }}
                    onClose={() => { setIsOpenModalConfirmDeleteMessage(false) }}
                />
            </CometChatBubbleViewTemplateStyled>
    )
}

export function summarizeReactions(dataArray: ReactionItem[], myUid: string): ReactionObject[] {
    const summaryMap: Record<string, { count: number; reactedByMe: boolean }> = {};

    dataArray.forEach((item) => {
        const reaction = item.reaction;

        if (!summaryMap[reaction]) {
            summaryMap[reaction] = {
                count: 0,
                reactedByMe: false
            };
        }

        summaryMap[reaction].count += 1;
        if (item.uid === myUid) {
            summaryMap[reaction].reactedByMe = true;
        }
    });

    // Convert the summaryMap into an array of ReactionSummaryItem
    const summary: ReactionObject[] = Object.entries(summaryMap).map(([reaction, { count, reactedByMe }]) => ({
        reaction,
        count,
        reactedByMe
    }));

    return summary;
}

const getFirstName = (name?: string): string => {
    return name ? name.split(/\s+/)[0] : '';
};

const checkIsMessageSentByCurrentUser = (senderUidMessage: string, currentUserLoginUid: string) => {
    return currentUserLoginUid === senderUidMessage;
}

const MessageTextRenderer = ({
    message,
    searchKeyword = "",
}: IPropMessageRenderer) => {
    const getDataMessage = message.getData();
    const { text } = getDataMessage as unknown as { text: string };
    return (
        <Typography className="messageTextContent" component={"p"}>
            <Highlight
                search={searchKeyword}
                matchStyle={{
                    backgroundColor: "#AA71F6",
                    color: "#FFFFFF",
                }}
            >
                {text}
            </Highlight>
        </Typography>
    );
};

const MessageDeletedTextRenderer = ({ message }: IPropMessageRenderer) => {
    return <Typography className="deletedMessageContent" component={"p"}>
        {"This message has been deleted"}
    </Typography>
}

const MessageFileRenderer = ({ message }: IPropMessageRenderer) => {
    const getDataMessage = message.getData();
    const { attachments = [] } = getDataMessage;
    const getAttachment: { name: string, url: string } = attachments.find((attachment: { name: string, url: string }) => attachment.name);
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = getAttachment.url;
        link.download = getAttachment.name;
        link.dispatchEvent(new MouseEvent('click'));
    };
    return (
        <Box>
            <p className="messageTextContent">{getAttachment.name}</p>
            <Button className="buttonDownloadFile" onClick={handleDownload}>
                Download
            </Button>
        </Box>
    )
}

const MessageActionMemberRenderer = ({ message, categories = "" }: IPropMessageRenderer) => {
    const castMessage = message as unknown as { message: string };
    return (
        <MessageActionRendererStyled>
            <p className="messageTextAction">{castMessage.message}</p>
        </MessageActionRendererStyled>
    )
}

const MessageActionRendererStyled = styled(Box)({
    padding: "6px 10px",
    background: "#7575751F",
    borderRadius: "6px",
    "& .messageTextAction": {
        fontFamily: "Manrope",
        fontWeight: 500,
        fontSize: "10px",
        color: "#757575"
    }
});

const CometChatBubbleViewTemplateStyled = styled(Box)(({ checked, ismessagesentbycurrentuser }: { checked?: boolean, ismessagesentbycurrentuser: string }) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: 10,
    "& .headerReplyMessage": {
        fontFamily: "Manrope",
        fontWeight: 500,
        fontSize: 14,
        color: "#6B6B6B",
        textAlign: ismessagesentbycurrentuser === "true" ? "right" : "unset",
        padding: "0 10px"

    },
    "& .groupMessageContainer": {
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
        padding: "0 8px",
        borderRadius: 1,
        maxWidth: 350
    },
    "& .groupMessageContainer.groupMessageContainer-other": {
        maxWidth: 385
    },
    "& .groupMessageContainer.borderGroupMessage": {
        borderRight: ismessagesentbycurrentuser === "true" ? "2px solid #4E4E4E" : "unset",
        borderLeft: ismessagesentbycurrentuser === "false" ? "2px solid #4E4E4E" : "unset",
    },
    "& .messageItemContainer": {
        display: "flex",
        position: "relative",
        alignItems: "center",
        "& .menuOption": {
            visibility: "hidden"
        },
        "& .menuOption.showBlock": {
            visibility: "unset"
        }
    },
    "& .messageItemContainer.user-message": {
        "& .messageContainer": {
            marginLeft: "12px",
        },
        "& .contentMessage": {
            background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            borderBottomRightRadius: 8
        }
    },

    "& .messageItemContainer.other-message": {
        flexDirection: "row-reverse",
        "& .messageContainer": {
            marginRight: "12px",
        },
        "&  .contentMessage": {
            "& .messageTextContent": {
                color: checked ? "#222222" : "#FFFFFF",
            },
            "& .messageSentAt": {
                marginRight: "calc(12px + 16px)",
                "& span.lightThemeTextColor": {
                    color: "#222222!important"
                },
            },
            background: checked ? "#E9E9E9" : "#222222",
            borderTopLeftRadius: 8
        }
    },
    "& .messageContainer": {
        display: "flex",
        gap: 5,
        width: "350px",
        "& .contentMessage": {
            width: "100%",
            boxSizing: "border-box",
            padding: "12px 20px",
            paddingRight: "40px",
            borderRadius: 16,
            postion: "relative",
            "& .messageTextContent": {
                wordBreak: "break-all",
                whiteSpace: "normal",
                fontFamily: "Manrope",
                fontWeight: 500,
                fontSize: 14,
                color: "#FFFFFF"
            },
            "& .deletedMessageContent": {
                fontStyle: "italic",
            },
            "& .buttonDownloadFile": {
                padding: "6px 0"
            },
            "& .messageSentAt": {
                position: "absolute",
                height: 15,
                bottom: 14,
                right: "calc(36px - 28px)",
                width: "28px",
                textAlign: "right",
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontWeight: 500,
                fontSize: "10px"
            },
        },
    },
    "& .messageContainer.messageContainer-image": {
        width: "100%"
    }
}));


const PopOverOptionMessageStyled = styled(Popover)(() => ({
    borderRadius: 5
}));

export default CometChatBubbleViewTemplate;