export const profileLogo = require("../assets/face.png");
export const verifyIcon = require("../assets/verifyIcon.svg");
export const reshotIcon = require("../assets/reshotIcon.svg");
export const reverseIcon = require("../assets/reverseIcon.svg");
export const saveIcon = require("../assets/saveIcon.svg");
export const shareIcon = require("../assets/shareIcon.svg");
export const favIcon = require("../assets/favIcon.svg");
export const commentIcon = require("../assets/commentIcon.svg");
export const reshotLightIcon = require("../assets/reshortLight.svg");
export const threeDots = require("../assets/3dots.svg");
export const searchDark = require("../assets/searchDark.svg");
export const searchLight = require("../assets/searchLight.svg");
export const callLight = require("../assets/callLight.svg");
export const callDark = require("../assets/callDark.svg");
export const saveActive = require("../assets/saveActive.svg");
export const favActive = require("../assets/favActive.svg");
export const verifyOrange = require("../assets/verifyOrange.svg");
export const verifyYellow = require("../assets/verifyYellow.svg");
export const ownerShipIcon = require("../assets/ownerShipIcon.svg");
export const infoIcon = require("../assets/infoIcon.png");
export const repostActive = require("../assets/repost-active.png");
export const reportedIcon = require("../assets/reportedIcon.svg");
export const pdfPlaceholder = require("../assets/pdf_placeholder.svg");








