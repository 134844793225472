import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Grid,
  Typography,
  styled,
  Button,
  ThemeProvider,
  createTheme,
  Popover,
  Tabs,
  Tab,
  Checkbox,
  Input,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import { lightCheckbox, darkCheckBox, checkbox } from "../../../blocks/navigationmenu/src/assets";
import { pdfIcon, searchWhite, profile, searchDark, groupIcon } from "../../../blocks/chat/src/assets";
import { DocData, fetchConversations, fetchMediaMessages, IConversation, MediaData } from '../CometChat';
import { Conversation } from '../../../blocks/chat/src/MessageItemController.web';


interface MediaDocsProps {
  checked: boolean;
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
  conversation: CometChat.User | CometChat.Group;
}

type ForwardModalProps = {
  open: boolean;
  onClose: () => void;
  onForward: (selectedUsers: {
    id: string;
    type: 'USER' | 'GROUP';
  }[]) => void;
  checked: boolean;
};


// const mediaData: MediaData = {
//   recent: [
//     'https://picsum.photos/200/300?random=1',
//     'https://picsum.photos/200/300?random=2',
//     'https://picsum.photos/200/300?random=3',
//     'https://picsum.photos/200/300?random=4',
//   ],
//   may: [
//     'https://picsum.photos/200/300?random=5',
//     'https://picsum.photos/200/300?random=6',
//     'https://picsum.photos/200/300?random=7',
//     'https://picsum.photos/200/300?random=8',
//   ],
// };

// const docsData: { [key: string]: { name: string; pages: number; size: string; format: string; date: string }[] } = {
//   may: [
//     { name: 'COI_4563876787.pdf', pages: 1, size: '64 kB', format: 'PDF', date: '5:06 pm' },
//     { name: 'Pdf_4563876787.pdf', pages: 2, size: '64 kB', format: 'PDF', date: '15/05/2024' },
//   ],
// };
const conditionRemover = (
  condition: boolean | undefined,
  trueStatement: string,
  falseStatement: string
) => {
  return condition ? trueStatement : falseStatement;
};

const MediaDocsPopover: React.FC<MediaDocsProps> = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedMedia, setSelectedMedia] = useState<string[]>([]);
  const [mediaData, setMediaData] = useState<MediaData>({});
  const [docsData, setDocsData] = useState<DocData>({})
  const [isForwardModalOpen, setIsForwardModalOpen] = useState(false);

  const open = Boolean(props.anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const {
    guid = "",
    uid = "",
  } = props.conversation as unknown as IConversation;
  const isGroup = guid ? true : false;


  useEffect(() => {
    const fetchMessages = async () => {
      const { media, docs } = await fetchMediaMessages(guid || uid, isGroup, 50);
      setMediaData(media)
      setDocsData(docs)
    };
    if (guid || uid) {
      fetchMessages()
    }

  }, [props.conversation, open])


  const handleTabs = (event: object, value: number) => {
    setSelectedTab(value);
  };
  const handleSelect = (messageId: string) => {
    setSelectedMedia((prevSelected) =>
      prevSelected.includes(messageId)
        ? prevSelected.filter((selectedItem) => selectedItem !== messageId)
        : [...prevSelected, messageId]
    );
  }
  const handleCancel = () => {
    setSelectedMedia([]);
    props.onClose()
  };

  const handleDelete = () => {
    console.log("Deleting items:", selectedMedia);
    setSelectedMedia([]); // Clear the selection
  };

  const handleForward = () => {
    setIsForwardModalOpen(true); // Open the forward modal
  };

  const closeForwardModal = () => {
    setIsForwardModalOpen(false); // Close the forward modal
  };

  const renderMediaSection = (title: string, mediaItems: { imageUrl: string, messageId: string }[]) => {
    let currentDate = new Date();
    let currentMonthName = currentDate.toLocaleString("default", { month: "long" });
    const monthTitle = currentMonthName.toLowerCase() === title.toLowerCase() ? "Recent" : title;
    return (
      <Box mb={3} style={{ display: "flex", flexDirection: "column", gap: 15 }}>
        <span className="mediaTitle">{monthTitle}</span>
        {mediaItems?.length > 0 ? (
          <Grid container spacing={1}>
            {mediaItems.map((item, index) => (
              <Grid item xs={3} key={index} style={{ position: "relative" }}>
                <img src={item.imageUrl} alt={`media-${index}`} height={80} width={80} />
                <Checkbox
                  icon={<img src={!props.checked ? lightCheckbox : darkCheckBox} alt="Avatar" />}
                  checkedIcon={<img src={checkbox} alt="Avatar" />}
                  checked={selectedMedia.includes(item.messageId)}
                  onChange={() => handleSelect(item.messageId)}
                  style={{ position: "absolute", top: 0, right: 0 }}
                  color="primary"
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body2" color="textSecondary" style={{ padding: "1rem" }}>No media found</Typography>
        )}
      </Box>
    )
  };

  const renderDocsSection = (title: string, docItems: { name: string, size: string, pages: string, format: string, date: string, messageId: string }[]) => (
    <Box mb={3} style={{ display: "flex", flexDirection: "column", gap: 15 }}>
      <span className="mediaTitle">{title}</span>
      {docItems?.length > 0 ? (
        docItems.map((doc, index) => (
          <Box key={index} style={{ display: "flex", alignItems: "center", gap: 10, position: "relative" }}>
            <img src={pdfIcon} alt="PDF Icon" height={40} />
            <Box flexGrow={1}>
              <Typography className="docTitle">{doc.name}</Typography>
              <Typography className="docSub">{`${doc.pages} page • ${doc.size} • ${doc.format}`}</Typography>
            </Box>
            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "end" }}>
              <Checkbox
                icon={<img src={!props.checked ? lightCheckbox : darkCheckBox} alt="Avatar" />}
                checkedIcon={<img src={checkbox} alt="Avatar" />}
                checked={selectedMedia.includes(doc.messageId)}  // Use messageId for checking
                onChange={() => handleSelect(doc.messageId)}  // Handle selection by messageId
                color="primary"
              />
              <Typography variant="caption" className="docSub">{doc.date}</Typography>
            </Box>
          </Box>
        ))
      ) : (
        <Typography variant="body2" color="textSecondary">No docs found</Typography>
      )}
    </Box>
  );

  return (
    <ThemeProvider theme={!props.checked ? commonLightTheme : commonDarkTheme}>
      <Popover
        id={id}
        open={open}
        anchorEl={props.anchorEl}
        onClose={handleCancel}
        style={{ background: 'rgba(0, 0, 0, 0.4)', zIndex: 999 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            borderRadius: 10,
            border: "1px solid #35383F",
            marginTop: "20px"
          }
        }}
      >
        <BoxContainer style={{ backgroundColor: conditionRemover(props.checked, "#0B0B0B", "#FFFFFF") }}>
          <TabsContainer>
            <Tabs
              value={selectedTab}
              onChange={handleTabs}
              classes={{ indicator: "indicator" }}
              textColor="primary"
              variant="fullWidth"
              data-test-id="tabBtn1"
            >
              <Tab label="Media" className={"tabstyle"} />
              <Tab label="Docs" className={"tabstyle"} />
            </Tabs>
          </TabsContainer>
          <MediaContainer>
            {selectedTab === 0 ? (
              Object.keys(mediaData).length > 0 ? (
                Object.keys(mediaData).reverse().map((month) => renderMediaSection(month, mediaData[month] || []))
              ) : (
                <Typography variant="body2" color="textSecondary">No media found</Typography>
              )
            ) : Object.keys(docsData).length > 0 ? (
              Object.keys(docsData).reverse().map((month) => renderDocsSection(month, docsData[month] || []))
            ) : (
              <Typography variant="body2" color="textSecondary">No docs found</Typography>
            )}
          </MediaContainer>
          <Footer>
            {selectedMedia.length > 0 && (
              <Box>
                {selectedMedia.length} Selected
              </Box>
            )}
            <Button className='cancelBtn' onClick={handleCancel}>Cancel</Button>
          </Footer>
        </BoxContainer>
      </Popover>
      {isForwardModalOpen && (
        <ForwardModal
          open={isForwardModalOpen}
          onClose={() => {
            closeForwardModal();
            handleCancel()
          }}
          onForward={(selectedUsers) => {
            if (selectedUsers.length > 0) {
              console.log("Forwarding to:", selectedUsers, "Items:", selectedMedia);
              closeForwardModal();
            }
          }}
          checked={props.checked}
        />
      )}
    </ThemeProvider>
  );
};



export const ForwardModal: React.FC<ForwardModalProps> = ({
  open,
  onClose,
  onForward,
  checked,
}) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [checkedContacts, setCheckedContacts] = useState<{ id: string; type: 'USER' | 'GROUP' }[]>([]);
  const [contactList, setContactList] = useState<Conversation[]>([])

  useEffect(() => {
    const getConversations = async () => {
      let conversations = await fetchConversations() || [];

      if (!conversations || !conversations?.length) {
        setContactList(conversations)
        return;
      }

      // Filter conversation by type


      setContactList(conversations)
    }
    getConversations();
  }, [open])

  // Handle search input
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Handle checkbox toggle
  const handleCheckboxChange = (uid: string, type: 'USER' | 'GROUP') => {
    setCheckedContacts((prev) => {
      const exists = prev.find((contact) => contact.id === uid);
      if (exists) {
        // Remove the contact if it's already selected
        return prev.filter((contact) => contact.id !== uid);
      } else {
        // Add the contact if it's not already selected
        return [...prev, { id: uid, type }];
      }
    });
  };



  const filteredContacts = contactList.filter((contact) =>
    contact.name.toLowerCase().includes(searchQuery)
  );

  // Handle forward action
  const handleForward = () => {
    if (checkedContacts.length > 0) {
      onForward(checkedContacts); // Pass the array of selected contacts
      onClose();
    }
  };

  return open ? (
    <DialogBox
      data-test-id="disappearModel"
      onClose={() => onClose()}
      open={open}
      PaperProps={{
        className: conditionRemover(
          checked,
          'modelDarkStyle',
          'modelLightStyle'
        ),
      }}
    >
      <Box className={classes.modalWrapper}>
        {/* Search Field */}
        <Box className={classes.searchWrapper}>
          <Input
            id="input-with-icon-adornment"
            className={classes.searchField}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <img src={checked ? searchWhite : searchDark} alt="search" />
              </InputAdornment>
            }
            onChange={handleSearch}
          />
        </Box>
        {/* Contacts */}
        {renderContactList({
          title: "Contacts on Arrow",
          contacts: filteredContacts,
          checkedContacts,
          onCheckboxChange: handleCheckboxChange,
        })}

        {/* Footer Actions */}
        <Box className={classes.modalFooter}>
          <Box>
            {checkedContacts.length > 0 && <Typography component="p">{checkedContacts.length} Selected</Typography>}
          </Box>
          <Box>
            <Button onClick={onClose} className={classes.cancelButton}>
              Cancel
            </Button>
            <Button onClick={handleForward} className={classes.forwardButton}>
              Forward
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogBox>
  ) : null;
};

// Function to render contact list
type RenderContactListProps = {
  title: string;
  contacts: Conversation[];
  checkedContacts: { id: string; type: 'USER' | 'GROUP' }[];
  onCheckboxChange: (uid: string, type: 'USER' | 'GROUP') => void;
};

const renderContactList: React.FC<RenderContactListProps> = ({
  title,
  contacts,
  checkedContacts,
  onCheckboxChange,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.suggestPeopleContainer}>
      <Typography className={classes.suggestPeopleTitle}>{title}</Typography>
      <Box className="listContactUser">
        {contacts.map((contact) => {
          const uid = contact.id;
          const isChecked = !!checkedContacts.find((c) => c.id === uid);
          const defaultAvatar = contact.type === "USER" ? profile : groupIcon;
          return (
            <Box key={uid} className="contactUser">
              <Box className="contactUserInformation">
                <img
                  src={contact.avatar || defaultAvatar}
                  width={48}
                  height={48}
                  alt={contact.name}
                  style={{ borderRadius: '30px' }}
                />
                <Typography component="p">{contact.name}</Typography>
              </Box>
              <Checkbox
                style={{ width: '20px', height: '20px' }}
                checked={!isChecked}
                data-test-id="checkBoxSelectedMember"
                onChange={() => onCheckboxChange(uid, contact.type)}
                icon={<img src={checkbox} alt="uncheck" />}
                checkedIcon={<img src={lightCheckbox} alt="checked" />}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  modalWrapper: {
    // Your styles here
    width: 375,
    display: "flex",
    flexDirection: "column",
    padding: 20,
    gap: 25,
    borderRadius: 10,
    border: "1px solid #35383F"
  },
  searchWrapper: {
    height: "48px",
    padding: '0px 15px',
    border: '1px solid #6B6B6B',
    borderRadius: '10px',
    alignContent: 'center',
    '&:has(.Mui-focused)': {
      border: "1px solid #7A2BF5"
    },
  },
  searchField: {
    '&::after': {
      display: "none",
    },
    '&::before': {
      display: "none",
    },
    width: "100%"
  },
  modalFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cancelButton: {
    boxShadow: 'none !important',
    borderRadius: '5px',
    backgroundColor: "#0B0B0B",
    padding: '6px 10px',
    width: '87px',
    height: '24px',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: "Manrope",
    textTransform: "capitalize",
  },
  forwardButton: {
    boxShadow: 'none !important',
    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    borderRadius: '5px',
    padding: '6px 10px',
    width: '81px',
    height: '24px',
    color: 'white',
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: "Manrope",
    textTransform: "capitalize",
  },
  suggestPeopleContainer: {
    "& .listContactUser": {
      display: "flex",
      flexDirection: "column",
      marginTop: "20px",
      gap: 16,
      maxHeight: 310,
      overflow: "scroll",
      scrollbarWidth: "none",
      padding: '10',
      "& .contactUser": {
        display: "flex",
        justifyContent: "space-between",
        "& .contactUserInformation": {
          display: "flex",
          alignItems: "center",
          gap: 10,

        }
      }
    }
  },
  suggestPeopleTitle: {
    fontSize: '16px',
    fontFamily: 'Manrope',
    fontWeight: 600,
    color: '#6B6B6B',
  },
});

// Styled Components
const BoxContainer = styled(Box)({
  maxHeight: 554,
  width: 375,
  padding: 20,
  boxSizing: "border-box",
});

const TabsContainer = styled(Box)({
  display: "none",
  marginBottom: 12,
  "& .indicator": {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height: '2.9px',
  },
  "& .tabstyle": {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "capitalize" as "capitalize",
    borderBottom: "3px solid gray",
    '&.Mui-selected': {
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent' as "transparent",
    },
  },
});

const MediaContainer = styled(Box)({
  overflow: "scroll",
  scrollbarWidth: "none",
  "& .mediaTitle": {
    fontSize: '16px',
    fontFamily: 'Manrope',
    fontWeight: 600,
    lineHeight: '19.2px',
    textTransform: "capitalize",
  },
  "& .docTitle": {
    fontSize: '16px',
    fontFamily: 'Manrope',
    fontWeight: 600,
    lineHeight: '19.2px',
    textTransform: "capitalize",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "17ch",
    whiteSpace: "nowrap",
  },
  "& .docSub": {
    fontSize: '12px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: '14.4px',
    color: "#6B6B6B"
  }

});

const Footer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& .totalPhotos": {
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: '21px',
  },
  '& .cancelBtn': {
    boxShadow: 'none !important',
    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    borderRadius: '5px',
    padding: '6px 10px',
    width: '81px',
    height: '24px',
    color: 'white',
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: "Manrope",
    textTransform: "capitalize",
  },
  '& .forwardBtn': {
    boxShadow: 'none !important',
    borderRadius: '5px',
    backgroundColor: "#0B0B0B",
    padding: '6px 10px',
    width: '87px',
    height: '24px',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: "Manrope",
    textTransform: "capitalize",
  },
  '& .deleteBtn': {
    boxShadow: 'none !important',
    borderRadius: '5px',
    padding: '6px 10px',
    width: '81px',
    height: '24px',
    color: '#6B6B6B',
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: "Manrope",
    textTransform: "capitalize",
  },
});

const DialogBox = styled(Dialog)({
  '& .bottomModelDarkStyle': {
    borderRadius: '6px',
    backdropFilter: 'blur(1px)',
    boxShadow: 'none',
    position: 'absolute',
    top: '80%',
  },
  '& .modelDarkStyle': {
    borderRadius: '10px',
    backdropFilter: 'blur(1px)',
    background: 'linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)',
    boxShadow: 'none',
  },
  '& .modelLightStyle': {
    borderRadius: '10px',
    backdropFilter: 'blur(1px)',
    background: 'linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)',
    boxShadow: 'none',
  },
  '& .bottomModelStyle': {
    borderRadius: '6px',
    backdropFilter: 'blur(1px)',
    boxShadow: 'none',
    position: 'absolute',
    top: '80%',
  },
});

const commonLightTheme = createTheme({
  palette: {
    type: "light",
    primary: { main: "#3858E3" },
    secondary: { main: 'rgba(0,0,0,0.75)' },
    info: { main: "#222222" },
    warning: { main: "#78716C" },
  },
});

const commonDarkTheme = createTheme({
  palette: {
    type: "dark",
    primary: { main: "#3858E3" },
    secondary: { main: "rgba(255,255,255,0.75)" },
    info: { main: "#FFFFFF" },
    warning: { main: "#78716C" },
  },
});

export default MediaDocsPopover;
