// Customizable Area Start
import React from 'react'
import { Modal, Box, createStyles, withStyles, Typography, Button } from '@material-ui/core'
import { Link } from "react-router-dom"

interface TrialExpiredModalProps {
	open: boolean
	onClose: () => void
	classes?: any
}

const TrialExpiredModal: React.FC<TrialExpiredModalProps> = ({
	open,
	onClose,
	classes
}) => {

	return (
		<Modal
			open={open}
			className={classes.container}
		>
			<Box className={classes.modalWrapper}>
				<Box className={classes.titleWrapper}>
					<Typography className={classes.title}>Your free trial is over</Typography>
				</Box>
				<Box className={classes.bodyWrapper}>
					<Typography className={classes.description}>
						Please subscribe to Arrow to use the application.
					</Typography>
					<Box className={classes.btnWrapper}>
						<Button onClick={onClose} className={classes.notNowBtn}>Not now</Button>
						<Link to='/AutomaticRenewals'>
							<Button className={classes.seeSubscribeBtn}>See Subscribe</Button>
						</Link>
					</Box>
				</Box>
			</Box>
		</Modal>
	)
}

const styles = createStyles({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
	},
	modalWrapper: {
		width: '420px',
		backgroundColor: '#0B0B0B',
		borderRadius: '10px',
		padding: '12px 0 24px',
		display: 'flex',
		flexDirection: 'column',
		gap: '24px',
		outline: 'none',
		border: '1px solid #35383F',
	},
	titleWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '12px 0',
		borderBottom: '1px solid #35383F'
	},
	title: {
		fontFamily: 'Manrope',
		fontWeight: 800,
		fontSize: '18px',
		color: '#FFFFFF',
	},
	bodyWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '24px',
	},
	description: {
		fontFamily: 'Manrope',
		fontWeight: 600,
		fontSize: '16px',
		color: '#FFFFFF',
	},
	btnWrapper: {
		display: 'flex',
		gap: '12px',
	},
	notNowBtn: {
		width: '160px',
		height: '48px',
		borderRadius: '10px',
		border: '1px solid #6B6B6B',
		background: 'transparent',
		color: '#6B6B6B',
		fontFamily: 'Manrope',
		fontWeight: 700,
		fontSize: '14px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		"&:hover": {
			background: "transparent",
		},
	},
	seeSubscribeBtn: {
		width: '160px',
		height: '48px',
		borderRadius: '10px',
		background: 'linear-gradient(-45deg, #3858E3, #BA05F7)',
		color: '#fff',
		fontFamily: 'Manrope',
		fontWeight: 700,
		fontSize: '14px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		"&:hover": {
			background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
		},
	},
})

export default withStyles(styles)(TrialExpiredModal)

// Customizable Area End
