import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Backdrop,
  Fade,
  InputAdornment,
  IconButton,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme,  ThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { styled } from "@material-ui/styles"
import { arrowRightRed, backArrowWhite, backArrowDark, arrowWhite,arrowDark, accountSettingsList, arrowRight24White, accountInformationList, sucIcon, calenderDark, calenderWhite, radioDark, radioLight, darkRightArrow, rightArrow,arowLogo} from "./assets";
import { Formik, Form, Field } from "formik";
import FormikInputField from "../../../components/src/FormikInputField.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const lightTheme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#3858E3",
      },
      secondary:{
        main:'rgba(0,0,0,0.75)'
      },
      info:{
        main:"#222222",
        light:'#fff'
      }
     }
    });
  
  const darkTheme=createTheme({
      palette: {
        type: "dark",
        primary: {
          main: "#3858E3",   
        },
        secondary:{
        main:"rgba(255,255,255,0.75)"
        },
        info:{
          main:"#FFFFFF",
          light:'#0B0B0B'
        }
       }
      });

const CustomButton = styled(Button)({
    backgroundColor:'transparent',
    "&.MuiButton-root.Mui-disabled": {
      color: '#E9E9E9'
    },
    "&.MuiButton-root:hover": {
       backgroundColor:'transparent'
      } 
  })  

const CustomTextField = styled(TextField)({
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Manrope',
    "& .MuiOutlinedInput-root": {
        borderRadius: '10px !important',
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: '#8833FF'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: '#8833FF !important'
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Manrope',
    },
    "& .MuiInputBase-input": {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Manrope',
    }
})  

const TextCustom= styled(TextField)({
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "5px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      border: '1px solid red',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 16px",
    }, 
  
     "& .MuiOutlinedInput-notchedOutline":{
      borderRadius: '10px',
      borderWidth:"1px",
      borderColor:"red"
     },
  
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px',
      borderWidth:"1px",
      borderColor:"#8833FF"
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px',
      borderWidth:"1px",
      borderColor:"#f44336"
    },
  })

  const ModalBox=styled(Box)({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  
    "& .Modal_Container":{
      padding: "20px 24px",
      background:'#0B0B0B',
      gap:"24px",
      borderRadius:"10px",
      height:"240px",
      width:'340px',
      border:`1px solid #35383F`
    },
  
    "& .darkModal_container":{
      padding: "20px 24px",
      background:'#fff',
      gap:"24px",
      borderRadius:"10px",
      height:"240px",
      width:'340px',
      border:`1px solid #35383F`
    },
  
    "& .light_line":{
      width: '95%',
      border: "none",
    height: "1px",
    backgroundColor: "#BABABA"
    },
    "& .dark_line":{
      width: '100%',
      border: "none",
      height: "1px",
      backgroundColor: "#222222"
    },
    "& .account_text":{
      fontFamily:'Manrope',
      fontWeight:800,
      fontSize:'24px',
      textAlign:'center',
      color:'white',
      marginTop:'10px',
      marginBottom:'15px'
    },
    "& .account_text_sub":{
      fontFamily:'Manrope',
      fontWeight:600,
      fontSize:'16px',
      textAlign:'center',
      color:'white',
      marginTop:'10px',
      marginBottom:'15px'
    },
    '& .account_text_light':{
      fontFamily:'Manrope',
      fontWeight:800,
      fontSize:'24px',
      textAlign:'center',
      color:'black',
      marginBottom:'15px',
      marginTop:'10px'
    },
    '& .account_text_light_sub':{
      fontFamily:'Manrope',
      fontWeight:600,
      fontSize:'16px',
      textAlign:'center',
      color:'black',
      marginBottom:'15px',
      marginTop:'10px'
    },
    
    "& .btn_styles":{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection:'row' as 'row',
      width:'100%',
      marginTop:"20px",
      gap:'10px'
    },
    '& .cancel_btn':{
      width:'180px',
      fontFamily: "Manrope",
      fontSize: "14px",
      height:'44px',
      padding:"16px", 
      boxShadow:"none !important",
      fontStyle: "normal" as 'normal',
      fontWeight: 700,
      background: `linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border :"1px solid transparent",
      borderRadius: "10px",
      textTransform: "capitalize" as "capitalize",
      color: "#8833FF", 
      "&.MuiButton-root.Mui-disabled": {
        opacity:0.64
      }, 
    },
    '& .cancel_btn_light':{
      background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border :"1px solid transparent",
      borderRadius: "10px",
      textTransform: "capitalize" as "capitalize",
      color: "#8833FF",
      height:'44px',
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal" as 'normal',
      fontWeight: 700,
      padding:"16px", 
      boxShadow:"none !important",
      "&.MuiButton-root.Mui-disabled": {
        opacity:0.64
      },
      width:'180px'
    },
    '& .remove_btn':{
      background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
          width:"180px",
          height:'44px',
          borderRadius:'10px',
          border:'none',
          color:"#fff",
          fontWeight:700,
          textTransform:'capitalize' as 'capitalize',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          fontFamily: "Manrope",
          fontSize: "14px",
          cursor:'pointer',
          textDecoration:'none'
    }
  
  
  })
 

const inputStyles={  
     
    darkBackdrop:{
       backgroundColor:'#0E0E0E',
       opacity:0.8
    },
     fade:{
        border:'1px solid #5B5B5B',
        borderRadius:'10px',
        display: 'flex'
    },
    lightBackdrop:{
      backgroundColor:'#FFFFFF',
      opacity:0.9
    },
    logoutButtonContainer: {
        display: "flex",
        gap: "12px",
    },
    logoutCancelButton: {
        width: "160px",
        height: "48px",
        padding: "18px 16px 18px 16px",
        gap: "10px",
        borderRadius: "10px",
        fontSize:12,
        fontWeight:800,
        lineHeight:'16.px',
        textTransform:"none" as "none",
        border: "1px solid #999999"
    },
    logoutYesButton:{
        width: "160px",
        height: "48px",
        padding: "18px 16px 18px 16px",
        gap: "10px",
        fontSize:14,
        fontWeight:700,
        lineHeight:'16.8px',
        borderRadius: "10px",
        textTransform:"none" as "none",
        background: "#EF4444"
    },
    iconButton:{
        color: "#6B6B6B",
    }
}
// Customizable Area End

import AccountSettingsController, {
  Props,  
  IUserData
} from "./AccountSettingsController.web";
import Loader from "../../../components/src/Loader.web";
import AccountUpdateForm from "./UserUpdateForm.web";

export default class AccountSettings extends AccountSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  showArchiveScreens=()=>{
       return(
          <Box style={webStyle.inputContainer}>
              <Button data-test-id="backButtonAOtp" onClick={this.onClickBackButtonOtp} className="backButton" variant="text">
                  <span className="icon"><img src={this.props.checked ? backArrowDark : backArrowWhite} /></span>
                  <p>We sent you a code</p>
              </Button>
              <Box style={webStyle.textContner}>
                  <Typography style={webStyle.subText}>Enter it below to verify</Typography>
                  {this.state.otpAccount.includes('Send an email') ? <Typography style={webStyle.subText}>{this.state.userInfo.email.slice(0, 2)}**********@gmail.com.</Typography> : <Typography style={webStyle.subText}>{this.state.userInfo.phone.slice(0, 2)}**********.</Typography>}
                  <Typography style={webStyle.label}>
                      Verification code
                  </Typography>
              </Box>
              <TextCustom
                  style={webStyle.textFieldOtp}
                  data-test-id="otp"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter Verification code"
                  InputProps={{ style: webStyle.customInput }}
                  autoComplete="off"
                  onChange={this.handleChangeOtp}
              />
              <Typography data-test-id='receive' onClick={this.openOtpModal} style={webStyle.suggestionText} >
                  Didn’t receive code ?
              </Typography>
              <Button data-test-id='verify' disabled={this.state.otpCode !== '' ? false : true} onClick={this.handleSendOtp}
                  style={webStyle.sendButton}>
                  <div style={webStyle.btnStyles}>
                      <p style={webStyle.text}>Verify</p>
                      <span className="icon"><img src={arrowRight24White} /></span>
                  </div>
              </Button>
              <Dialog
        open={this.state.otpModal}
        onClose={this.handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: this.props.checked? webStyle.light:webStyle.dark
        }}
        
      >
        <DialogTitle id="alert-dialog-title" style={webStyle.alertText} >{"Didn’t receive code ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Typography data-test-id='resend' style={this.props.checked ?webStyle.resendText:webStyle.resendTextLight} onClick={this.resendOtp}>Resend</Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
          </Box>
      )
  }

  showArchivedScreens=()=>{
      return (
          <Box style={webStyle.inputContainer}>
              {this.state.downloadStatus === 'loaded' ? <Button data-test-id="backButtonLoad" onClick={this.onClickBackButtonLoad} className="backButton" variant="text">
                  <span className="icon"><img src={this.props.checked ? backArrowDark : backArrowWhite} /></span>
                  <p>Download an archive of your data</p>
              </Button> : <Button data-test-id="backButtonArchive" onClick={this.onClickBackButtonArchive} className="backButton" variant="text">
                  <span className="icon"><img src={this.props.checked ? backArrowDark : backArrowWhite} /></span>
                  <p>Download an archive of your data</p>
              </Button>}
              <Box style={this.props.checked ? webStyle.inputContainerThemeLight : webStyle.inputContainerTheme}>
                  <Typography style={webStyle.subText}>Arrows data</Typography>
                  <Typography style={webStyle.descriptionText}>You can request a ZIP file with an archive of
                      your account<br />
                      information, account history, apps and devices,
                      account activity,<br />
                      interests, and Ads data.
                      You’ll get an in-app notification when the<br />
                      archive of your data is ready to download.<span style={webStyle.suggestionText}>Learn more</span>
                  </Typography>

                  {this.state.downloadStatus === 'open' && <Button data-test-id="archive" onClick={this.handleRequestArchive} style={webStyle.requestBtn}>Request archive</Button>}
                  {this.state.downloadStatus === 'loading' && <Button data-test-id="archiveLoad" style={webStyle.requestBtnLight}>Requesting Archive</Button>}
                  {this.state.downloadStatus === 'loaded' && <Button data-test-id="archived" onClick={this.handleDownloadArchive} style={webStyle.requestBtn}>Download Archive</Button>}
              </Box>
              {this.state.downloadStatus === 'loading' && <Box style={this.props.checked ? webStyle.inputContainerThemeLight : webStyle.inputContainerTheme}>
                  <img src={arowLogo} style={webStyle.logoImage} />
                  <Typography style={webStyle.descriptionText}>
                      We received your request. to protect your account, it can take 24<br />
                      hours or longer for your data to be ready.
                  </Typography>
              </Box>}
              {this.state.downloadStatus === 'loaded' && <Box style={this.props.checked ? webStyle.inputContainerThemeLight : webStyle.inputContainerTheme}>
                  <img src={arowLogo} style={webStyle.logoImage} />
                  <Typography style={webStyle.descriptionText}>
                      Your archive is ready for you to download and view using your <br />
                      desktop browser. Keep in mind, you need to download this data <br />
                      before it expires in a few days.
                  </Typography>
              </Box>}
          </Box>
      )  
  }

  showDownloadedData=()=>{
      return (
          <Box style={webStyle.inputContainer}>
              <Button data-test-id="backButtonArrowSize" onClick={this.onClickBackButtonSize} className="backButton" variant="text">
                  <span className="icon"><img src={this.props.checked ? backArrowDark : backArrowWhite} /></span>
                  <p>Download an archive of your data</p>
              </Button>
              <Box style={this.props.checked ? webStyle.inputContainerThemeLight : webStyle.inputContainerTheme}>
                  <Typography style={webStyle.subText}>Arrows data</Typography>
                  <Box style={webStyle.fileStyle}>
                      <Typography style={webStyle.descriptionTextSize}>
                          Generated on: June 7, 2024 <br />
                          Expires on: June 14, 2024 <br />
                          Estimated size: 48 MB
                      </Typography>

                      <Button data-test-id='archiveModal' onClick={this.handleArchiveData} style={webStyle.requestBtnLoad}>Download archive</Button>
                  </Box>
              </Box>
              {this.state.isOpen && this.showDownloadModal()}
          </Box>
      )
  }

  renderSubCat = () => {
    const {checked} = this.props
    return(
        <div className="subCategory">
            <div className="subCategoryHeading">
            <Link to="/settings" style={{ textDecoration: 'none' }}>
                <Button  className="backButton2" variant="text">
                    <span><img src={checked ? backArrowDark : backArrowWhite} /></span>
                </Button>
                </Link>
                <div className="textHeading">
                    <div><span className="headingTxt" >Your Account</span></div>
                   <div><p className="paragraphText">See information about your account, download an archive of your data, or learn about your account deactivation options.</p></div>
                </div>
             </div>
            <List className={"list"}>
                {accountSettingsList.map((item, index: number) =>
                    (<ListItem alignItems="center"
                        className={"listItem alignStart"}
                        button
                        data-test-id={`subcat-${index}`}
                        key={`setingsCat${index}`} 
                        onClick={()=>this.changeAccountSettingsRenderType(item.path)}
                        >
                        <ListItemIcon className={"subCatIconContainer"}>
                            <img src={checked ? item.darkIcon : item.whiteIcon} className={"iconImage"} />
                            </ListItemIcon>
                            <ListItemText className="subcatTitleContainer" primary={item.name} secondary={item.description} />
                            <ListItemIcon className={"justify-content-center"}>
                            <img src={checked ? darkRightArrow : rightArrow} className={"iconImage marginTopClass"} />
                        </ListItemIcon>
                    </ListItem>))
                }         
            </List>
        </div>       
    )
  }

  verifyWithPassword = () => {
    return(
        <>
            {this.renderBackButton()}
            <div className="verificationContainer">
                <p className="reEnterText">Re-enter your Arrows password to continue.</p>   
                {this.state.pasError !== "" && <div className="pasErrorContainer arrowFontFamily" > 
                {this.state.pasError}
                </div> }          
                <Formik
                    initialValues={{             
                        password: "",
                    }}
                    onSubmit={this.validatePassword}
                    data-test-id="validate-fromik-form"
                    validate={this.onVerifyPasswordChang}
                >
                    {(formikProps) => {
                    const { values, handleChange} = formikProps;
                    return (
                        <Form noValidate autoComplete="off">
                            <div className="fieldContainer">
                                <label htmlFor="passwordInput"> New Password</label>
                                <CustomTextField
                                    name="password"
                                    id="outlined-basic"
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={values.password}                                   
                                    placeholder="At least 8 characters"
                                    fullWidth
                                    type="password"
                                />
                            </div>
                            <Button  data-test-id="verify-password-submit" className="submitButton" type="submit">
                                <p className="label buttonText">Verify</p> 
                                <span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
                            </Button>
                        </Form>
                        );
                    }}
                </Formik>
            </div>
        </>

    )
  }

  renderBackButton = () => {
    const {checked} = this.props
    return (
        <div className="backContainer">
        <Button data-test-id="backButtonArrow" onClick={this.onClickBackButton} className="backButton backButtonContain" variant="text">
            <span className="icon"><img src={checked ? backArrowDark : backArrowWhite} /></span>
        </Button>
         <div className="backButtonText">{this.renderBackButtonTitle()}</div>
        </div>
    )
  }

  renderLogoutModal = () => {
    const isVerifyPassword = this.state.renderAccountSettingsType === "accountInformation"
    const modalHeading = isVerifyPassword ? "Logout" : "Deactivate account";
    const modalDescription = isVerifyPassword ? "Are you sure you want to log out?" : "Are you sure you want to Deactivate your account?";
    const buttonLabel = isVerifyPassword ? "Yes, Logout" : "Yes, Deactivate"
    return(
        <Modal           
            className={`logoutModal  dark-${!this.props.checked} ${!isVerifyPassword ? "deactivateModal": ""}`}
            open={this.state.logoutModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              style: this.props.checked ? inputStyles.lightBackdrop : inputStyles.darkBackdrop
            }}
          >
            <Fade 
                in={this.state.logoutModal} 
                style={inputStyles.fade}
            >
               <div className={`logoutContainer dark-${!this.props.checked}`}>
                    <h6 className="arrowFontFamily font18 m0">{modalHeading}</h6>
                    <p className="arrowFontFamily font16 m0">{modalDescription}</p>
                    <div style={inputStyles.logoutButtonContainer}>
                        <Button className="cancelButton fontStyle2" onClick={this.onCloseLogout} style={inputStyles.logoutCancelButton} >
                            Cancel
                        </Button>
                        <Button data-test-id="logout-confirm-modal" className="confirmButton fontStyle2" onClick={this.onLogout} style={inputStyles.logoutYesButton} variant="text">                                  
                            {buttonLabel}
                        </Button>
                    </div>
               </div>
            </Fade>
          </Modal>
    )
  }
  renderAccountInformation = () => {
    const {checked} = this.props
    return(
        <div className="">            
           {this.renderBackButton()}
            <List className={"userList accountInfo"}>
                {accountInformationList.map((each, index: number) =>{
                    const keyValue = this.state.userData && each.keyPair in this.state.userData ? this.state.userData[each.keyPair as keyof IUserData] : ''
                    return(
                        <ListItem alignItems="center"
                            className={"userListItem"}
                            button
                            data-test-id={`account-information-${index}`}
                            key={`setingsCat${index}`} 
                            onClick={()=>this.redirectToEditForm(each.keyPair, keyValue)}
                        >
                            <ListItemIcon className={"drawerItemIcon iconWidth"}>
                                <img src={checked ? each.darkIcon : each.whiteIcon} className={"iconImage"} />
                            </ListItemIcon>
                            <ListItemText className="mainCatLabel fontStyle" primary={each.label} />
                            <ListItemIcon className={"userDataValue"}>
                                {keyValue ? keyValue : "Add"}
                                <img src={checked ? arrowDark : arrowWhite} className={"iconImage imgStyle"} />
                            </ListItemIcon>
                    </ListItem>
                    )
                })}
                <ListItem 
                    alignItems="center"
                    className={"listItem cursorNone"}
                    button
                >
                    <ListItemIcon className={"drawerItemIcon iconWidth"}>
                        <img src={ checked? calenderDark: calenderWhite } className={"iconImage"} />
                    </ListItemIcon>
                    <ListItemText className="mainCatLabel fontStyle"  primary="Date of Birth"/>
                    <ListItemIcon className={"userDataValue"}>
                        { this.state.userData?.dateOfBirth&&this.formatDate(this.state.userData?.dateOfBirth)}
                    </ListItemIcon>
                </ListItem>              
            </List>
        </div>
    )
  }
  
  renderMessage = () => {
    return(
        <div className="messageScreen">
            <div className="messageContainer">
                <img src={this.state.messageType === "suc" ? sucIcon : ""} />
                <span>{this.state.message}</span>
            </div>
        </div>
    )
  }

  renderDeactivate = () =>{
    const isPasswordErr = this.state.pasError !==""
    return(
        <div className="deactivateContainer">
            {this.renderBackButton()}            
            <p> Complete your deactivation request by entering the password associated with your account. </p>
            {isPasswordErr && <div className="pasErrorContainer arrowFontFamily"> {this.state.pasError}</div> }
            <div className="verificationContainer">
                <Formik
                    initialValues={{ password: "" }}
                    onSubmit={this.validatePassword}
                    data-test-id="validate-password-form"
                    validate={this.onVerifyPasswordChang}
                >
                    {(formikProps) => {
                    const { values} = formikProps;
                    return (
                        <Form noValidate autoComplete="off">
                            <div className="fieldContainer">
                                <label htmlFor="passwordInput2">Password</label>
                                <Field
                                    component={FormikInputField}
                                    id="passwordInput2"
                                    className = {`passField error-${isPasswordErr}`}
                                    name="password"
                                    value={values.password}
                                    placeholder="Enter Your Password"
                                    variant="outlined"
                                    fullWidth
                                    errors={isPasswordErr}
                                    type={this.state.showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            onClick={this.onClickVisiblePassword}>
                                            <IconButton style={inputStyles.iconButton}
                                            >
                                            {this.state.showPassword ? (<VisibilityOffIcon /> ) : (<VisibilityIcon />)}
                                            </IconButton>
                                        </InputAdornment>
                                        ),
                                    }}
                                    />
                            </div>
                            <Button className="deactivateButton" type="submit">
                                <p className="label">Deactivate</p> 
                                <span className="icon"><img src={arrowRightRed} className={"iconImage"} /></span>
                            </Button>
                        </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    )
  }

  renderChangeForm = (values: { current_password:string, new_password:string, confirm_password:string}) => {
    return (
        <Form className="changePassForm" noValidate autoComplete="off">
            <div className="fieldContainer">
                <label htmlFor="passwordInput2">Current Password</label>
                <Field
                    component={FormikInputField}
                    id="passwordInput2"
                    className = "passField"
                    name="current_password"
                    value={values.current_password}
                    placeholder="Enter Your Password"
                    variant="outlined"
                    fullWidth
                    type={this.state.showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment
                            position="end"
                            onClick={this.onClickVisiblePassword}>
                            <IconButton style={inputStyles.iconButton}
                            >
                            {this.state.showPassword ? (<VisibilityIcon /> ) : (<VisibilityOffIcon />)}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    />
            </div>
            <div className="fieldContainer">
                <label htmlFor="passwordInput"> New Password</label>
                <Field
                    component={FormikInputField}
                    id="passwordInput"
                    name="new_password"
                    value={values.new_password}                                   
                    placeholder="At least 8 characters"
                    variant="outlined"
                    fullWidth
                    type="password"
                    InputLabelProps={{
                        className: "13",
                    }}
                    className = "passField"
                />
            </div>
            <div className="fieldContainer">
                <label htmlFor="passwordInput2">Confirm Password</label>
                <Field
                    component={FormikInputField}
                    id="passwordInput2"
                    className = "passField"
                    name="confirm_password"
                    value={values.confirm_password}
                    placeholder="Enter Your Password"
                    variant="outlined"
                    fullWidth
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment
                            position="end"
                            onClick={this.onClickConfirmVisible}>
                            <IconButton style={inputStyles.iconButton}
                            >
                            {this.state.showConfirmPassword ? ( <VisibilityIcon />) : (<VisibilityOffIcon />)}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                />
            </div>
            <Button  data-test-id="verify-password-submit" className="submitButton" type="submit">
                <p className="label">Update Password</p> 
                <span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
            </Button>            
            <Link to="/ForgotPassword" style={{ textDecoration: 'none' }} className="arrowFontFamily font14 font500 txtGradiant forgotPswd">Forgot password?</Link>            
            
        </Form>
        );
  }

  renderChangePswrd = () => {
    return(
        <React.Fragment>
            {this.renderBackButton()}
            <div className="changepswrdContainer">
            {this.state.pasError !== "" && <div className="pasErrorContainer arrowFontFamily" > 
                {this.state.pasError}
            </div>}
                <Formik
                    initialValues={{ 
                        current_password: "",
                        new_password: "",
                        confirm_password: ""
                    }}
                    onSubmit={this.updateNewPswrd}
                    data-test-id="validate-change-password-form"
                    validate={this.onVerifyPasswordChang}
                >
                    {(formikProps) => {
                    const { values} = formikProps;
                        return(this.renderChangeForm(values))
                    }}
                </Formik>
            </div>
        </React.Fragment>
    )
  }

  renderArchiveData=()=>{
      switch (this.state.step) {
          case 0:
              return (<Box >
                  {this.renderBackButton()}
                  <Typography style={webStyle.subTextOtp}>You can use the information associated with your account.</Typography>
                  <Box style={webStyle.accountsContainer}>
                      {this.state.userAccounts.map((each) =>
                          <CustomButton data-test-id='accountType' key={each.id} fullWidth onClick={() => { this.toggleRadioBtn(each) }}
                              style={!each.clicked ? webStyle.selectBtn : webStyle.selectBtnDark} >
                              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                  {each.title}
                              </span>
                              <img src={!each.clicked ? radioDark : radioLight} style={webStyle.radioBtn} />
                          </CustomButton>)}
                  </Box>
                  <Button disabled={this.state.otpAccount ? false : true} style={webStyle.sendButton} data-test-id="sendCode" onClick={this.sendOtp}>
                      <div style={webStyle.btnStyles}>
                          <p style={webStyle.text}>Send code</p>
                          <span className="icon"><img src={arrowRight24White} /></span>
                      </div>
                  </Button>
              </Box>)
          case 1:
             return this.showArchiveScreens()
          case 2:
             return this.showArchivedScreens()
          case 3:
            return  this.showDownloadedData()

      }         
  }

  showDownloadModal=()=>{
    const theme =this.props.checked?lightTheme:darkTheme
    return(
      <ThemeProvider theme={theme}>
       <Modal 
        onClose={this.handleRemoveModal}
        data-testid="noModal"
        open={this.state.isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
        <ModalBox>
        <Box className={this.props.checked?"darkModal_container":"Modal_Container"}>
          <Typography className={this.props.checked?'account_text_light':'account_text'}>Your archive is about to download</Typography>
        
        <Typography className={this.props.checked?'account_text_light_sub':'account_text_sub'}>Unregistering a device means you’ll no longer have access to encrypted messages on it.</Typography>
        <div className='btn_styles'>
        <Button 
        data-test-id='cancel'
        onClick={this.cancelDownload} 
        className={this.props.checked ?"cancel_btn":"cancel_btn_light"}
        >Cancel</Button>
        <Button
         data-test-id='submit'
         className='remove_btn'
         onClick={this.downloadData}
         >Get Started</Button>
      </div>
        </Box>
        </ModalBox>
       </Modal>
       </ThemeProvider>)
  }

  renderSection = () => {
    return (
        <Box>
            {this.state.renderAccountSettingsType === "default" && this.renderSubCat()}
            {this.state.renderAccountSettingsType == "verifyPassword" && this.verifyWithPassword()}
            {this.state.renderAccountSettingsType === "accountInformation" && this.renderAccountInformation()}
            {this.state.renderAccountSettingsType === "deactivateAccount" && this.renderDeactivate()}   
            {this.state.renderAccountSettingsType === "changePassword" && this.renderChangePswrd()}  
            {this.state.renderAccountSettingsType === "download" && this.renderArchiveData()}  
            {this.state.renderAccountSettingsType === "userEditForm" && <AccountUpdateForm 
                editType={this.state.editUserType} 
                onClickBackButton={this.onClickBackButton} 
                fieldValue={this.state.editUserTypeValue}
                checked={this.props.checked}
                setMessage={this.setMessage}
                updateUserName={this.updateUserName}
                updateError={this.state.updateError}
                updateErrorMsg={this.updateErrorMsg}
                apiCall={this.apiCall}
            />}
            {this.state.messageType !=="" &&  this.renderMessage()}   
            {this.state.logoutModal && this.renderLogoutModal()}                      
        </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start 
      <React.Fragment>
         <Loader loading={this.state.isLoading} />
        <StyleContainer>
            {!this.state.isLoading && this.renderSection()}   
        </StyleContainer>  
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyleContainer = styled(Box)({
    "& .logoutModal":{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    "& .fontStyle span":{
      fontFamily:'Manrope',
      fontSize:16,
      fontWeight:600,
      lineHeight:"19.2px"
    },

    "& .imgStyle":{
      height:20,
      width:20
    },

    "& .iconWidth":{
     minWidth:40,
    },

    "& .accountInfo":{
      marginTop:20,
      display:'flex',
      flexDirection:'column',
      rowGap:20,
    },
    " .logoutContainer": {
        height: "240px",
        width: "375px"
    },
    "& .messageScreen": {
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: "0px",
        left: "0px",
        zIndex: 100,
        background: "#000000c7",
        display: "flex",
        justifyContent: "center"
    },
    "& .messageScreen .messageContainer": {         
        background: "#222222",        
        padding: "10px 20px 10px 20px",
        gap: "8px",
        borderRadius: "6px",
        marginTop: "auto",
        marginBottom: "5%",
        display: "flex"
    },
    "& .messageContainer span": {
        margin: "auto",
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "14.4px"
    },
    "& .subCategory": {
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
        fontFamily:"Manrope",
    
    },

    "& .buttonText":{
      fontFamily:"Manrope",
      fontWeight:700,
      fontSize:14,
      lineHeight:'16.8px'
    },

    "& .headingTxt":{
        fontFamily:"Manrope",
        fontSize: "24px",
        fontWeight: "500",
        paddingRight: "0",
        textTransform: "none",
        paddingLeft: "0px"
    },

    "& .fontStyle2 span":{
        textTransform:"none !important" as "none" 
    },
    
    "& .backButtonContain":{
        minWidth:0,
    },

    "& .backButtonText":{
        fontFamily:"Manrope",
        fontSize: "24px",
        fontWeight: "500",
        paddingRight: "0px",
        textTransform: "none",
        paddingLeft: "0px"
    },

    "& .backButtonContain .icon":{
       width:"0px !important",
       marginRight:'16px !important'
    },

    "& .textHeading":{
     display:'flex',
     flexDirection:'column',
     rowGap:'20px'
    },

    "& .paragraphText":{
      fontFamily:'Manrope',
      fontWeight:600,
      fontSiz:16,
      lineHeight:'19.2px'
    },

    "& .subCategoryHeading":{
      display:'flex',
      paddingTop:16,
    },

    "& .alignStart":{
     alignItems:'flex-start'
    },

    "& .subCategory .list": {
        paddingTop: "0px",   
    },
    "& .backButton2:hover":{
        background: "transparent"
    },
    "& .backButton:hover":{
        background: "transparent"
    },

    "& .marginTopClass":{
     marginTop:6,
     height:24,
     width:24
    },

    "& .backButton2 .icon":{
        height: "48px",
        width: "48px",
        textAlign: "center",
        alignContent: "center",
        marginRight: "0px"       
    },
    "& .backButton2 span": {
        marginRight: "0px",
        justifyContent:'flex-start'        
    },
    "& .backButton2 .MuiButton-label": {
        justifyContent: "flex-start"
    },
    "& .backButton": {
        fontFamily:"Manrope",
        fontSize: "24px",
        fontWeight: "500",
        paddingRight: "0",
        textTransform: "none",
        paddingLeft: "0px"
    },
    "& .pl16": {
        paddingLeft: "16px"
    },

    "& .backContainer":{
      display:'flex',
      alignItems:'center',
      flexDirection:'row'
    },
    "& .backButton .MuiButton-label": {
        justifyContent: "left"
    },
    "& .backButton span": {
        marginRight: "30px"        
    },
    "& .backButton .icon":{
        height: "48px",
        width: "48px",
        textAlign: "center",
        alignContent: "center",
        marginRight: "0px"       
    },
    "& .backButton .icon img":{        
        marginTop: "5px"       
    },
    "& .subCategory .backButton .MuiButton-label span": {
        marginTop: "12px",
        marginBottom: "auto"
    },
    "& .subCategory .backButton ": {
        paddingTop: "11px"
    },
    "& .justify-content-center": {
        justifyContent: "center",
        alignItems:'flex-start'
    },
    "& .subCatIconContainer": {
        marginTop: "6px",
        marginBottom: "auto"
    },
    "& .textAlignInitial": {
        textAlign: "initial"
    },
    "& .textAlignInitial p,& .subcatTitleContainer p":{
        fontSize: "14px",
        margin: "0px",
        fontWeight: "500",
        lineHeight: "21px",
        color: "#6B6B6B",
    },
    "& .subcatTitleContainer p":{
        fontSize: "12px",
        marginTop:10,
        fontWeight: "500",
        lineHeight: "14.4px",
        color: "#6B6B6B",
        maxWidth: "60%"
    },

    "& .subcatTitleContainer span": {
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "19px",
    },
    "& .verificationContainer": {
        gap: "30px",
        flexDirection:'column',
        display:'flex',
    },
    "& .verificationContainer p": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        padding: "10px 0px"
    },
    "& .verificationContainer .pasErrorContainer":{
        width:"auto",
        marginBottom: "20px"
    },
    "& .fieldContainer": {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "20px"
    },
    "& .fieldContainer .MuiOutlinedInput-root":{
        borderRadius: "10px"
    },
    "& .fieldContainer label": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        marginBottom: "8px"
    },
    "& .submitButton": {
        width: "160px",
        height: "48px",
        padding: "15px 30px 15px 30px",
        gap: "5px",
        borderRadius: "10px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)"
    },
    "& .deactivateButton":{
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "700",
        color: "rgba(239, 68, 68, 1)",
        width: "160px",
        height: "48px",
        padding: "15px 30px 15px 30px",
        gap: "5px",
        borderRadius: "10px",
        border: "1px solid rgba(239, 68, 68, 1)"

    },

    "& .submitButton .label":{
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "16.8px",
        textAlign: "center",
        textTransform: "none",
        color: "white"
    },
    "& .userDataValue": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        justifyContent: "end"
    },
    "& .userDataValue .iconImage": {
        marginLeft: "10px"
    },
    "& .deactivateContainer p": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        margin: "0px"
    },
    "& .deactivateContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "30px"
    },
    "& .pasErrorContainer":{
        width: "420px",
        height: "36px",
        padding: "12px 16px 12px 16px",
        gap: "8px",
        borderRadius: "10px",
        border: "0px 0px 0px 1.3px",
        background: "linear-gradient(0deg, rgba(33, 33, 33, 0.29), rgba(33, 33, 33, 0.29)), linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)",
        alignContent: "center",
        color: "rgba(239, 68, 68, 1)"
    },
    "& .deactivateModal .logoutContainer p":{
        maxWidth: "319px"
    },
    "& .changepswrdContainer":{
        paddingTop: "24px"
    },
    "& .changePassForm": {
        display: "flex",
        flexDirection: "column",
    },
    "& .changePassForm .submitButton": {
        margin: "auto",
        width: "unset"
    },
    "& .changepswrdContainer .pasErrorContainer":{
        marginBottom: "30px"
    },
    "& .forgotPswd":{
        margin: "auto",
        marginTop: "15px"
    }
    
});

const webStyle = {
    light:{
        width: "25%", 
        border: "1px solid #35383F", 
        backgroundColor: "#E9E9E9",
        borderRadius:"10px"
      },
      dark:{
        width: "25%", 
        border: "1px solid #35383F", 
        backgroundColor: "#0B0B0B",
        borderRadius:"10px"
      },
    resendText:{
fontFamily: "Manrope",
fontSize: "18px",
fontWeight: 800,
lineHeight: "21.6px",
textAlign: "left" as 'left',
color:'rgba(0, 0, 0, 0.87)',
cursor:'pointer'
    },
    resendTextLight:{
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 800,
        lineHeight: "21.6px",
        textAlign: "left" as 'left',
        color:'white',
        cursor:'pointer'
            },
    alertText:
    {textAlign:'center' as 'center',
        fontSize:'18px'
    },
    textContner:{
     marginTop:'24px'
    },
    accountsContainer:{
        marginTop:'30px'
    },

    logoImage:{
        height:'24px',
        width:'24px'
    },
    fileStyle:{
        display:'flex',
        flexDirection:'row' as 'row',
        justifyContent:'space-between',
        alignItems:'center'
    },
    descriptionText:{
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "14.4px",
        color:'#6B6B6B',
        marginTop:'10px'
    },
    descriptionTextSize:{
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "18px",
        color:'#6B6B6B',
        marginTop:'10px'
    },
    subText:{
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "19.2px"
    },
    subTextOtp:{
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "19.2px",
        width:'300px',
        textAlign:'left' as 'left',
        marginTop:'24px'
    },
    suggestionText: {
        textAlign: "left" as "left",
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        marginTop: "12px",
        cursor: 'pointer',
        textDecorationColor: "rgb(136, 51, 255, 0.3)"
    },
    button: {
        color: "#FFF",
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        marginTop: '35px',
        height: '46px',
        width:'160px',
        borderRadius: "10px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        textTransform: "capitalize" as "capitalize",
    },
    textFieldOtp: {
        width: "100%",
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderRadius: '10px',
                borderWidth: "1px",
                borderColor: "#f44336"
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#f44336 !important',
            },
        },
        "& .MuiInputBase-input": {
            fontFamily: "Manrope",
            fontSize: "12px",
            fontStyle: "normal" as 'normal',
            fontWeight: 500,
            height: '24px',
            border: '1px solid #f44336',
            borderRadius: '10px'
        },
        "& .MuiOutlinedInput-input": {
            padding: "10px 16px",
        },

    },
    customInput: {
        fontSize: '12px',
        fontFamily: "Manrope",
        fontStyle: "normal" as 'normal',
        borderRadius:'10px',
        height:'50px',
        borderWidth:'1px !important'
    },
    label: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal" as 'normal',
        fontWeight: 500,
        textAlign: "left" as "left",
        marginBottom: '6px',
        marginTop: '26px',
    },
    inputContainer: {
        marginTop: "10px",
    },
    inputContainerTheme:{
     backgroundColor:'#181818',
     border:'1px solid #5B5B5B',
     padding:'15px',
     borderRadius:'10px',
     marginTop:"20px",
     display:'flex',
     flexDirection:'column' as 'column',
     justifyContent:'flex-start'
    },
    inputContainerThemeLight:{
        backgroundColor:'#E9E9E9',
        border:'1px solid #5B5B5B',
        padding:'15px',
        borderRadius:'10px',
        marginTop:"20px",
        display:'flex',
        flexDirection:'column' as 'column',
        justifyContent:'flex-start'
       },
    selectBtn: {
        padding: "15px 10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid #6B6B6B",
        borderRadius: "6px",
        textTransform: "capitalize" as "capitalize",
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal" as 'normal',
        fontWeight: 700,
        marginTop: '8px',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent'
        },
        width: '100%'

    },
    selectBtnDark: {
        padding: "15px 10px",
        boxShadow: "none !important",
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #8833FF",
        borderRadius: "6px",
        textTransform: "capitalize" as "capitalize",
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal" as 'normal',
        fontWeight: 700,
        marginTop: '8px',
        '&:hover': {
            backgroundColor: 'transparent'
        },
        backgroundColor: 'transparent',
        width: '100%'
    },
    radioBtn: {
        marginLeft: '30px'
    },
    sendButton: {
        textTransform: 'none' as 'none',
        marginTop: '36px',
        marginRight: 'auto',
        display: 'block',
        width: "160px",
        height: "48px",
        gap: "5px",
        borderRadius: "10px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)"
    },
    requestBtn:{
        color:'white',
        alignSelf:'flex-end',
        textTransform: 'none' as 'none',
        marginTop: '10px',
        width: "130px",
        height: "24px",
        borderRadius: "5px",
        fontFamily: "Manrope",
        fontSize: "12px",
        fontStyle: "normal" as 'normal',
        fontWeight: 700,
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)"
    },
    requestBtnLoad:{
        color:'white',
        textTransform: 'none' as 'none',
        marginTop: '10px',
        width: "130px",
        height: "24px",
        borderRadius: "5px",
        fontFamily: "Manrope",
        fontSize: "12px",
        fontStyle: "normal" as 'normal',
        fontWeight: 700,
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)"
    },
    requestBtnLight:{
        color:'white',
        opacity:'0.4',
        alignSelf:'flex-end',
        textTransform: 'none' as 'none',
        marginTop: '10px',
        width: "138px",
        height: "24px",
        borderRadius: "5px",
        fontFamily: "Manrope",
        fontSize: "12px",
        fontStyle: "normal" as 'normal',
        fontWeight: 700,
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)"
    },
    btnStyles: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        color: 'white',
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 700
    }
}
// Customizable Area End
